import { InputAdornment, Stack, Typography } from "@mui/material";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { FunctionComponent, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { getLocalizedCurrencyCode } from "Utils/CurrencyUtils";
import { ProductSelectors } from "Components/ContractExchangeAssets/components/ProductSelectors";
import {
  ContractExchangeableAsset,
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsType,
  ContractOverviewPortfolioItemDto,
  ContractTypeCode,
  CurrencyCode,
  ProductFeeRateCalculationType,
} from "Api/Api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { RateLabel } from "Components/Contract/RateLabel";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import {
  MIN_EXCHANGE_ASSETS_AMOUNT_CZK,
  MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
  useYupFormSchema,
} from "Hooks/ContractExchangeAssets/useYupFormSchema";
import {
  ExchangeAssetsFormModel,
  ExchangeAssetsStep,
  setExchangeAssetsLastRequest,
  setExchangeAssetsStep,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";

type Props = {
  isDip: boolean;
  contractID: number;
  sourceIsin: string;
  contractTypeCode: ContractTypeCode;
  exchangeableAssets: ContractExchangeableAsset[];
  portfolioItem: ContractOverviewPortfolioItemDto;
};

const AmountDecimalPlaces = 0;
const ComponentResources = Resources.Contract.ExchangeAssets.Modelling;

export const ModellingStep: FunctionComponent<Props> = ({
  sourceIsin,
  contractID,
  contractTypeCode,
  exchangeableAssets,
  portfolioItem,
  isDip,
}) => {
  const { t } = useResource();
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { isLoading, formData } = useAppSelector(
    s => s.contracts.exchangeAssets,
  );

  const { current: defaultTarget } = useRef(
    exchangeableAssets?.length === 1 ? exchangeableAssets[0] : null,
  );

  const { current: defaultMinAmount } = useRef(
    portfolioItem.currency === CurrencyCode.CZK
      ? MIN_EXCHANGE_ASSETS_AMOUNT_CZK
      : MIN_EXCHANGE_ASSETS_AMOUNT_EUR,
  );

  const title = usePageTitle();
  const dispatch = useAppDispatch();
  const schema = useYupFormSchema(portfolioItem, exchangeableAssets);

  const {
    control,
    watch,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ExchangeAssetsFormModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...formData,
      targetIsin: formData.targetIsin ?? defaultTarget?.targetIsin,
      amountOrPieces:
        formData.amountOrPieces ||
        defaultTarget?.single.minAmount ||
        defaultMinAmount,
    },
  });

  const isExchangeAll = watch("isExchangeAll");
  const targetIsin = watch("targetIsin");
  const method = watch("method");
  const amountOrPieces = watch("amountOrPieces");

  const decimalPlaces = useMemo(
    () =>
      method === ContractExchangeAssetsMethod.Pieces ? AmountDecimalPlaces : 0,
    [method],
  );

  const onSubmit = (formData: ExchangeAssetsFormModel) => {
    if (!formData.amountOrPieces || !formData.targetIsin) {
      return;
    }

    dispatch(setExchangeAssetsStep(ExchangeAssetsStep.Information));
    dispatch(
      setExchangeAssetsLastRequest({
        ...formData,
        isBiometry: canUseBiometricsForSigning,
        contractID: contractID,
        sourceIsin: sourceIsin,
        targetIsin: formData.targetIsin,
        amountOrPieces: formData.amountOrPieces,
      }),
    );
  };

  const handleMethodChange = (value: ContractExchangeAssetsMethod) => {
    if (value === ContractExchangeAssetsMethod.Pieces) {
      setValue("amountOrPieces", 1, { shouldValidate: true });
      return handleIsExchangeAllChange(isExchangeAll, value);
    }

    const exchangeableAsset = exchangeableAssets?.find(
      x => x.targetIsin === targetIsin,
    );

    setValue(
      "amountOrPieces",
      exchangeableAsset?.single.minAmount ?? defaultMinAmount,
      { shouldValidate: true },
    );

    return handleIsExchangeAllChange(isExchangeAll, value);
  };

  const handleIsExchangeAllChange = (
    isExchangeAll: boolean,
    method: ContractExchangeAssetsMethod,
  ) => {
    if (!isExchangeAll) {
      return;
    }

    if (
      method === ContractExchangeAssetsMethod.Pieces &&
      !!portfolioItem.quantity
    ) {
      return setValue("amountOrPieces", portfolioItem.quantity, {
        shouldValidate: true,
      });
    }

    if (
      method === ContractExchangeAssetsMethod.Amount &&
      !!portfolioItem.amount
    ) {
      return setValue("amountOrPieces", portfolioItem.amount, {
        shouldValidate: true,
      });
    }
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <ComponentTitleWrapper
          title={t(ComponentResources.FundSelection.Title)}
          marginTop={0}
        >
          <Typography marginBottom={5}>
            {t(ComponentResources.FundSelection.Description)}
          </Typography>
          <ProductSelectors
            isDip={isDip}
            defaultIsin={targetIsin}
            portfolioItem={portfolioItem}
            exchangeableAssets={exchangeableAssets}
            onSelect={x => {
              setValue("targetIsin", x, { shouldValidate: true });
              trigger("amountOrPieces");
            }}
          />
        </ComponentTitleWrapper>
        <ComponentTitleWrapper title={t(ComponentResources.Parameters.Title)}>
          <Stack>
            <RadioButtons
              control={control}
              name="type"
              label={t(ComponentResources.Parameters.Type.Title)}
              errors={errors}
              codeList={[
                {
                  code: ContractExchangeAssetsType.Single,
                  name: t(ComponentResources.Parameters.Type.Single),
                },
                // {
                //   code: ContractExchangeAssetsType.Periodical,
                //   name: t(ComponentResources.Parameters.Type.Periodical),
                //   disabled: true,
                // },
              ]}
            />

            <RadioButtons
              control={control}
              name="method"
              label={t(ComponentResources.Parameters.Method.Title)}
              errors={errors}
              codeList={[
                {
                  code: ContractExchangeAssetsMethod.Amount,
                  name: t(ComponentResources.Parameters.Method.Amount),
                },
                {
                  code: ContractExchangeAssetsMethod.Pieces,
                  name: t(ComponentResources.Parameters.Method.Pieces),
                },
              ]}
              onChange={handleMethodChange}
            />

            <BlInputFormField
              control={control}
              name="amountOrPieces"
              label={
                method === ContractExchangeAssetsMethod.Amount
                  ? t(ComponentResources.Parameters.Amount.Label)
                  : t(ComponentResources.Parameters.Pieces.Label)
              }
              errors={errors}
              mask={Number}
              scale={decimalPlaces}
              isMaskLazy={false}
              inputProps={{
                inputMode: decimalPlaces ? "decimal" : "numeric",
                pattern: decimalPlaces
                  ? `[0-9]*[.,]?[0-9]{0,${decimalPlaces}}`
                  : "[0-9]*",
              }}
              maskPlaceholder=" "
              max={Number.MAX_SAFE_INTEGER}
              min={0}
              isNumber
              inputEndAdornment={
                <InputAdornment position="end">
                  {method === ContractExchangeAssetsMethod.Amount
                    ? getLocalizedCurrencyCode(portfolioItem.currency)
                    : t(Resources.Common.QuantityUnit)}
                </InputAdornment>
              }
              disabled={isExchangeAll}
              hasTracking
            />

            <RateLabel
              feeRate="single"
              sourceIsin={sourceIsin}
              targetIsin={targetIsin}
              sourceContractID={contractID}
              targetContractID={contractID}
              sourceContractTypeCode={contractTypeCode}
              targetContractTypeCode={contractTypeCode}
              amountOrPieces={amountOrPieces}
              isPieces={method === ContractExchangeAssetsMethod.Pieces}
              investmentLengthYears={null}
              calculationType={ProductFeeRateCalculationType.ExchangeAssets}
              translations={{
                single: {
                  label: t(ComponentResources.Parameters.FeeRate.Single.Label),
                },
              }}
            />

            <BlCheckboxFormField
              control={control}
              name="isExchangeAll"
              label={t(ComponentResources.Parameters.ExchangeAll)}
              errors={errors}
              onChange={x => handleIsExchangeAllChange(x, method)}
            />
          </Stack>
        </ComponentTitleWrapper>
      </div>

      <PrimaryButton
        isLoading={isLoading}
        color="primary"
        trackingTag={t(ComponentResources.Submit)}
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
      >
        {t(ComponentResources.Submit)}
      </PrimaryButton>
    </>
  );
};
