import {
  ClientVerificationTypeQueryResult,
  getClientVerificationType,
} from "Api/Api";
import { produce } from "immer";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ClientVerificationTypeState = {
  isLoading: boolean;
  isBankIDVerified: boolean;
  error?: Error | null;
};

function getInitialState(): ClientVerificationTypeState {
  return {
    isLoading: false,
    isBankIDVerified: false,
    error: null,
  };
}

export type ClientVerificationTypeActionType = ActionType<
  typeof clientVerificationTypeAsync
>;

export const clientVerificationTypeAsync = createAsyncAction(
  "@client/GET_VERIFICATION_TYPE_REQUEST",
  "@client/GET_VERIFICATION_TYPE_SUCCESS",
  "@client/GET_VERIFICATION_TYPE_FAILURE",
)<void, ClientVerificationTypeQueryResult, Error>();

function* clientVerificationType(
  action: ActionType<typeof clientVerificationTypeAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(getClientVerificationType);

    if (error) {
      yield put(clientVerificationTypeAsync.failure(error));
      return;
    }

    yield put(clientVerificationTypeAsync.success(response));
  } catch (err) {
    yield put(clientVerificationTypeAsync.failure(err as Error));
  }
}

export function* watchClientVerificationTypeSaga() {
  yield takeLeading(
    getType(clientVerificationTypeAsync.request),
    clientVerificationType,
  );
}

export const clientVerificationTypeReducer = createReducer<
  ClientVerificationTypeState,
  ClientVerificationTypeActionType
>(getInitialState())
  .handleAction(clientVerificationTypeAsync.request, state =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(clientVerificationTypeAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(clientVerificationTypeAsync.success, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.isBankIDVerified = action.payload.isBankIDVerified;
      return draft;
    }),
  );
