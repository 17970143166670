import {
  BankIDProcessAudience,
  BankIDProfileResponse,
  BankItemDto,
} from "Api/Api";
import { ValidatedPersonalNumberNationalities } from "Constants/Contracts/Create";
import { RootStateType } from "State/Store";
import {
  profileIdCardsScopeClaimsErrorFilter,
  nationalitiesScopeClaimsMapper,
  mapBankIDProfileResponse,
  validateAllRequiredBankIDFields,
  getPersonalDocumentType,
} from "Utils/BankIDUtils";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { call, put, select, takeLatest } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from "typesafe-actions";
import { track } from "Utils/TrackingUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { ValidationError } from "yup";
import { notNoU } from "Utils/ObjectUtils";
import {
  AllowedBankIdDocumentTypes,
  BankIdClaims,
  BankIdScopes,
  TrackableBankIdErrors,
} from "Models/BankID";

export const setBankIDProfile = createAsyncAction(
  "@contract/SET_BANK_ID_PROFILE_REQUEST",
  "@contract/SET_BANK_ID_PROFILE_SUCCESS",
  "@contract/SET_BANK_ID_PROFILE_FAILURE",
)<
  {
    processAudience: BankIDProcessAudience;
    profile: BankIDProfileResponse;
    t: (resourcePath: string, options?: any) => string;
  },
  ReturnType<typeof mapBankIDProfileResponse>,
  [Error, Partial<ReturnType<typeof mapBankIDProfileResponse>> | undefined]
>();

export const clearBankIDProfileError = createAction(
  "@contract/CLEAR_BANK_ID_PROFILE_ERROR",
)();

function* getBankIDProfile(
  action: ActionType<typeof setBankIDProfile.request>,
): Generator {
  try {
    const banks = (yield select(
      (s: RootStateType) => s.codeList.banks.data?.bankList,
    )) as BankItemDto[] | undefined;

    const mappedBankIdResponse = mapBankIDProfileResponse(
      action.payload.profile,
      banks ?? [],
      action.payload.t,
    );

    try {
      yield* call(
        validateAllRequiredBankIDFields,
        mappedBankIdResponse,
        action.payload.processAudience,
      );
    } catch (err) {
      const validationError = err as ValidationError;
      yield put(setBankIDProfile.failure(err as Error, mappedBankIdResponse));

      const errors = validationError.inner
        .filter(error => notNoU(error.params?.label))
        .map(error => error.params!.label as string);

      const distinctErrors = Array.from(new Set(errors));
      const idCard = action.payload.profile.idcards?.find(
        ({ type }) =>
          AllowedBankIdDocumentTypes.some(
            allowedType => allowedType === type,
          ) && notNoU(getPersonalDocumentType(type)),
      );

      if (
        distinctErrors.some(
          error =>
            idCard &&
            error === `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`,
        )
      ) {
        track({
          category: "IDinvalidDateValidTo",
          event: "BankID",
          action: "GetProfile",
          url: getPath(AppRouting.BankIDCallback),
          value: idCard?.valid_to,
        });
      }

      if (
        distinctErrors.some(
          error =>
            !idCard &&
            error === `${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`,
        )
      ) {
        track({
          category: "IDblock",
          event: "BankID",
          action: "GetProfile",
          url: getPath(AppRouting.BankIDCallback),
          value: action.payload.profile.idcards
            ?.map(document => document.type)
            .join(" "),
        });
      }

      const trackableErrors = distinctErrors.filter(
        error =>
          TrackableBankIdErrors.some(
            trackableError => trackableError === error,
          ) && profileIdCardsScopeClaimsErrorFilter(idCard, error),
      );

      if (trackableErrors.length) {
        track({
          category: "InsufficientNecessarilyData",
          event: "BankID",
          action: "GetProfile",
          url: getPath(AppRouting.BankIDCallback),
          value: trackableErrors
            .map(error =>
              nationalitiesScopeClaimsMapper(
                action.payload.profile.primary_nationality,
                action.payload.profile.nationalities,
                error,
              ),
            )
            .join(", "),
        });
      }

      return;
    }

    const [personalData] = mappedBankIdResponse;

    // validate gender
    if (!personalData.sex) {
      const isValidated = personalData.nationalities.some(({ value }) =>
        ValidatedPersonalNumberNationalities.includes(value),
      );

      if (isValidated) {
        const personalIdentification = PersonalIdentification.getInfo(
          personalData.personalIdentificationNumber,
        );

        if (personalIdentification.IsValid && personalIdentification.Gender) {
          mappedBankIdResponse[0].sex = personalIdentification.Gender;
          yield put(setBankIDProfile.success(mappedBankIdResponse));
          return;
        }
      }

      yield put(setBankIDProfile.failure(new Error(), undefined));
    } else {
      yield put(setBankIDProfile.success(mappedBankIdResponse));
    }
  } catch (err) {
    yield put(setBankIDProfile.failure(err as Error, undefined));
  }
}

export function* setBankIDProfileSaga() {
  yield takeLatest(getType(setBankIDProfile.request), getBankIDProfile);
}

export type SetBankIDProfileActionType =
  | ActionType<typeof setBankIDProfile>
  | ActionType<typeof clearBankIDProfileError>;
