import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import { PersonalDataFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isBankID: boolean;
};

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.PersonalData;

export const PersonalDataForm: FunctionComponent<Props> = ({ isBankID }) => {
  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalDataFormModel>();

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)} marginTop={0}>
      <BlInputFormField
        control={control}
        name="personalData.lastName"
        errors={errors}
        label={t(ComponentResources.LastName)}
        disabled={isBankID}
      />
    </InputHeaderWrapper>
  );
};
