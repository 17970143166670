import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankiDIcon } from "Components/Shared/Icons";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { PageTitle } from "Components/Shared/PageTitle";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useYupFormSchema } from "Hooks/More/PersonalData/EditPersonalData/PathSelection/useYupFormSchema";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useIsAnyContractBankIDVerified } from "Hooks/useIsAnyContractBankIDVerified";
import { CodeListItem } from "Models/CodeListItem";
import { FunctionComponent, ReactNode, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  EditPersonalDataSteps,
  PathSelectionFormModel,
  setEditPersonalDataActualStep,
  setEditPersonalDataFormData,
} from "State/Client/EditPersonalData/EditPersonalDataState";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

const StyledUnderline = styled.span`
  text-decoration: underline;
`;

const StyledBankIdNegativeIcon = styled(BankiDIcon)`
  display: block;
  height: 16px;
  margin-top: 3px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const PageResource = Resources.More.PersonalData.EditPersonalData;
const ComponentResources = PageResource.PathSelection;

export const PathSelectionStep: FunctionComponent = _ => {
  const { t } = useResource();
  const { isBankIDVerified } = useIsAnyContractBankIDVerified();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.editPersonalData,
  );

  const title = usePageTitle();
  const dispatch = useAppDispatch();

  const schema = useYupFormSchema();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PathSelectionFormModel>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (formData: PathSelectionFormModel) => {
    dispatch(setEditPersonalDataFormData({ ...defaultValues, ...formData }));
    if (formData.isBankID) {
      dispatch(
        setEditPersonalDataActualStep(EditPersonalDataSteps.BankIDRedirect),
      );
      return;
    }

    dispatch(setEditPersonalDataActualStep(EditPersonalDataSteps.Modelling));
  };

  const paths = useMemo(() => {
    const manualPath: CodeListItem<boolean, ReactNode> = {
      code: false,
      name: (
        <Typography component="span" variant="h4">
          {t(ComponentResources.Manual.Label)}
        </Typography>
      ),
      description: t(ComponentResources.Manual.Description),
    };

    return isBankIDVerified
      ? [
          {
            code: true,
            name: (
              <div>
                <StyledBankIdNegativeIcon height={50} width={undefined} />
                <Typography component="span" variant="h4">
                  {t(ComponentResources.BankID.Label[1])}
                  <StyledUnderline>
                    {t(ComponentResources.BankID.Label[2])}
                  </StyledUnderline>
                </Typography>
                <BlInfoPopper color="primary" icon="info">
                  {t(ComponentResources.BankID.Description)}
                </BlInfoPopper>
              </div>
            ),
          },
          manualPath,
        ]
      : [manualPath];
  }, [isBankIDVerified, t]);

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {title}
        </PageTitle>

        <Typography marginBottom={8}>
          {t(ComponentResources.Description)}
        </Typography>

        <RadioButtons<PathSelectionFormModel, boolean>
          codeList={paths}
          control={control}
          name="isBankID"
          errors={errors}
          spacing={4}
        />
      </div>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.Continue)}
        fullWidth
        disabled={!isValid}
      >
        {t(ComponentResources.Continue)}
      </PrimaryButton>
    </>
  );
};

export const PathSelectionLoadingSkeleton = (
  <>
    <Stack gap={5}>
      <PrimarySkeleton fullWidth fullHeight={false} height={50} />
      <PrimarySkeleton fullWidth fullHeight={false} height={150} />
    </Stack>
    <PrimarySkeleton fullWidth fullHeight={false} height={75} />
  </>
);
