import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { PhoneVerification } from "Components/ContractCreate/Shared/PhoneVerification";
import { PageTitle } from "./Shared/PageTitle";
import { useCreateContractStep } from "Hooks/Contract/useCreateContractStep";
import { useFormData } from "Hooks/Contract/useFormData";
import { useAppSelector } from "Hooks/useAppSelector";
import { useContactConfirmation } from "Hooks/useContactConfirmation";
import { PageStepProps } from "Pages/Contracts/CreatePage";
import {
  ContantVerificationFormModel,
  NewContractStep,
} from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { track } from "Utils/TrackingUtils";
import { useEffect, type FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema, object, string } from "yup";
import { useModelingColor } from "Hooks/useModelingColor";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";

const PageResources = Resources.Contract.NewContract.ContactVerification;

type Props = PageStepProps;

export const PersonalDataVerification: FunctionComponent<Props> = ({
  isActive,
}) => {
  const { t } = useResource();
  const schema = useYupFormSchema();
  const { color, contrastColor } = useModelingColor();

  const { defaultValues } = useFormData<NewContractStep.PersonalData>({
    step: NewContractStep.PersonalData,
  });
  const isPhoneVerified = useAppSelector(
    s => s.contracts.create.isPhoneVerified,
  );
  const { nextStep } = useCreateContractStep();

  const form = useForm<ContantVerificationFormModel>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit, setError } = form;

  const onSubmit = (formData: ContantVerificationFormModel) => {
    track({
      category: "SMSVerification",
      event: "Submit",
    });
    nextStep({ formData });
  };

  const {
    isLoadingSendCommand,
    isLoadingVerification,
    sendValidationCode,
    validateCode,
    requested,
    requestedNumber,
    validated,
  } = useContactConfirmation({
    onConfirmAccept: () => handleSubmit(onSubmit)(),
    onConfirmReject: () => {
      setError("code", {
        message: t(PageResources.BadCode),
      });
    },
    active: isActive,
  });

  useEffect(() => {
    // ignore, if we already requested code for this number
    if (requested && requestedNumber === defaultValues?.phone) {
      return;
    }

    if (isActive && defaultValues?.phone && !validated) {
      sendValidationCode(defaultValues.phone);
    }
  }, [
    defaultValues?.phone,
    isActive,
    sendValidationCode,
    requested,
    requestedNumber,
    validated,
  ]);

  const onValidate = (formData: ContantVerificationFormModel) => {
    validateCode(formData.code);
  };

  const skipValidation = validated || isPhoneVerified;

  return (
    <>
      <div>
        <PageTitle>{t(PageResources.Title)}</PageTitle>

        {skipValidation ? (
          <Typography>{t(PageResources.PhoneValidated)}</Typography>
        ) : (
          <FormProvider {...form}>
            <PhoneVerification
              isLoading={isLoadingSendCommand}
              phone={defaultValues?.phone ?? ""}
              translations={{
                codeDetermination: t(PageResources.CodeDetermination),
              }}
              onResendCode={sendValidationCode}
              onSubmit={onSubmit}
            />
          </FormProvider>
        )}
      </div>
      <PrimaryButton
        color="primary"
        onClick={skipValidation ? () => nextStep() : handleSubmit(onValidate)}
        isLoading={isLoadingVerification}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};

const useYupFormSchema = (): ObjectSchema<ContantVerificationFormModel> => {
  return object<ContantVerificationFormModel>().shape({
    code: string()
      .matches(/^.{4}$/)
      .required(),
  });
};
