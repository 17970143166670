import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const PageResources = Resources.More.PersonalData.EditAML;
const ComponentResources = Resources.More.PersonalData.EditAML.Signature;

export const SignatureStep: FunctionComponent = () => {
  const { t } = useResource();
  const { isLoading, phone, signatureHash, error } = useAppSelector(
    s => s.client.editAml,
  );

  const navigate = useNavigate();

  return (
    <SignatureForm
      isLoading={isLoading}
      contractID={undefined}
      signatureHash={signatureHash}
      sms={{
        phone: phone ?? "",
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.EditAml,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: t(PageResources.Title),
          codeSent: t(Resources.Signature.SmsSent),
          codeDetermination: t(ComponentResources.CodeDetermination),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.SubmitButton),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      error={error}
      onSuccess={() =>
        navigate(getPath(AppRouting.PersonalData, PersonalDataTabs.Aml))
      }
    />
  );
};
