import { Box, CardContent, Typography } from "@mui/material";
import { ProductItemDto } from "Api/Api";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { InfoIcon } from "Components/Shared/Icons";
import { RadioButton } from "Components/Shared/Inputs/RadioButton";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { Resources, useResource } from "Translations/Resources";
import { ChangeEvent, type FunctionComponent } from "react";
import styled from "styled-components";

const ComonentResources = Resources.Contract.Detail.Purchase;

const StyledProductCard = styled(StyledDarkCard)`
  .MuiCardContent-root {
    padding-left: 0;
  }
`;

export type ProductCardProduct = Pick<
  ProductItemDto,
  | "isin"
  | "minPerformance"
  | "maxPerformance"
  | "productName"
  | "color"
  | "classCode"
  | "singleMinInvestment"
  | "singleMaxInvestment"
  | "periodicalMinInvestment"
  | "periodicalMaxInvestment"
>;

type Props = {
  onCheck: (isin: string | null | undefined) => void;
  onDetail: (isin: string | null | undefined) => void;
  checked: boolean;
} & ProductCardProduct;

export const ProductCard: FunctionComponent<Props> = ({
  minPerformance,
  maxPerformance,
  isin,
  checked,
  productName,
  classCode,
  onCheck,
  onDetail,
}) => {
  const { t } = useResource();

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onCheck(isin);
    }
  };

  const isDetailShown =
    minPerformance !== undefined && maxPerformance !== undefined;

  const productClass = useFormattedProductClass(isin);

  return (
    <StyledProductCard>
      <CardContent>
        <StyledFlex $gap={3}>
          <RadioButton checked={checked} onChange={handleCheck} />
          <Box flexGrow={1} onClick={() => onCheck(isin)}>
            <ProductLogo
              isin={isin!}
              name={productClass}
              imageHeight={35}
              nameNoWrap
            />
            {isDetailShown && (
              <Box marginTop={1}>
                <Typography color="secondary" component="span" marginRight={1}>
                  {t(ComonentResources.ExpectedAppreciation)}
                </Typography>
                <Typography color="secondary" component="span" fontWeight={600}>
                  {t(ComonentResources.ExpectedAppreciationValue, {
                    min: minPerformance,
                    max: maxPerformance,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </Box>
            )}
          </Box>
          {isDetailShown && (
            <div style={{ cursor: "pointer" }}>
              <IconCircleWrapper
                size={18}
                transparent
                onClick={() => onDetail(isin)}
                asButton={true}
              >
                <InfoIcon />
              </IconCircleWrapper>
            </div>
          )}
        </StyledFlex>
      </CardContent>
    </StyledProductCard>
  );
};
