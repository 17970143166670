import { CircularProgress, Typography } from "@mui/material";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import { DocumentsIcon, DownloadIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { getDownloadAsync } from "State/Documents/Download/GetDownloadState";
import { Resources, useResource } from "Translations/Resources";
import { track } from "Utils/TrackingUtils";
import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { Colors } from "Components/Layout/Themes/Colors";
import { alpha } from "@mui/material";
import {
  IGNORE_DOCUMENT_CACHE,
  formatDocumentLink,
} from "Utils/ExternalLinkUtils";

type Props = {
  name: string;
  href?: string;
  contractID?: number;
  icon?: ReactNode;
  onClick?: () => void;
  hrefDownloadText?: string;
  disabled?: boolean;
  ignoreCache?: boolean;
};

const StyledWrapper = styled.div<{ $pointer?: boolean; $disabled?: boolean }>`
  cursor: ${props => (props.$pointer ? "pointer" : "default")};
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(2)};
  border: 1px dashed ${props => props.theme.palette.grey[600]};
  border-radius: 8px;
  margin-bottom: ${props => props.theme.spacing(2)};

  background-color: ${props =>
    props.$disabled ? alpha(Colors.White, 0.05) : "transparent"};
  pointer-events: ${props => (props.$disabled ? "none" : "auto")};
  color: ${props =>
    props.$disabled ? props.theme.palette.text.secondary : "inherit"};

  svg {
    color: ${props => props.theme.palette.text.secondary};
    width: 21px;
    height: 21px;
  }

  &:hover {
    background-color: ${alpha(Colors.White, 0.05)};
  }
`;

const StyledFileName = styled(Typography)`
  padding-right: ${props => props.theme.spacing(3)};
`;

export const Document: FunctionComponent<Props> = ({
  name,
  icon,
  href,
  onClick,
  hrefDownloadText,
  disabled,
  ignoreCache = IGNORE_DOCUMENT_CACHE,
  contractID,
}) => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const link = formatDocumentLink(href, ignoreCache);
  const downloadState = useAppSelector(s => s.documents.download);

  const isLoading = downloadState[name]?.isLoading ?? false;

  const onDownload = () => {
    if (disabled) {
      return;
    }

    if (!isLoading) {
      track({
        category: "Document",
        action: "Download",
        event: "Click",
        value: name,
      });

      dispatch(getDownloadAsync.request({ contractID, fileName: name }));
    }
  };

  const onOpen = () => {
    if (disabled) {
      return;
    }

    if (link) {
      track({
        category: "Document",
        action: "Open",
        event: "Click",
        url: link,
      });

      if (onClick) {
        onClick();
      } else {
        window.open(link);
      }
    }
  };

  return (
    <StyledWrapper
      onClick={link ? onOpen : onDownload}
      $pointer={!isLoading}
      $disabled={disabled}
    >
      <StyledFlex
        $alignItems="center"
        $gap={3}
        $overflow="hidden"
        $flexGrow={1}
        role="button"
      >
        <IconCircleWrapper size={35}>
          {isLoading ? (
            <CircularProgress size={21} />
          ) : (
            icon ?? (link ? <DocumentsIcon /> : <DownloadIcon />)
          )}
        </IconCircleWrapper>
        <StyledFileName noWrap>{name}</StyledFileName>
      </StyledFlex>
      {!link ? (
        <TextButton color="secondary" disabled={isLoading}>
          {isLoading
            ? `${t(Resources.Common.Downloading)}...`
            : t(Resources.Common.Download)}
        </TextButton>
      ) : (
        <>
          {hrefDownloadText && (
            <TextButton disabled={disabled}>{hrefDownloadText}</TextButton>
          )}
        </>
      )}
    </StyledWrapper>
  );
};
