import {
  BankIDProcessAudience,
  UpdateClientPersonalDataCommandResult,
  UpdateClientPersonalDataRequest,
  PersonalDocumentType,
  postClientPersonalData,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { clientVerificationTypeAsync } from "State/Client/VerificationType/ClientVerificationTypeState";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { sendClientVerificationCodeAsync } from "State/Contracts/Biometrics/SendClientVerificationCodeState";
import { setBankIDProfile } from "State/Contracts/Create/BankIDProfile/SetBankIDProfile";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type PathSelectionFormModel = Pick<
  UpdateClientPersonalDataRequest,
  "isBankID"
>;

export type PersonalDataFormModel = Pick<
  UpdateClientPersonalDataRequest,
  "personalData"
>;

export type ContactInfoFormModel = Pick<
  UpdateClientPersonalDataRequest,
  "contactInfo"
>;

export type PrimaryDocumentFormModel = Pick<
  UpdateClientPersonalDataRequest,
  "primaryDocument"
>;

export type PermanentAddressFormModel = Pick<
  UpdateClientPersonalDataRequest,
  "permanentAddress"
>;

export type EditPersonalDataFormModel = PathSelectionFormModel &
  PersonalDataFormModel &
  ContactInfoFormModel &
  PrimaryDocumentFormModel &
  PermanentAddressFormModel;

export enum EditPersonalDataSteps {
  PathSelection,
  BankIDRedirect,
  BankIDCallback,
  Modelling,
  Signature,
  ClientVerification,
}

export type EditPersonalDataState = {
  isLoading: boolean;
  isStateResetAllowed: boolean;
  bankID: {
    personalIdentificationNumber?: string | null;
  };
  actualStep: EditPersonalDataSteps;
  lastRequest: UpdateClientPersonalDataRequest & {
    isContactInfoChange: boolean;
    isCodeSendToClientEmail: boolean;
  };
  signatureHash?: string | null;
  requestHash?: string | null;
  clientVerificationHash?: string | null;
  error?: Error | null;
};

function getInitialState(): EditPersonalDataState {
  return {
    isLoading: false,
    isStateResetAllowed: true,
    bankID: {
      personalIdentificationNumber: null,
    },
    actualStep: EditPersonalDataSteps.PathSelection,
    lastRequest: {
      isBankID: false,
      isContactInfoChange: false,
      isCodeSendToClientEmail: false,
      contactInfo: {
        phone: "",
        email: "",
      },
      personalData: {
        lastName: "",
      },
      permanentAddress: {
        streetName: "",
        streetNumber: "",
        streetConscriptionNumber: "",
        postalCode: "",
        city: "",
        country: "",
      },
      primaryDocument: {
        type: PersonalDocumentType.IdentityCard,
        number: "",
        issuingAuthority: "",
        issueCountry: "",
        issueDate: "",
        expiryDate: "",
        frontScan: null,
        backScan: null,
      },
      isBiometry: false,
    },
    signatureHash: null,
    requestHash: null,
    error: null,
  };
}

export const resetEditPersonalDataState = createAction(
  "@client/RESET_PERSONAL_DATA_EDIT_STATE",
)<void>();

export const setEditPersonalDataFormData = createAction(
  "@client/SET_PERSONAL_DATA_EDIT_FORM_DATA",
)<
  UpdateClientPersonalDataRequest & {
    isContactInfoChange: boolean;
    isCodeSendToClientEmail: boolean;
  }
>();

export const setEditPersonalDataActualStep = createAction(
  "@client/SET_PERSONAL_DATA_EDIT_ACTUAL_STEP",
)<EditPersonalDataSteps>();

export const setEditPersonalDataIsBankIDVerified = createAction(
  "@client/SET_PERSONAL_DATA_EDIT_IS_BANK_ID_VERIFIED",
)<boolean>();

export const editPersonalDataAsync = createAsyncAction(
  "@client/POST_PERSONAL_DATA_EDIT_REQUEST",
  "@client/POST_PERSONAL_DATA_EDIT_SUCCESS",
  "@client/POST_PERSONAL_DATA_EDIT_FAILURE",
)<
  UpdateClientPersonalDataRequest & {
    isContactInfoChange: boolean;
    isCodeSendToClientEmail: boolean;
  },
  UpdateClientPersonalDataCommandResult & { phone?: string | null },
  Error
>();

export type EditPersonalDataActions =
  | ActionType<typeof editPersonalDataAsync>
  | ActionType<typeof resetEditPersonalDataState>
  | ActionType<typeof setEditPersonalDataFormData>
  | ActionType<typeof setEditPersonalDataActualStep>
  | ActionType<typeof setEditPersonalDataIsBankIDVerified>
  | ActionType<typeof setBankIDProfile>
  | ActionType<typeof clientVerificationTypeAsync>;

function* editPersonalData(
  action: ReturnType<typeof editPersonalDataAsync.request>,
): Generator {
  try {
    const { isBiometry, isCodeSendToClientEmail, isContactInfoChange } =
      action.payload;
    const { response, error } = yield* safeApiCall(
      postClientPersonalData,
      action.payload,
    );

    if (!!error) {
      yield put(editPersonalDataAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        editPersonalDataAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (isContactInfoChange) {
      if (!response.clientVerificationHash) {
        yield put(
          editPersonalDataAsync.failure(
            new ApplicationError("Client verification hash is required."),
          ),
        );
        return;
      }

      if (isBiometry) {
        yield put(
          sendClientVerificationCodeAsync.request({
            isCodeSendToClientEmail,
            clientVerificationHash: response.clientVerificationHash,
          }),
        );
      }

      if (!isBiometry) {
        yield put(
          initializeSmsSignatureAsync.request({
            signatureHash: response.signatureHash,
          }),
        );
      }

      yield put(editPersonalDataAsync.success(response));
      return;
    }

    if (isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.EditPersonalData),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(editPersonalDataAsync.success(response));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(editPersonalDataAsync.success(response));
  } catch (err) {
    yield put(editPersonalDataAsync.failure(err as Error));
  }
}

export function* watchEditPersonalDataSaga() {
  yield takeLeading(getType(editPersonalDataAsync.request), editPersonalData);
}

export const editPersonalDataReducer = createReducer<
  EditPersonalDataState,
  EditPersonalDataActions
>(getInitialState())
  .handleAction(editPersonalDataAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.lastRequest = action.payload;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editPersonalDataAsync.success, (state, action) =>
    produce(state, draft => {
      const { requestHash, signatureHash, clientVerificationHash } =
        action.payload;

      draft.isLoading = false;
      draft.requestHash = requestHash;
      draft.signatureHash = signatureHash;
      draft.clientVerificationHash = clientVerificationHash;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editPersonalDataAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditPersonalDataActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditPersonalDataFormData, (state, action) =>
    produce(state, draft => {
      draft.lastRequest = action.payload;
      return draft;
    }),
  )
  .handleAction(resetEditPersonalDataState, state =>
    produce(state, draft => {
      if (!state.isStateResetAllowed) {
        draft.isStateResetAllowed = true;
        return draft;
      }

      return getInitialState();
    }),
  )
  .handleAction(setBankIDProfile.request, (state, action) =>
    produce(state, draft => {
      const { processAudience } = action.payload;
      if (processAudience !== BankIDProcessAudience.EditPersonalData) {
        return state;
      }

      draft = getInitialState();
      draft.actualStep = EditPersonalDataSteps.BankIDCallback;
      draft.isStateResetAllowed = false;
      draft.lastRequest.isBankID = true;

      return draft;
    }),
  )
  .handleAction(
    setBankIDProfile.success,
    (state, { payload: [personalData, addresses, , document] }) =>
      produce(state, draft => {
        const {
          lastRequest: { isBankID },
        } = state;

        if (!isBankID) {
          return state;
        }

        draft.bankID.personalIdentificationNumber =
          personalData.personalIdentificationNumber;

        draft.lastRequest.isBankID = true;
        draft.lastRequest.personalData = {
          lastName: personalData.lastName,
        };

        draft.lastRequest.permanentAddress = {
          streetName: addresses.addresses.permanentAddress.street,
          streetNumber: addresses.addresses.permanentAddress.streetNumber,
          streetConscriptionNumber:
            addresses.addresses.permanentAddress.streetConscriptionNumber ?? "",
          postalCode: addresses.addresses.permanentAddress.postalCode,
          city: addresses.addresses.permanentAddress.city,
          country: addresses.addresses.permanentAddress.country,
        };

        if (document) {
          draft.lastRequest.primaryDocument = {
            type: document.type,
            number: document.number,
            issueDate: document.dateValidFrom,
            expiryDate: document.dateExpiry ?? "",
            issuingAuthority: document.issuingAuthority,
            issueCountry: document.countryIssue,
          };
        }

        return draft;
      }),
  )
  .handleAction(setBankIDProfile.failure, (state, action) =>
    produce(state, draft => {
      const {
        lastRequest: { isBankID },
      } = state;

      if (!isBankID) {
        return state;
      }

      draft.error = action.payload;
      draft.lastRequest.isBankID = false;
      return draft;
    }),
  )
  .handleAction(setEditPersonalDataIsBankIDVerified, (state, action) =>
    produce(state, draft => {
      const isVerified = action.payload;
      if (isVerified) {
        draft.lastRequest.isBankID = true;
      }

      if (!isVerified) {
        const { lastRequest } = getInitialState();
        draft.lastRequest = {
          ...lastRequest,
          isBankID: false,
        };
      }

      draft.actualStep = EditPersonalDataSteps.Modelling;
      return draft;
    }),
  )
  .handleAction(clientVerificationTypeAsync.success, (state, action) =>
    produce(state, draft => {
      if (!state.isStateResetAllowed) {
        return draft;
      }

      if (action.payload.isBankIDVerified) {
        draft.actualStep = EditPersonalDataSteps.PathSelection;
        return draft;
      }

      draft.actualStep = EditPersonalDataSteps.Modelling;
      return draft;
    }),
  );
