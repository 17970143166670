import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useDesktop } from "Hooks/useDesktop";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const ComponentResources = Resources.More.PersonalData.Tabs.AML;

export const Aml: FunctionComponent = () => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();

  const isLead = useIsLead();
  const navigate = useNavigate();

  return (
    <StyledFlex
      $flexDirection="column"
      $justifyContent={isDesktop ? undefined : "space-between"}
      $gap={isDesktop ? 10 : 0}
      $fullHeight
      $flex="1"
    >
      <Typography>{t(ComponentResources.Description)}</Typography>
      {!isLead && (
        <PrimaryButton
          color="primary"
          onClick={() => navigate(getPath(AppRouting.EditAml))}
          trackingTag={t(ComponentResources.Edit)}
          disabled={isLead}
        >
          {t(ComponentResources.Edit)}
        </PrimaryButton>
      )}
    </StyledFlex>
  );
};
