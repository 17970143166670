import { Stack, Typography } from "@mui/material";
import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useEffect } from "react";
import { clientDocumentsAsync } from "State/Client/Documents/ClientDocumentsState";
import { Resources, useResource } from "Translations/Resources";
import { Document } from "Components/Shared/Document";

const ComponentResources = Resources.More.PersonalData.Tabs.Documents;

export const Documents: FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const {
    isLoading: isLoadingDocuments,
    documents,
    error,
  } = useAppSelector(s => s.client.documents);

  useEffect(() => {
    if (!documents) {
      dispatch(clientDocumentsAsync.request());
    }
  }, [documents, dispatch]);

  const isLoading = isLoadingDocuments && !documents;

  return (
    <ComponentTitleWrapper
      title={t(ComponentResources.DocumentsLabel)}
      marginTop={0}
    >
      <Stack gap={2}>
        <LoadingWrapper
          isLoading={isLoading}
          skeleton={<LoadingSkeleton />}
          error={error}
        >
          {documents?.map(({ fileName }) => (
            <Document key={fileName} name={fileName} />
          ))}
          {!documents?.length && (
            <Typography>{t(ComponentResources.DocumentsNotFound)}</Typography>
          )}
        </LoadingWrapper>
      </Stack>
    </ComponentTitleWrapper>
  );
};

const LoadingSkeleton = () => (
  <>
    <PrimarySkeleton height={55} />
    <PrimarySkeleton height={55} />
  </>
);
