import { useAppSelector } from "Hooks/useAppSelector";
import { useEffect } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { clientVerificationTypeAsync } from "State/Client/VerificationType/ClientVerificationTypeState";

export const useIsAnyContractBankIDVerified = (refetch: boolean = false) => {
  const dispatch = useAppDispatch();

  const { isLoading, isBankIDVerified, error } = useAppSelector(
    s => s.client.verificationType,
  );

  useEffect(() => {
    if (refetch) {
      dispatch(clientVerificationTypeAsync.request());
    }
  }, [refetch, dispatch]);

  return {
    isLoading,
    isBankIDVerified,
    error,
  };
};
