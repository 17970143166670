import {
  UpdateAmlCommandResult,
  UpdateAmlRequest,
  getUserContact,
  postClientAml,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export enum EditAmlSteps {
  Modelling,
  Signature,
}

export type EditAmlState = {
  isLoading: boolean;
  actualStep: EditAmlSteps;
  formData?: AMLQuestionsFormModel | null;
  lastRequest?: UpdateAmlRequest | null;
  phone?: string | null;
  requestHash?: string | null;
  signatureHash?: string | null;
  error?: Error | null;
};

function getInitialState(): EditAmlState {
  return {
    isLoading: false,
    actualStep: EditAmlSteps.Modelling,
    error: null,
  };
}

export const resetEditAmlState = createAction(
  "@client/RESET_AML_EDIT_STATE",
)<void>();

export const setEditAmlFormData = createAction(
  "@client/SET_AML_EDIT_FORM_DATA",
)<AMLQuestionsFormModel>();

export const setEditAmlActualStep = createAction(
  "@client/SET_AML_EDIT_ACTUAL_STEP",
)<EditAmlSteps>();

export const editAmlAsync = createAsyncAction(
  "@client/POST_AML_REQUEST",
  "@client/POST_AML_SUCCESS",
  "@client/POST_AML_FAILURE",
)<
  UpdateAmlRequest,
  UpdateAmlCommandResult & { phone?: string | null },
  Error
>();

export type EditAmlActions =
  | ActionType<typeof editAmlAsync>
  | ActionType<typeof setEditAmlActualStep>
  | ActionType<typeof setEditAmlFormData>
  | ActionType<typeof resetEditAmlState>;

function* editAml(action: ReturnType<typeof editAmlAsync.request>): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postClientAml,
      action.payload,
    );

    if (error) {
      yield put(editAmlAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        editAmlAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(setBiometricsSignatureType(BiometricsSignatureType.EditAml));

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(editAmlAsync.success(response));

      return;
    }

    const { response: contactResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(editAmlAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      editAmlAsync.success({
        ...response,
        phone:
          contactResponse.contactInfo?.client?.phone ||
          contactResponse.contactInfo?.user.phone,
      }),
    );
  } catch (err) {
    yield put(editAmlAsync.failure(err as Error));
  }
}

export function* watchEditAmlSaga() {
  yield takeLeading(getType(editAmlAsync.request), editAml);
}

export const editAmlReducer = createReducer<EditAmlState, EditAmlActions>(
  getInitialState(),
)
  .handleAction(editAmlAsync.request, (state, action) =>
    produce(state, draft => {
      draft.isLoading = true;
      draft.lastRequest = action.payload;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editAmlAsync.success, (state, action) =>
    produce(state, draft => {
      const { phone, requestHash, signatureHash } = action.payload;
      draft.isLoading = false;
      draft.actualStep = EditAmlSteps.Signature;
      draft.phone = phone;
      draft.requestHash = requestHash;
      draft.signatureHash = signatureHash;
      draft.error = null;
      return draft;
    }),
  )
  .handleAction(editAmlAsync.failure, (state, action) =>
    produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditAmlActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(setEditAmlFormData, (state, action) =>
    produce(state, draft => {
      draft.formData = action.payload;
      return draft;
    }),
  )
  .handleAction(resetEditAmlState, state =>
    produce(state, () => {
      return getInitialState();
    }),
  );
