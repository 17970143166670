import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLeading } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import {
  ContractCreateBankConnectionCommandResult,
  ContractCreateBankConnectionRequest,
  CurrencyCode,
  getUserContact,
  postContractContractIDBankConnections,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";

export enum ContractCreateBankConnectionStep {
  Modelling,
  Signature,
}

export type BankConnectionType = "bankNumber" | "iban";

export type ContractCreateBankConnectionFormModel = {
  type: BankConnectionType;
  iban?: string;
  bank?: string;
  bankCode?: string;
  bankNumber?: string;
  currency: CurrencyCode;
};

export type ContractCreateBankConnectionState = {
  isLoading: boolean;
  contractID?: number;
  actualStep: ContractCreateBankConnectionStep;
  formData: ContractCreateBankConnectionFormModel;
  phone?: string;
  requestHash?: string;
  signatureHash?: string;
  signatureType: "SMS" | "BIOMETRICS";
  error?: Error;
};

export const initialState: ContractCreateBankConnectionState = {
  isLoading: false,
  contractID: undefined,
  actualStep: ContractCreateBankConnectionStep.Modelling,
  formData: {
    type: "bankNumber",
    iban: "",
    bank: "",
    bankCode: "",
    bankNumber: "",
    currency: CurrencyCode.CZK,
  },
  phone: undefined,
  requestHash: undefined,
  signatureHash: undefined,
  signatureType: "SMS",
  error: undefined,
};

export const setContractCreateBankConnectionContractID = createAction(
  "@contract/SET_CONTRACT_CREATE_BANK_CONNECTION_CONTRACT_ID",
)<number>();

export const resetContractCreateBankConnectionState = createAction(
  "@contract/RESET_CONTRACT_CREATE_BANK_CONNECTION_STATE",
)<void>();

export type ContractCreateBankConnectionStateAction =
  | ActionType<typeof setContractCreateBankConnectionContractID>
  | ActionType<typeof resetContractCreateBankConnectionState>
  | ActionType<typeof contractCreateBankConnectionAsync>;

export const contractCreateBankConnectionAsync = createAsyncAction(
  "@contract/POST_CONTRACT_CREATE_BANK_CONNECTION_REQUEST",
  "@contract/POST_CONTRACT_CREATE_BANK_CONNECTION_SUCCESS",
  "@contract/POST_CONTRACT_CREATE_BANK_CONNECTION_FAILURE",
)<
  ContractCreateBankConnectionRequest & {
    contractID: number;
    type: BankConnectionType;
  },
  ContractCreateBankConnectionCommandResult & {
    phone?: string | null;
    signatureType: "SMS" | "BIOMETRICS";
  },
  Error
>();

function* contractCreateBankConnection(
  action: ReturnType<typeof contractCreateBankConnectionAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractContractIDBankConnections,
      {
        ...action.payload,
        iban: action.payload.type === "iban" ? action.payload.iban : undefined,
        bankCode:
          action.payload.type === "bankNumber"
            ? action.payload.bankCode
            : undefined,
        bankNumber:
          action.payload.type === "bankNumber"
            ? action.payload.bankNumber
            : undefined,
      },
      action.payload.contractID,
    );

    if (!!error) {
      yield put(contractCreateBankConnectionAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        contractCreateBankConnectionAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(
          BiometricsSignatureType.CreateBankConnection,
        ),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      yield put(
        contractCreateBankConnectionAsync.success({
          ...response,
          signatureType: "BIOMETRICS",
        }),
      );

      return;
    }

    const { response: contactInfoResponse, error: contactError } =
      yield* safeApiCall(getUserContact);

    if (!!contactError) {
      yield put(contractCreateBankConnectionAsync.failure(contactError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      contractCreateBankConnectionAsync.success({
        ...response,
        phone:
          contactInfoResponse.contactInfo?.client?.phone ||
          contactInfoResponse.contactInfo?.user.phone,
        signatureType: "SMS",
      }),
    );
  } catch (err) {
    yield put(contractCreateBankConnectionAsync.failure(err as Error));
  }
}

export function* contractCreateBankConnectionSaga() {
  yield takeLeading(
    getType(contractCreateBankConnectionAsync.request),
    contractCreateBankConnection,
  );
}

export const contractCreateBankConnectionReducer = createReducer<
  ContractCreateBankConnectionState,
  ContractCreateBankConnectionStateAction
>(initialState)
  .handleAction(setContractCreateBankConnectionContractID, (state, action) => {
    return produce(state, draft => {
      draft.contractID = action.payload;
      return draft;
    });
  })
  .handleAction(resetContractCreateBankConnectionState, (state, _) => {
    return produce(state, _ => {
      return initialState;
    });
  })
  .handleAction(contractCreateBankConnectionAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.actualStep = ContractCreateBankConnectionStep.Signature;
      return draft;
    });
  })
  .handleAction(contractCreateBankConnectionAsync.success, (state, action) => {
    return produce(state, draft => {
      const { phone, signatureType, signatureHash, requestHash } =
        action.payload;

      draft.isLoading = false;
      draft.phone = phone ?? undefined;
      draft.signatureHash = signatureHash ?? undefined;
      draft.requestHash = requestHash ?? undefined;
      draft.signatureType = signatureType;

      return draft;
    });
  })
  .handleAction(contractCreateBankConnectionAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  });
