import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ModellingStep } from "Components/More/PersonalData/EditAml/ModellingStep";
import { SignatureStep } from "Components/More/PersonalData/EditAml/SignatureStep";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { getBusinessSectorAsync } from "State/CodeLists/BusinessSector/GetBusinessSectorState";
import {
  EditAmlSteps,
  resetEditAmlState,
  setEditAmlActualStep,
} from "State/Client/EditAml/EditAmlState";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const PageResources = Resources.More.PersonalData.EditAML;

export const EditAMLPage: FunctionComponent = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.client.editAml);
  const { isDesktop } = useDesktop();

  const isLead = useIsLead();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackClick = useCallback(() => {
    switch (actualStep) {
      case EditAmlSteps.Modelling:
        navigate(getPath(AppRouting.PersonalData, PersonalDataTabs.Aml));
        return;
      case EditAmlSteps.Signature:
        dispatch(setEditAmlActualStep(EditAmlSteps.Modelling));
        return;
    }
  }, [actualStep, navigate, dispatch]);

  useEffect(() => {
    dispatch(getBusinessSectorAsync.request());
    return () => {
      dispatch(resetEditAmlState());
    };
  }, [dispatch]);

  if (isLead) {
    return (
      <Navigate to={getPath(AppRouting.PersonalData, PersonalDataTabs.Aml)} />
    );
  }

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
    >
      {actualStep === EditAmlSteps.Modelling && <ModellingStep />}
      {actualStep === EditAmlSteps.Signature && <SignatureStep />}
    </AuthenticatedLayout>
  );
};
