import { BankIDProcessAudience } from "Api/Api";
import { BankIDRedirect } from "Components/BankID/BankIDRedirect";
import { PageTitle } from "Components/Shared/PageTitle";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

export const BankIDRedirectStep: FunctionComponent = () => {
  const { t } = useResource();

  const title = usePageTitle();

  return (
    <div>
      <PageTitle showOnDesktop showOnMobile={false}>
        {title}
      </PageTitle>
      <BankIDRedirect
        isActive
        processAudience={BankIDProcessAudience.EditPersonalData}
        translations={{
          redirecting: t(
            Resources.Contract.NewContract.BankID.WaitingForBankID,
          ),
        }}
      />
    </div>
  );
};
