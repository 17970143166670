import { StyledFormControl } from "Components/Shared/FormStyles";
import { FileInput } from "Components/Shared/Inputs/FileInput/FileInput";
import { ALLOWED_FILE_MIME_TYPES, MAX_FILE_SIZE_MB } from "Constants/Inputs";
import { FunctionComponent, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import get from "lodash-es/get";
import { PrimaryDocumentFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { useDocumentScan } from "Hooks/More/PersonalData/EditPersonalData/Modelling/useDocumentScan";

type Props = {
  isFrontScan: boolean;
  label: string;
};

export const DocumentScanInput: FunctionComponent<Props> = ({
  isFrontScan,
  label,
}) => {
  const {
    setError,
    formState: { errors },
  } = useFormContext<PrimaryDocumentFormModel>();

  const {
    isLoading,
    file: currentFile,
    uploadFile,
    deleteFile,
  } = useDocumentScan(isFrontScan);

  const handleChange = (file: File | null) => {
    if (file) {
      uploadFile(file);
      return;
    }

    deleteFile();
  };

  const setFormError = useCallback(
    (x: string) =>
      setError(
        isFrontScan
          ? "primaryDocument.frontScan.documentGuid"
          : "primaryDocument.backScan.documentGuid",
        { message: x },
      ),
    [isFrontScan, setError],
  );

  return (
    <StyledFormControl>
      <FileInput
        maxSizeMB={MAX_FILE_SIZE_MB}
        fieldError={get(
          errors,
          isFrontScan
            ? "primaryDocument.frontScan.documentGuid"
            : "primaryDocument.backScan.documentGuid",
        )}
        onChange={handleChange}
        value={currentFile}
        label={currentFile?.name || label}
        isLoading={isLoading}
        accept={ALLOWED_FILE_MIME_TYPES}
        setFormError={setFormError}
      />
    </StyledFormControl>
  );
};
