import {
  CreateOrUpdateAttachmentCommandResult,
  CreateOrUpdateAttachmentCommandResultStatus,
  postAttachments,
} from "Api/Api";
import { AttachmentMetaType } from "State/Attachments/AttachmentsReducer";
import { safeApiCall } from "State/Utils";
import { getFileBase64 } from "Utils/FileUtils";
import { call, put, takeEvery } from "typed-redux-saga";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";

export const uploadAttachmentAsync = createAsyncAction(
  "@attachments/POST_ATTACHMENT_REQUEST",
  "@attachments/POST_ATTACHMENT_SUCCESS",
  "@attachments/POST_ATTACHMENT_FAILURE",
)<
  [
    {
      file: File;
      onSuccess: (documentGuid: string, fileName: string) => void;
      onFail: (status: CreateOrUpdateAttachmentCommandResultStatus) => void;
    },
    AttachmentMetaType,
  ],
  [CreateOrUpdateAttachmentCommandResult, AttachmentMetaType],
  [Error, AttachmentMetaType]
>();

function* uploadAttachment(
  action: ActionType<typeof uploadAttachmentAsync.request>,
): Generator {
  try {
    const fileAsBase64 = yield* call(getFileBase64, action.payload.file);

    const { response, error } = yield* safeApiCall(postAttachments, {
      ...action.payload.file,
      ...action.meta,
      base64Data: fileAsBase64,
    });

    if (error) {
      yield put(uploadAttachmentAsync.failure(error, action.meta));
      action.payload.onFail(
        error.response?.status ??
          CreateOrUpdateAttachmentCommandResultStatus.Fail,
      );
      return;
    }

    yield put(
      uploadAttachmentAsync.success(response, {
        ...action.meta,
        ...action.payload,
      }),
    );

    action.payload.onSuccess(
      action.meta.attachmentGuid,
      action.payload.file.name,
    );
  } catch (err) {
    yield put(uploadAttachmentAsync.failure(err as Error, action.meta));
  }
}

export function* watchUploadAttachmentSaga() {
  yield takeEvery(getType(uploadAttachmentAsync.request), uploadAttachment);
}
