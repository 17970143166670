import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { FetchStateType, getFetchStateDefaultValue } from "State/Models";
import {
  handleActionFailure,
  handleActionRequest,
  handleActionSuccess,
  safeApiCall,
} from "State/Utils";
import {
  ClientVerifyContactConfirmationCommand,
  ClientVerifyContactConfirmationCommandResult,
  StatusCommandResult,
  putClientContactConfirmation,
} from "Api/Api";
import { userContactVerified } from "State/Shared/UserContactVerified";
import { produce } from "immer";

export type PutContactConfirmationStateType =
  FetchStateType<StatusCommandResult>;

function getInitialState(): PutContactConfirmationStateType {
  return {
    ...getFetchStateDefaultValue(),
  };
}

export const resetPutContactConfirmationState = createAction(
  "@client/RESET_PUT_CONTACT_CONFIRMATION_STATE",
)<void>();

export const putContactConfirmationAsync = createAsyncAction(
  "@client/PUT_CONTACT_CONFIRMATION_REQUEST",
  "@client/PUT_CONTACT_CONFIRMATION_SUCCESS",
  "@client/PUT_CONTACT_CONFIRMATION_FAILURE",
)<
  ClientVerifyContactConfirmationCommand & { onSuccess?: () => void },
  ClientVerifyContactConfirmationCommandResult,
  Error
>();

export type PutContactConfirmationActions =
  | ActionType<typeof resetPutContactConfirmationState>
  | ActionType<typeof putContactConfirmationAsync>;

function* putContactConfirmation(
  action: ActionType<typeof putContactConfirmationAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      putClientContactConfirmation,
      action.payload,
    );

    if (error) {
      yield put(putContactConfirmationAsync.failure(error));
      return;
    }

    yield put(putContactConfirmationAsync.success(response));
    yield put(userContactVerified());

    action.payload.onSuccess?.();
  } catch (err) {
    yield put(putContactConfirmationAsync.failure(err as Error));
  }
}

export function* putContactConfirmationSaga() {
  yield takeLatest(
    getType(putContactConfirmationAsync.request),
    putContactConfirmation,
  );
}

export const putContactConfirmationReducer = createReducer<
  PutContactConfirmationStateType,
  PutContactConfirmationActions
>(getInitialState())
  .handleAction(putContactConfirmationAsync.request, handleActionRequest)
  .handleAction(putContactConfirmationAsync.failure, handleActionFailure)
  .handleAction(putContactConfirmationAsync.success, handleActionSuccess)
  .handleAction(resetPutContactConfirmationState, state =>
    produce(state, () => {
      return getInitialState();
    }),
  );
