import { Box } from "@mui/material";
import { ContantForm } from "Components/Shared/Forms/ContantForm";
import { Dropdown } from "Components/Shared/Inputs/Dropdown";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { TextInput } from "Components/Shared/Inputs/TextInput";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PageTitle } from "Components/Shared/PageTitle";
import { useInputDateFormatter } from "Hooks/Inputs/useInputDateFormatter";
import { useSexCodeList } from "Hooks/Inputs/useSexCodeList";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { useAppSelector } from "Hooks/useAppSelector";
import { ContactInformationFormModel } from "Models/Forms";
import { SexType } from "Models/Inputs";
import { Resources, useResource } from "Translations/Resources";
import {
  useEffect,
  type FunctionComponent,
  useState,
  useCallback,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ClientDto } from "Api/Api";
import {
  CZECHIA_COUNTRY_CODE,
  SLOVAKIA_COUNTRY_CODE,
} from "Constants/Countries";
import { getPermanentAddress } from "Utils/AddressUtils";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { Navigate, useNavigate } from "react-router";
import { useIsLead } from "Hooks/User/useIsLead";
import { LAYOUT_PADDING_X } from "Constants/Layout";

const ComponentResources = Resources.More.PersonalData.Tabs.PersonalData;
const FormResources = Resources.Forms.PersonalData;

const PersonalIdentificationNumberMask = /^.+$/;
const KnownPersonalIdentificationNumberMask = "000000/0000";
const VerifiableNationalities = [CZECHIA_COUNTRY_CODE, SLOVAKIA_COUNTRY_CODE];

const isCitizenshipVerifiable = (data: ClientDto | null | undefined) => {
  if (!data) {
    return false;
  }

  if (VerifiableNationalities.includes(data.citizenship)) {
    return true;
  }

  return (
    data.otherCitizenships?.some(x => VerifiableNationalities.includes(x)) ??
    false
  );
};

export const PersonalData: FunctionComponent = _ => {
  const { t } = useResource();
  const { isLoading, data } = useAppSelector(s => s.client.client);

  const [personalIdentificationMask, setPersonalIdentificationMask] = useState<
    string | RegExp
  >(PersonalIdentificationNumberMask);

  const isLead = useIsLead();
  const navigate = useNavigate();
  const countries = useCountriesList();
  const genders = useSexCodeList();
  const birthdate = useInputDateFormatter(data?.birthDate);

  useEffect(() => {
    setPersonalIdentificationMask(
      isCitizenshipVerifiable(data)
        ? KnownPersonalIdentificationNumberMask
        : PersonalIdentificationNumberMask,
    );
  }, [data]);

  const form = useForm<
    { sex?: SexType | null } & Partial<ContactInformationFormModel>
  >();
  const {
    reset,
    control,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (data) {
      const address = getPermanentAddress(data?.addresses, true);

      reset({
        sex: data?.sex,
        addresses: {
          permanentAddress: {
            city: address?.city,
            country: address?.country,
            street: address?.streetName,
            streetConscriptionNumber: address?.streetConscriptionNumber,
            streetNumber: address?.streetNumber,
            postalCode: address?.zip,
          },
        },
      });
    }
  }, [data, reset]);

  const handleEditClick = useCallback(
    () => navigate(getPath(AppRouting.EditPersonalData)),
    [navigate],
  );

  if (isLead) {
    return <Navigate to={getPath(AppRouting.More)} />;
  }

  return (
    <>
      <Box marginBottom={isLead ? 0 : LAYOUT_PADDING_X}>
        <PageTitle showOnDesktop showOnMobile={false}>
          {t(ComponentResources.Tab)}
        </PageTitle>

        <InputHeaderWrapper header={t(ComponentResources.Tab)} marginTop={0}>
          <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
            <Dropdown
              label={t(FormResources.PrimaryNationality)}
              disabled
              value={data?.citizenship ?? ""}
              options={countries}
              name="citizenship"
            />
            {!!data?.otherCitizenships?.length &&
              data.otherCitizenships.map(value => (
                <Dropdown
                  key={value}
                  label={t(FormResources.Nationalities)}
                  disabled
                  value={value}
                  options={countries}
                  name={`citizenship-${value}`}
                />
              ))}
            <TextInput
              value={data?.personalIdentificationNumber ?? ""}
              label={t(FormResources.PersonalIdentificationNumber)}
              mask={personalIdentificationMask}
              isMaskLazy
              disabled
            />
            <TextInput
              value={birthdate ?? ""}
              label={t(FormResources.BirthDate)}
              type="date"
              disabled
            />

            <Box marginTop={3}>
              <RadioButtons
                name="sex"
                label={t(Resources.Forms.PersonalData.Gender)}
                codeList={genders}
                control={control}
                errors={errors}
                disabled
              />
            </Box>

            <TextInput
              value={data?.name?.firstName ?? ""}
              label={t(FormResources.FirstName)}
              disabled
            />
            <TextInput
              value={data?.name?.lastName ?? ""}
              label={t(FormResources.LastName)}
              disabled
            />
            <TextInput
              value={data?.name?.titleBefore ?? ""}
              label={t(FormResources.TitleBeforeName)}
              disabled
            />
            <TextInput
              value={data?.name?.titleAfter ?? ""}
              label={t(FormResources.TitleAfterName)}
              disabled
            />
          </LoadingWrapper>
        </InputHeaderWrapper>

        <InputHeaderWrapper header={t(ComponentResources.Contact)}>
          <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
            <TextInput
              value={data?.mobile ?? ""}
              label={t(FormResources.Phone)}
              disabled
              mask={Number}
              inputMode="numeric"
              pattern="[0-9]*"
            />
            <TextInput
              value={data?.email ?? ""}
              label={t(FormResources.Email)}
              disabled
            />
          </LoadingWrapper>
        </InputHeaderWrapper>

        <FormProvider {...form}>
          <InputHeaderWrapper header={t(ComponentResources.PermanentAddress)}>
            <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
              <ContantForm formType="permanentAddress" disabled />
            </LoadingWrapper>
          </InputHeaderWrapper>
        </FormProvider>
      </Box>

      {!isLead && (
        <PrimaryButton
          color="primary"
          onClick={handleEditClick}
          trackingTag={t(ComponentResources.Edit)}
          isLoading={isLoading}
          disabled={isLead}
          fullWidth
        >
          {t(ComponentResources.Edit)}
        </PrimaryButton>
      )}
    </>
  );
};
