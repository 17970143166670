import { all } from "typed-redux-saga";
import {
  uploadAttachmentAsync,
  watchUploadAttachmentSaga,
} from "State/Attachments/Upload/UploadAttachmentState";
import { ActionType, createReducer } from "typesafe-actions";
import { CreateOrUpdateAttachmentRequest } from "Api/Api";
import { FetchStateType } from "State/Models";
import {
  deleteAttachmentAsync,
  watchDeleteAttachmentSaga,
} from "State/Attachments/Delete/DeleteAttachmentState";
import { produce } from "immer";
import { omit } from "lodash-es";

export type AttachmentMetaType = Pick<
  CreateOrUpdateAttachmentRequest,
  "attachmentGuid"
>;

export type AttachmentsState = {
  [key: string]: FetchStateType<AttachmentMetaType>;
};

const getInitialState = (): AttachmentsState => ({});

export type UploadAttachmentActionType =
  | ActionType<typeof uploadAttachmentAsync>
  | ActionType<typeof deleteAttachmentAsync>;

export function* watchAttachmentsSaga() {
  yield all([watchUploadAttachmentSaga(), watchDeleteAttachmentSaga()]);
}

export const attachmentsReducer = createReducer<
  AttachmentsState,
  UploadAttachmentActionType
>(getInitialState())
  .handleAction(uploadAttachmentAsync.request, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = {
        isLoading: true,
        data: null,
        error: null,
      };

      return draft;
    }),
  )
  .handleAction(uploadAttachmentAsync.failure, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = {
        isLoading: false,
        error: action.payload,
      };

      return draft;
    }),
  )
  .handleAction(uploadAttachmentAsync.success, (state, action) =>
    produce(state, draft => {
      draft[action.meta.attachmentGuid] = {
        isLoading: false,
        data: {
          ...action.payload,
          ...action.meta,
        },
      };

      return draft;
    }),
  )
  .handleAction(deleteAttachmentAsync.request, (state, action) =>
    produce(state, draft => {
      return {
        ...omit(draft, action.payload.attachmentGuid),
      };
    }),
  );
