import { PathSelectionFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";
import { ObjectSchema, object, boolean } from "yup";

export const useYupFormSchema = (): ObjectSchema<PathSelectionFormModel> => {
  const { t } = useResource();

  return object<PathSelectionFormModel>().shape({
    isBankID: boolean().required(
      t(Resources.Contract.NewContract.PathSelection.Validation.Required),
    ),
  });
};
