import { ComponentTitleWrapper } from "Components/Shared/ComponentTitleWrapper";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { FunctionComponent, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LabelWithCurrency } from "Components/ContractDetail/Overview/components/LabelWithCurrency";
import { Resources, useResource } from "Translations/Resources";
import { useFormatLocale } from "Hooks/DateFns/useFormatLocale";
import {
  UpIcon,
  PurchaseIcon,
  RedeemIcon,
  ExchangeIcon,
} from "Components/Shared/Icons";
import { IconButton } from "Components/ContractDetail/Shared/IconButton";
import styled from "styled-components";
import { BlIconTextButton } from "Components/Shared/Buttons/BlIconTextButton";
import { ProgressChart } from "Components/ContractDetail/Overview/components/ProgressChart";
import { useAppSelector } from "Hooks/useAppSelector";
import { useNavigate } from "react-router";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { TabProps } from "Pages/Contracts/DetailPage";
import { InvestmentComposition } from "Components/ContractDetail/Overview/components/InvestmentComposition/InvestmentComposition";
import { SecurityPaper } from "Components/ContractDetail/Overview/components/SecurityPaper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { PercentageIndicator } from "Components/Shared/PercentageIndicator";
import { useUser } from "Hooks/useUser";
import { useIsRedemptionAllowed } from "Hooks/Contract/Detail/useIsRedemptionAllowed";
import { RedemptionNotAllowedDialog } from "Components/ContractRedemption/RedemptionNotAllowedDialog";
import { performanceToPercent } from "Utils/PerformanceCalculators";
import { OverviewLogo } from "Components/ContractDetail/Overview/components/OverviewLogo";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { notNoU } from "Utils/ObjectUtils";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { getUnsettledTradesAsync } from "State/InvestmentsDetail/UnsettledTrades/GetUnsettledTradesState";
import {
  ContractOverviewDto,
  ContractTypeCode,
  ContractUnsettledTradeItemDto,
  ContractUnsettledTradeType,
} from "Api/Api";
import { ConfirmValues } from "Components/Shared/Dialogs/ConfirmDialog";
import { useConfirmationDialog } from "Hooks/Dialogs/useConfirmationDialog";
import { TransferPromoCard } from "Components/ContractTransferDip/TransferPromoCard";
import { DipDescriptionDialog } from "Components/ContractModeling/FundSelector/Components/DipDescriptionDialog";
import { useSettings } from "Hooks/App/Settings/useSettings";
import { clearAppSettings } from "State/App/Settings/GetSettingsState";
import { PaymentInformationCard } from "./components/PaymentInformationCard";
import { PercentageChart } from "Components/Dashboard/DetailDashboard/DashboardCard/PercentageChart";

const PageResources = Resources.Contract.Detail.Overview;

const StyledUpIcon = styled(UpIcon)`
  transform: rotate(90deg);
`;

const StyledPurchaseIcon = styled(PurchaseIcon)`
  width: 17.5px;
  height: 17.5px;
`;

type Props = TabProps;

export const Overview: FunctionComponent<Props> = ({
  contractID,
  isReadonly,
}) => {
  const { t } = useResource();
  const format = useFormatLocale();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { settings, isLoading: isLoadingSettings } = useSettings();
  const user = useUser();

  const { isLoading: isLoadingOverview, data: overview } = useAppSelector(
    state => state.investmentsDetail.overview,
  );

  const { isLoading: isLoadingUnsettledTrades, data: unsettledTrades } =
    useAppSelector(state => state.investmentsDetail.unsettledTrades);

  const isComposed = (overview?.portfolioItems?.length ?? 0) > 1;

  useEffect(() => {
    dispatch(clearAppSettings());
    dispatch(getOverviewAsync.request(contractID));
    dispatch(getUnsettledTradesAsync.request(contractID));
  }, [dispatch, contractID]);

  const isSelfInvestmentType = useIsSelfNegotiatedContract(overview?.type);
  const isin = overview?.portfolioItems?.[0]?.isin ?? "";
  const maxAmountToRedeem = overview?.portfolioItems?.[0]?.amount ?? 0;
  const isRedemptionAllowed = useIsRedemptionAllowed(isin);
  const [
    isRedemptionNotAllowedDialogOpened,
    setIsRedemptionNotAllowedDialogOpened,
  ] = useState(false);

  const isLoading =
    (isLoadingOverview && !overview) ||
    (isLoadingUnsettledTrades && !unsettledTrades) ||
    (isLoadingSettings && !settings);

  const getAwaitingInvestmentValue = (
    overview: ContractOverviewDto | null | undefined,
    unsettledTrades: ContractUnsettledTradeItemDto[] | null | undefined,
  ): number => {
    const portfolioItemsLength = overview?.portfolioItems?.length ?? 0;

    if (!unsettledTrades?.length) {
      return 0;
    }

    if (portfolioItemsLength > 1) {
      return unsettledTrades
        .filter(
          item =>
            overview?.portfolioItems.every(
              portfolioItem => portfolioItem.productID !== item.productID,
            ),
        )
        .filter(item => item.type === ContractUnsettledTradeType.BUY)
        .reduce((accumulator, item) => accumulator + (item.amount ?? 0), 0);
    }

    return unsettledTrades
      .filter(item => item.type === ContractUnsettledTradeType.BUY)
      .reduce((accumulator, item) => accumulator + (item.amount ?? 0), 0);
  };

  const awaitingInvestmentValue = getAwaitingInvestmentValue(
    overview,
    unsettledTrades,
  );

  const displayAwaitingInvestment = awaitingInvestmentValue > 0;

  const displayInvestUninvestedDepositsButton =
    overview?.type === ContractTypeCode.RS_INVCZKSELF ||
    overview?.type === ContractTypeCode.RS_INVEURSELF;

  const redemptionDipDialog = useConfirmationDialog({
    confirmation: t(
      Resources.Contract.Detail.Redemption.DipDialog.Confirmation,
    ),
    cancelText: t(Resources.Common.Close),
    confirmText: t(Resources.Common.Continue),
  });

  const [
    isDipMoreInformationDialogVisible,
    setIsDipMoreInformationDialogVisible,
  ] = useState(false);

  return (
    <>
      {overview?.isFirstPayment && !isReadonly && (
        <Box marginBottom={6}>
          <PaymentInformationCard />
        </Box>
      )}

      <ComponentTitleWrapper
        title={t(PageResources.ActualValue)}
        marginBottom={displayAwaitingInvestment ? 0 : 4}
        marginTop={0}
        tooltip={t(PageResources.InvestmentValueTooltip)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={isComposed ? 3 : 0}
        >
          <Box overflow="auto" height="max-content" paddingBottom={1}>
            <LoadingWrapper isLoading={isLoading} variant="text" wrapChildren>
              <CurrencyRenderer
                value={overview?.actualAmount}
                currency={overview?.currency}
                variant="h2"
                fontWeight={700}
                decimalPlaces={2}
              />
              <PercentageIndicator
                value={performanceToPercent(
                  overview?.performanceFromAccountCreation,
                )}
              />
            </LoadingWrapper>
          </Box>

          {isComposed && !isReadonly && (
            <Box position="relative" top={-24}>
              <IconButton
                icon={<StyledPurchaseIcon />}
                label={t(PageResources.DepositMoney)}
                onClick={() =>
                  navigate(getPath(AppRouting.Purchase, contractID))
                }
                size={35}
                highlighted
                disabled={!!user?.isUnderage}
              />
            </Box>
          )}

          {!isComposed && (
            <LoadingWrapper isLoading={isLoading} wrapChildren>
              {overview?.portfolioItems?.[0]?.isin && (
                <OverviewLogo
                  isin={overview?.portfolioItems?.[0]?.isin}
                  nameWidth="auto"
                  nameTextAlign="right"
                  nameWhiteSpace="nowrap"
                  imageTextAlign="right"
                  imageMarginLeft={3}
                />
              )}
            </LoadingWrapper>
          )}
        </Box>
      </ComponentTitleWrapper>

      {displayAwaitingInvestment && (
        <Box
          overflow="auto"
          height="max-content"
          marginTop={2}
          marginBottom={4}
        >
          <LoadingWrapper isLoading={isLoading} variant="text" wrapChildren>
            <Typography
              component="div"
              variant="subtitle1"
              display="inline-block"
            >
              {t(PageResources.AwaitingInvestmentLabel)}
            </Typography>
            &nbsp;&nbsp;
            <CurrencyRenderer
              value={awaitingInvestmentValue}
              currency={overview?.currency}
              decimalPlaces={2}
              variant="body2"
              fontWeight={700}
              display="inline-block"
            />
            <BlInfoPopper color="primary">
              {t(PageResources.AwaitingInvestedTooltipText)}
            </BlInfoPopper>
          </LoadingWrapper>
        </Box>
      )}

      <LoadingWrapper isLoading={isLoading} wrapChildren fullWidth>
        {isComposed && (
          <InvestmentComposition
            contractId={contractID}
            isReadonly={isReadonly}
          />
        )}
        {!isComposed && !isReadonly && (
          <StyledFlex $justifyContent="space-around">
            <RedemptionNotAllowedDialog
              isDip={overview?.isDip ?? false}
              isOpened={isRedemptionNotAllowedDialogOpened}
              onClose={() => setIsRedemptionNotAllowedDialogOpened(false)}
            />
            <IconButton
              icon={<PurchaseIcon />}
              label={t(PageResources.PurchaseButton)}
              textWidth={overview?.hasExchangeableAssets ? "10ch" : undefined}
              $textLineClamp={overview?.hasExchangeableAssets ? 2 : undefined}
              onClick={() => navigate(getPath(AppRouting.Purchase, contractID))}
              disabled={!!user?.isUnderage}
            />
            <IconButton
              icon={<RedeemIcon />}
              label={t(PageResources.RedemptionButton)}
              textWidth={overview?.hasExchangeableAssets ? "10ch" : undefined}
              $textLineClamp={overview?.hasExchangeableAssets ? 2 : undefined}
              disabled={
                user?.isUnderage ||
                !isSelfInvestmentType ||
                maxAmountToRedeem === 0
              }
              onClick={async () => {
                if (isRedemptionAllowed) {
                  if (overview?.isDip) {
                    const result = await redemptionDipDialog();
                    if (result === ConfirmValues.Cancel) {
                      return;
                    }
                  }

                  navigate(getPath(AppRouting.Redemption, contractID, isin));
                } else {
                  setIsRedemptionNotAllowedDialogOpened(true);
                }
              }}
            />
            {overview?.hasExchangeableAssets && (
              <IconButton
                icon={<ExchangeIcon />}
                label={t(PageResources.ExchangeAssetsButton)}
                textWidth={overview?.hasExchangeableAssets ? "10ch" : undefined}
                $textLineClamp={overview?.hasExchangeableAssets ? 2 : undefined}
                onClick={() =>
                  navigate(
                    getPath(
                      AppRouting.ContractExchangeAssets,
                      contractID,
                      isin,
                    ),
                  )
                }
              />
            )}
            {/* // TODO: Removed unused IconButton until implemented
              <IconButton
              icon={<StyledLExchangeIcon />}
              label={t(PageResources.ToMoveButton)}
              onClick={() => {}}
            />*/}
          </StyledFlex>
        )}
      </LoadingWrapper>

      <LoadingWrapper
        isLoading={isLoading}
        skeleton={
          <Box marginTop={7}>
            <PrimarySkeleton variant="rectangular" height={150} />
          </Box>
        }
      >
        {!overview?.isDip &&
          settings?.isDIPEnabled &&
          isSelfInvestmentType &&
          !isReadonly && (
            <>
              <DipDescriptionDialog
                onClose={() => setIsDipMoreInformationDialogVisible(false)}
                isOpen={isDipMoreInformationDialogVisible}
              />

              <Box marginTop={7}>
                <TransferPromoCard
                  onTransferDip={() =>
                    navigate(
                      getPath(AppRouting.ContractTransferDip, contractID),
                    )
                  }
                  onMoreInformation={() =>
                    setIsDipMoreInformationDialogVisible(true)
                  }
                />
              </Box>
            </>
          )}
      </LoadingWrapper>

      {!isLoading && notNoU(overview) && notNoU(overview.actualPercent) && (
        <ComponentTitleWrapper
          title={t(PageResources.ActualPercent)}
          marginTop={10}
        >
          <CurrencyRenderer
            value={overview.purposeAmount}
            currency={overview.currency}
            variant="body2"
            fontWeight={700}
            decimalPlaces={2}
          />
          <PercentageChart
            percentage={overview.actualPercent}
            purposeCategory={overview.purposeCategory}
            marginY={2}
          />
        </ComponentTitleWrapper>
      )}

      {(!!overview?.actualAmount || isLoading) && (
        <ComponentTitleWrapper
          title={t(PageResources.Progression)}
          marginTop={
            notNoU(overview) && notNoU(overview?.actualPercent) ? undefined : 10
          }
        >
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={<PrimarySkeleton variant="rectangular" height={250} />}
          >
            <ProgressChart
              data={overview?.graphData}
              currency={overview?.currency ?? ""}
              isAlignedLeft
            />
          </LoadingWrapper>
        </ComponentTitleWrapper>
      )}

      <ComponentTitleWrapper title={t(PageResources.Detail)}>
        <Stack gap={4}>
          <LoadingWrapper
            isLoading={isLoading}
            skeleton={
              <>
                <PrimarySkeleton />
                <PrimarySkeleton />
                <PrimarySkeleton />
                <PrimarySkeleton />
                <PrimarySkeleton />
                <PrimarySkeleton variant="rectangular" height={60} />
              </>
            }
          >
            {notNoU(overview) && (
              <>
                <LabelWithCurrency
                  label={t(PageResources.InvestedDeposits)}
                  value={overview.totalInvestments || undefined}
                  currency={overview.currency}
                />
                <LabelWithCurrency
                  label={t(PageResources.TotalWithdraws)}
                  value={overview.totalWithdraws || undefined}
                  currency={overview.currency}
                />
                <LabelWithCurrency
                  label={t(PageResources.RealizedProfit)}
                  value={overview.realizedProfit || undefined}
                  currency={overview.currency}
                  displaySign
                />
                <LabelWithCurrency
                  label={t(PageResources.UnrealizedProfit)}
                  value={overview.unrealizedProfit || undefined}
                  currency={overview.currency}
                  tooltip={t(PageResources.UnrealizedProfitTooltip)}
                />
                <LabelWithCurrency
                  label={t(PageResources.UninvestedDeposits)}
                  value={overview.uninvestedDeposits || undefined}
                  currency={overview.currency}
                >
                  {displayInvestUninvestedDepositsButton && !isReadonly && (
                    <BlIconTextButton
                      icon={<StyledUpIcon />}
                      disabled={
                        !overview.uninvestedDeposits || !!user?.isUnderage
                      }
                      onClick={() =>
                        navigate(
                          getPath(
                            AppRouting.InvestUninvestedDeposits,
                            contractID,
                          ),
                        )
                      }
                    >
                      {t(PageResources.InvestDeposit)}
                    </BlIconTextButton>
                  )}
                </LabelWithCurrency>
                <LabelWithCurrency
                  label={t(PageResources.TotalDeposits)}
                  value={overview.totalDeposits || undefined}
                  currency={overview.currency}
                />
                <LabelWithCurrency
                  label={t(PageResources.PaidEntryFees)}
                  value={overview.paidCommissions || undefined}
                  currency={overview.currency}
                />
                <div>
                  <Typography variant="subtitle1">
                    {t(PageResources.LastDepositDate)}
                  </Typography>
                  <Typography variant="body2" fontWeight={700}>
                    {!!overview.lastMovementDate &&
                      format(new Date(overview.lastMovementDate))}
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    {t(PageResources.SecuritiesPieces)}
                  </Typography>
                  <Stack gap={2}>
                    {overview.portfolioItems?.map(
                      ({ isin, name, quantity }) => (
                        <SecurityPaper
                          key={isin}
                          name={name}
                          quantity={quantity}
                        />
                      ),
                    )}
                  </Stack>
                </div>
              </>
            )}
          </LoadingWrapper>
        </Stack>
      </ComponentTitleWrapper>
    </>
  );
};
