import { ClientVerificationForm } from "Components/ContractBankConnections/Shared/ClientVerificationForm";
import { AppRouting } from "Utils/UrlUtils";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { setEditPersonalDataActualStep } from "State/Client/EditPersonalData/EditPersonalDataState";
import { FunctionComponent } from "react";
import { EditPersonalDataSteps } from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { getPath } from "Utils/UrlUtils";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.ClientVerification;

export const ClientVerificationStep: FunctionComponent = () => {
  const { t } = useResource();
  const {
    isLoading,
    lastRequest: {
      isBiometry,
      isCodeSendToClientEmail,
      contactInfo: { phone, email },
    },
    signatureHash,
    clientVerificationHash,
    error,
  } = useAppSelector(s => s.client.editPersonalData);

  const title = usePageTitle();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = () => {
    dispatch(setEditPersonalDataActualStep(EditPersonalDataSteps.Signature));

    if (isBiometry && signatureHash) {
      dispatch(
        setBiometricsSignatureType(BiometricsSignatureType.EditPersonalData),
      );

      dispatch(
        initializeBiometricSignatureAsync.request({
          signatureHash: signatureHash,
        }),
      );

      return dispatch(
        setEditPersonalDataActualStep(EditPersonalDataSteps.Signature),
      );
    }

    navigate(getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData));
  };

  return (
    <ClientVerificationForm
      isLoading={isLoading}
      isCodeSendToClientEmail={isCodeSendToClientEmail}
      signatureHash={signatureHash}
      clientVerificationHash={clientVerificationHash}
      phone={phone}
      email={email}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        title: title,
        codeDetermination: t(ComponentResources.CodeDetermination),
        sms: {
          codeType: t(ComponentResources.SmsCodeSentLabel),
          codeSent: t(ComponentResources.SmsCodeSentNotification),
          codeNotReceived: t(ComponentResources.SmsCodeNotReceived),
          resendCode: t(ComponentResources.ResendSms),
          resendCodeTimeout: t(
            Resources.ApiValidation.Validation.Specific
              .SmsErrorSecondsBetweenSending,
          ),
        },
        email: {
          codeType: t(ComponentResources.EmailCodeSentLabel),
          codeSent: t(ComponentResources.EmailCodeSentNotification),
          codeNotReceived: t(ComponentResources.EmailCodeNotReceived),
          resendCode: t(ComponentResources.ResendEmail),
          resendCodeTimeout: t(ComponentResources.ResendEmailTimeout),
        },
        verificationError: t(ComponentResources.VerificationError),
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
        clientVerificationHashNotFoundError: t(
          ComponentResources.ClientVerificationHashNotFoundError,
        ),
        submit: t(ComponentResources.SubmitButton),
      }}
      error={error}
      onSuccess={handleSuccess}
    />
  );
};
