import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";

export interface HandleFormSubmit {
  submit(): void;
}

type FormModel = {
  personalIdentificationNumber: string;
};

type Props = {
  label: string;
  personalIdentificationNumber?: string | null;
  translations: {
    mismatch: string;
  };
  onSuccess?: () => void;
};

const PersonalIdentificationNumberValidatorComponent: ForwardRefRenderFunction<
  HandleFormSubmit,
  Props
> = (
  {
    label,
    personalIdentificationNumber,
    translations: { mismatch },
    onSuccess,
  },
  ref,
) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<FormModel>();

  const onSubmit = useCallback(
    (dataForm: FormModel) => {
      if (
        personalIdentificationNumber &&
        personalIdentificationNumber === dataForm.personalIdentificationNumber
      ) {
        onSuccess?.();
        return;
      }

      setError("personalIdentificationNumber", {
        message: mismatch,
      });
    },
    [mismatch, personalIdentificationNumber, onSuccess, setError],
  );

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSubmit)(),
  }));

  return (
    <BlInputFormField
      control={control}
      errors={errors}
      name="personalIdentificationNumber"
      isMaskLazy={false}
      label={label}
      mask={/^.+$/}
    />
  );
};

export const PersonalIdentificationNumberValidator = forwardRef(
  PersonalIdentificationNumberValidatorComponent,
);
