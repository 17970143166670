import { StyledFormControl } from "Components/Shared/FormStyles";
import { FileInput } from "Components/Shared/Inputs/FileInput/FileInput";
import { ALLOWED_FILE_MIME_TYPES, MAX_FILE_SIZE_MB } from "Constants/Inputs";
import { useDocumentFile } from "Hooks/Contract/Documents/useDocumentFile";
import { useAppDispatch } from "Hooks/useAppDispatch";
import {
  DocumentScanSide,
  Documents,
  DocumentsFormModel,
} from "State/Contracts/Create/Models";
import { deleteDocument } from "State/Contracts/Shared/Draft";
import get from "lodash-es/get";
import { FunctionComponent } from "react";
import { FieldPath, FieldValues, useFormContext } from "react-hook-form";

type Props<FormType extends FieldValues = FieldValues> = {
  name: FieldPath<FormType>;
  label?: string;
  documentType: Documents;
  side: DocumentScanSide;
  setFormError: (error: string) => void;
};

export const DocumentInput: FunctionComponent<Props> = ({
  name,
  label,
  documentType,
  side,
  setFormError,
}) => {
  const dispatch = useAppDispatch();
  const { file, isSaving, saveFile } = useDocumentFile(documentType, side);
  const {
    formState: { errors },
  } = useFormContext<DocumentsFormModel>();

  const handleChange = (file: File | null) => {
    if (file) {
      saveFile(file);
    } else {
      dispatch(deleteDocument({ document: documentType, side }));
    }
  };

  return (
    <StyledFormControl>
      <FileInput
        maxSizeMB={MAX_FILE_SIZE_MB}
        fieldError={get(errors, name as string)}
        onChange={handleChange}
        value={file}
        label={label}
        isLoading={isSaving}
        accept={ALLOWED_FILE_MIME_TYPES}
        setFormError={setFormError}
      />
    </StyledFormControl>
  );
};
