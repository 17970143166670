import {
  GetFeeRateStateType,
  getFeeRateReducer,
  getFeeRateSaga,
} from "State/Products/FeeRate/GetFeeRateState";
import {
  GetProductDetailStateType,
  getProductDetailReducer,
  getProductDetailSaga,
} from "State/Products/ProductDetail/GetProductsState";
import {
  GetProductTilesStateType,
  getProductTilesReducer,
  getProductTilesSaga,
} from "State/Products/ProductTiles/GetProductTilesState";
import {
  GetProductsStateType,
  getProductsReducer,
  getProductsSaga,
} from "State/Products/Products/GetProductsState";
import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ProductsState = {
  productDetail: GetProductDetailStateType;
  productTiles: GetProductTilesStateType;
  products: GetProductsStateType;
  feeRate: GetFeeRateStateType;
};

export function* watchProductsSaga() {
  yield all([
    getProductDetailSaga(),
    getProductsSaga(),
    getProductTilesSaga(),
    getFeeRateSaga(),
  ]);
}

export const productsReducer = combineReducers<ProductsState>({
  productDetail: getProductDetailReducer,
  productTiles: getProductTilesReducer,
  products: getProductsReducer,
  feeRate: getFeeRateReducer,
});
