import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import {
  BankIDCallbackLoadingSkeleton,
  BankIDCallbackStep,
} from "Components/More/PersonalData/EditPersonalData/BankID/BankIDCallbackStep";
import { BankIDRedirectStep } from "Components/More/PersonalData/EditPersonalData/BankID/BankIDRedirectStep";
import { ClientVerificationStep } from "Components/More/PersonalData/EditPersonalData/ClientVerification/ClientVerificationStep";
import { ModellingStep } from "Components/More/PersonalData/EditPersonalData/Modelling/ModellingStep";
import {
  PathSelectionLoadingSkeleton,
  PathSelectionStep,
} from "Components/More/PersonalData/EditPersonalData/PathSelection/PathSelectionStep";
import { SignatureStep } from "Components/More/PersonalData/EditPersonalData/Signature/SignatureStep";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { useIsAnyContractBankIDVerified } from "Hooks/useIsAnyContractBankIDVerified";
import { useIsLead } from "Hooks/User/useIsLead";
import { FunctionComponent, useCallback, useEffect } from "react";
import { Navigate, useNavigate } from "react-router";
import { getClientAsync } from "State/Client/Client/GetClient";
import {
  EditPersonalDataSteps,
  resetEditPersonalDataState,
  setEditPersonalDataActualStep,
} from "State/Client/EditPersonalData/EditPersonalDataState";
import { contactInfoAsync } from "State/Profile/ContactInfo/ContactInfoReducer";
import { AppRouting, getPath } from "Utils/UrlUtils";

export const EditPersonalDataPage: FunctionComponent = _ => {
  const { isDesktop } = useDesktop();
  const { actualStep } = useAppSelector(s => s.client.editPersonalData);
  const { isLoading: isLoadingClient, error: clientError } = useAppSelector(
    s => s.client.client,
  );
  const { isLoading: isLoadingContactInfo, error: contactInfoError } =
    useAppSelector(s => s.profile.contactInfo);

  const {
    isLoading: isLoadingVerificationType,
    isBankIDVerified,
    error: verificationTypeError,
  } = useIsAnyContractBankIDVerified(true);

  const isLead = useIsLead();
  const pageTitle = usePageTitle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBackClick = useCallback(() => {
    switch (actualStep) {
      case EditPersonalDataSteps.PathSelection:
        navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        );
        return;
      case EditPersonalDataSteps.BankIDRedirect:
      case EditPersonalDataSteps.BankIDCallback:
      case EditPersonalDataSteps.Modelling:
        if (isBankIDVerified) {
          dispatch(
            setEditPersonalDataActualStep(EditPersonalDataSteps.PathSelection),
          );
          return;
        }

        navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        );
        return;
      case EditPersonalDataSteps.Signature:
      case EditPersonalDataSteps.ClientVerification:
        navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        );
        return;
    }
  }, [isBankIDVerified, actualStep, navigate, dispatch]);

  useEffect(() => {
    dispatch(contactInfoAsync.request());
    dispatch(getClientAsync.request());
    return () => {
      dispatch(resetEditPersonalDataState());
    };
  }, [dispatch]);

  const isLoading =
    isLoadingClient || isLoadingContactInfo || isLoadingVerificationType;

  if (isLead) {
    return (
      <Navigate
        to={getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData)}
      />
    );
  }

  return (
    <AuthenticatedLayout
      title={pageTitle}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
    >
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={
          actualStep === EditPersonalDataSteps.BankIDCallback
            ? BankIDCallbackLoadingSkeleton
            : PathSelectionLoadingSkeleton
        }
        error={clientError || contactInfoError || verificationTypeError}
      >
        {actualStep === EditPersonalDataSteps.PathSelection && (
          <PathSelectionStep />
        )}
        {actualStep === EditPersonalDataSteps.BankIDRedirect && (
          <BankIDRedirectStep />
        )}
        {actualStep === EditPersonalDataSteps.BankIDCallback && (
          <BankIDCallbackStep />
        )}
        {actualStep === EditPersonalDataSteps.Modelling && <ModellingStep />}
        {actualStep === EditPersonalDataSteps.Signature && <SignatureStep />}
        {actualStep === EditPersonalDataSteps.ClientVerification && (
          <ClientVerificationStep />
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};
