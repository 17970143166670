import {
  type BoxProps,
  Box,
  Typography,
  TypographyOwnProps,
} from "@mui/material";

import { Products } from "Models/Products";
import { FunctionComponent, useMemo } from "react";
import styled from "styled-components";

import Investika from "Assets/Images/Funds/investika.svg";
import Monetika from "Assets/Images/Funds/monetika.svg";
import Dynamika from "Assets/Images/Funds/dynamika.svg";
import Efektika from "Assets/Images/Funds/efektika.svg";
import Euromonetika from "Assets/Images/Funds/euromonetika.svg";
import { ProductsIsinDetail } from "Constants/Products";

const IsinLogos: { [key: string]: Products } = {
  CZ0008474830: "investika",
  CZ0008475902: "investika",
  CZ0008476314: "investika",
  CZ0008477379: "monetika",
  CZ0008477361: "monetika",
  CZ0008477650: "efektika",
  CZ0008477676: "efektika",
  CZ0008477668: "efektika",
  CZ0008475670: "dynamika",
  CZ0008476272: "dynamika",
  CZ0008476801: "benefika",
  CZ0008478005: "euromonetika",
};

type Props = {
  isin?: string;
  productCode?: string;
  imageHeight?: number;
  imageWidth?: number;
  imageTextAlign?: BoxProps["textAlign"];
  imageMarginLeft?: BoxProps["marginLeft"];
  name?: string;
  nameWidth?: BoxProps["width"];
  nameMaxWidth?: TypographyOwnProps["maxWidth"];
  nameTextAlign?: BoxProps["textAlign"];
  nameWhiteSpace?: BoxProps["whiteSpace"];
  nameNoWrap?: TypographyOwnProps["noWrap"];
  nameTextOverflow?: TypographyOwnProps["textOverflow"];
} & Omit<BoxProps, "width"> &
  (
    | {
        isin: string;
        productCode?: never;
      }
    | {
        isin?: never;
        productCode: string;
      }
  );

const Icons: {
  [key in Products]: string;
} = {
  dynamika: Dynamika,
  investika: Investika,
  monetika: Monetika,
  efektika: Efektika,
  benefika: "", // benefika is without logo,
  euromonetika: Euromonetika,
};

const StyledTypography = styled(Typography)`
  font-style: normal;
`;

export const ProductLogo: FunctionComponent<Props> = ({
  isin,
  productCode,
  name,
  nameWidth = "fit-content",
  nameMaxWidth,
  nameTextAlign,
  nameWhiteSpace,
  nameNoWrap,
  nameTextOverflow,
  imageHeight,
  imageWidth,
  imageTextAlign,
  imageMarginLeft,
  ...boxProps
}) => {
  const Icon = useMemo(() => {
    if (isin) {
      return Icons[IsinLogos[isin]];
    }

    const [productIsin] =
      Object.entries(ProductsIsinDetail).find(
        ([, product]) => product.code === productCode,
      ) ?? [];

    if (productIsin) {
      return Icons[IsinLogos[productIsin]];
    }

    return null;
  }, [isin, productCode]);

  return (
    <Box width="min-content" {...boxProps}>
      {Icon && (
        <Box textAlign={imageTextAlign} marginLeft={imageMarginLeft}>
          <img src={Icon} alt={name} height={imageHeight} width={imageWidth} />
        </Box>
      )}
      <StyledTypography
        width={nameWidth}
        maxWidth={nameMaxWidth}
        color="secondary"
        textAlign={nameTextAlign}
        whiteSpace={nameWhiteSpace}
        noWrap={nameNoWrap}
        textOverflow={nameTextOverflow}
      >
        {name}
      </StyledTypography>
    </Box>
  );
};
