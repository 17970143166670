import { BoxProps } from "@mui/material";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { type FunctionComponent } from "react";

type Props = {
  isin: string;
  nameWidth?: BoxProps["width"];
  nameTextAlign?: BoxProps["textAlign"];
  nameWhiteSpace?: BoxProps["whiteSpace"];
  imageTextAlign?: BoxProps["textAlign"];
  imageMarginLeft?: BoxProps["marginLeft"];
};

export const OverviewLogo: FunctionComponent<Props> = props => {
  const {
    isin,
    nameWidth,
    nameTextAlign,
    nameWhiteSpace,
    imageTextAlign,
    imageMarginLeft,
  } = props;

  const productClass = useFormattedProductClass(isin);
  return (
    <>
      <ProductLogo
        isin={isin}
        name={productClass}
        nameWidth={nameWidth}
        nameTextAlign={nameTextAlign}
        nameWhiteSpace={nameWhiteSpace}
        imageTextAlign={imageTextAlign}
        imageHeight={30}
        imageWidth={90}
        imageMarginLeft={imageMarginLeft}
      />
    </>
  );
};
