import { all } from "typed-redux-saga";
import {
  TwoFactorVerificationState,
  twoFactorVerificationReducer,
} from "./TwoFactorVerification/TwoFactorVerificationReducer";
import { combineReducers } from "redux";
import {
  contactInfoReducer,
  ContactInfoState,
  watchContactInfoSaga,
} from "State/Profile/ContactInfo/ContactInfoReducer";

export type ProfileState = {
  twoFactorVerification: TwoFactorVerificationState;
  contactInfo: ContactInfoState;
};

export function* watchProfileSaga() {
  yield all([watchContactInfoSaga()]);
}

export const profileReducer = combineReducers<ProfileState>({
  twoFactorVerification: twoFactorVerificationReducer,
  contactInfo: contactInfoReducer,
});
