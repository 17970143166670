export const formatPhoneNumber = (phone?: string | null) => {
  if (!phone) {
    return "";
  }

  // Add spaces between every 3 characters but ignore if starts with + sign
  return phone.replace(/(\d{3})(?=\d)/g, "$1 ");
};

export function isMatchingPhone(
  phone: string | null | undefined,
  newPhone: string | null | undefined,
) {
  return (
    (phone ?? "").replace(/\D/g, "") === (newPhone ?? "").replace(/\D/g, "")
  );
}
