import {
  putSignatureBiometricsVerification,
  ValidateVerificationCodeCommand,
} from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { createAsyncAction, getType } from "typesafe-actions";

export const verifyClientVerificationCodeAsync = createAsyncAction(
  "@biometrics/VERIFY_CLIENT_VERIFICATION_CODE_REQUEST",
  "@biometrics/VERIFY_CLIENT_VERIFICATION_CODE_SUCCESS",
  "@biometrics/VERIFY_CLIENT_VERIFICATION_CODE_FAILURE",
)<
  ValidateVerificationCodeCommand & {
    onSuccess: (clientVerificationHash: string) => void;
  },
  void,
  Error
>();

function* verifyClientVerificationCode(
  action: ReturnType<typeof verifyClientVerificationCodeAsync.request>,
): Generator {
  try {
    const { error } = yield* safeApiCall(
      putSignatureBiometricsVerification,
      action.payload,
    );

    if (error) {
      yield put(verifyClientVerificationCodeAsync.failure(error));
      return;
    }

    yield put(verifyClientVerificationCodeAsync.success());
    action.payload.onSuccess(action.payload.clientVerificationHash);
  } catch (err) {
    yield put(verifyClientVerificationCodeAsync.failure(err as Error));
  }
}

export function* watchVerifyClientVerificationCodeSaga() {
  yield takeLatest(
    getType(verifyClientVerificationCodeAsync.request),
    verifyClientVerificationCode,
  );
}
