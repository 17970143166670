import { SignatureForm } from "Components/ContractBankConnections/Shared/SignatureForm";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { PersonalDataTabs } from "Hooks/More/PersonalData/useMenuTabs";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { BiometricsSignatureType } from "State/Biometrics/BiometricsActions";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Signature;

export const SignatureStep: FunctionComponent = () => {
  const { t } = useResource();
  const {
    isLoading,
    lastRequest: {
      contactInfo: { phone },
    },
    signatureHash,
    error,
  } = useAppSelector(s => s.client.editPersonalData);

  const title = usePageTitle();
  const navigate = useNavigate();

  return (
    <SignatureForm
      isLoading={isLoading}
      contractID={undefined}
      signatureHash={signatureHash}
      sms={{
        phone,
      }}
      biometrics={{
        signatureType: BiometricsSignatureType.EditPersonalData,
      }}
      translations={{
        isLoading: t(ComponentResources.WorkingText),
        sms: {
          title: title,
          codeDetermination: t(ComponentResources.CodeDetermination),
          codeSent: t(Resources.Signature.SmsSent),
          verificationError: t(ComponentResources.VerificationError),
          button: t(ComponentResources.SubmitButton),
        },
        biometrics: {
          title: t(ComponentResources.Biometrics),
          signatureError: t(ComponentResources.BiometricsError),
          signatureInProcessError: t(
            ComponentResources.BiometricsInProcessError,
          ),
        },
        signatureHashNotFoundError: t(
          ComponentResources.SignatureHashNotFoundError,
        ),
      }}
      error={error}
      onSuccess={() => {
        navigate(
          getPath(AppRouting.PersonalData, PersonalDataTabs.PersonalData),
        );
      }}
    />
  );
};
