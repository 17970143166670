import {
  GetClientStateType,
  getClientReducer,
  getClientSaga,
} from "State/Client/Client/GetClient";
import {
  PostContactConfirmationStateType,
  postContactConfirmationReducer,
  postContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PostContactConfirmation";
import {
  PutContactConfirmationStateType,
  putContactConfirmationReducer,
  putContactConfirmationSaga,
} from "State/Client/ContactConfirmation/PutContactConfirmation";
import { clientDocumentsReducer } from "State/Client/Documents/ClientDocumentsState";
import {
  ClientDocumentsState,
  watchClientDocumentsSaga,
} from "State/Client/Documents/ClientDocumentsState";
import {
  editAmlReducer,
  watchEditAmlSaga,
} from "State/Client/EditAml/EditAmlState";
import { EditAmlState } from "State/Client/EditAml/EditAmlState";
import { editPersonalDataReducer } from "State/Client/EditPersonalData/EditPersonalDataState";
import {
  EditPersonalDataState,
  watchEditPersonalDataSaga,
} from "State/Client/EditPersonalData/EditPersonalDataState";
import {
  GetDealersStateType,
  getDealersReducer,
  getDealersSaga,
} from "State/Client/GetDealers/GetDealersState";
import {
  GetExistenceStateType,
  getExistenceReducer,
  getExistenceSaga,
} from "State/Client/GetExistence/GetExistenceState";
import {
  clientVerificationTypeReducer,
  ClientVerificationTypeState,
  watchClientVerificationTypeSaga,
} from "State/Client/VerificationType/ClientVerificationTypeState";

import { combineReducers } from "redux";
import { all } from "typed-redux-saga";

export type ClientState = {
  client: GetClientStateType;
  dealers: GetDealersStateType;
  existence: GetExistenceStateType;
  postContactConfirmation: PostContactConfirmationStateType;
  putContactConfirmation: PutContactConfirmationStateType;
  verificationType: ClientVerificationTypeState;
  documents: ClientDocumentsState;
  editAml: EditAmlState;
  editPersonalData: EditPersonalDataState;
};

export function* watchClientSaga() {
  yield all([
    getClientSaga(),
    getDealersSaga(),
    getExistenceSaga(),
    postContactConfirmationSaga(),
    putContactConfirmationSaga(),
    watchClientVerificationTypeSaga(),
    watchClientDocumentsSaga(),
    watchEditAmlSaga(),
    watchEditPersonalDataSaga(),
  ]);
}

export const clientReducer = combineReducers<ClientState>({
  client: getClientReducer,
  dealers: getDealersReducer,
  existence: getExistenceReducer,
  postContactConfirmation: postContactConfirmationReducer,
  putContactConfirmation: putContactConfirmationReducer,
  verificationType: clientVerificationTypeReducer,
  documents: clientDocumentsReducer,
  editAml: editAmlReducer,
  editPersonalData: editPersonalDataReducer,
});
