import { PersonalDocumentType } from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { InputHeaderWrapper } from "Components/Shared/Inputs/InputHeaderWrapper";
import { useCountriesList } from "Hooks/codeLists/useCountriesList";
import { FunctionComponent, FocusEvent, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { PrimaryDocumentFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";
import { isValid as isDateValid } from "date-fns";
import { parseDateToDocumentsDate } from "Utils/Contracts/NewContract/DateUtils";
import { getAllowedDocumentTypes } from "Hooks/More/PersonalData/EditPersonalData/Modelling/useYupFormSchema";
import { useDocumentsList } from "Hooks/Inputs/useDocumentsList";
import { CZECHIA_COUNTRY_CODE } from "Constants/Countries";
import { DocumentScanInput } from "Components/More/PersonalData/EditPersonalData/Modelling/components/DocumentScanInput";

type Props = { isBankID: boolean; nationalities: string[] };

const ComponentResources =
  Resources.More.PersonalData.EditPersonalData.Modelling.PrimaryDocument;

export const DocumentsForm: FunctionComponent<Props> = ({
  isBankID,
  nationalities,
}) => {
  const { t } = useResource();

  const countries = useCountriesList();
  const documents = useDocumentsList();

  const allowedDocuments = useMemo(() => {
    const allowedDocumentTypes = getAllowedDocumentTypes(nationalities);

    if (!isBankID && nationalities.some(x => x === CZECHIA_COUNTRY_CODE)) {
      const identityCard = documents.find(
        x => x.Value === PersonalDocumentType.IdentityCard,
      );

      return identityCard ? [identityCard] : [];
    }

    return documents.filter(x => allowedDocumentTypes.some(y => y === x.Value));
  }, [isBankID, documents, nationalities]);

  const {
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<PrimaryDocumentFormModel>();

  const handleIssueDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const issueDate = new Date(event.target.value);
    const issueDateLowerBoundary = new Date(1900, 11, 30);
    const issueDateUpperBoundary = new Date();

    if (isDateValid(issueDate) && issueDate > issueDateUpperBoundary) {
      setValue(
        "primaryDocument.issueDate",
        parseDateToDocumentsDate(issueDateUpperBoundary),
        {
          shouldValidate: true,
        },
      );
      return;
    }

    if (isDateValid(issueDate) && issueDate < issueDateLowerBoundary) {
      setValue(
        "primaryDocument.issueDate",
        parseDateToDocumentsDate(issueDateLowerBoundary),
        {
          shouldValidate: true,
        },
      );
      return;
    }

    trigger("primaryDocument.issueDate");
  };

  const handleExpiryDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    const expiryDate = new Date(event.target.value);
    const expiryDateLowerBoundary = new Date();
    const expiryDateUpperBoundary = new Date(2999, 11, 30);

    if (isDateValid(expiryDate) && expiryDate > expiryDateUpperBoundary) {
      setValue(
        "primaryDocument.expiryDate",
        parseDateToDocumentsDate(expiryDateUpperBoundary),
        {
          shouldValidate: true,
        },
      );
      return;
    }

    if (isDateValid(expiryDate) && expiryDate < expiryDateLowerBoundary) {
      setValue(
        "primaryDocument.expiryDate",
        parseDateToDocumentsDate(expiryDateLowerBoundary),
        {
          shouldValidate: true,
        },
      );
      return;
    }

    trigger("primaryDocument.expiryDate");
  };

  return (
    <InputHeaderWrapper header={t(ComponentResources.Header)}>
      <DropdownInput
        control={control}
        errors={errors}
        name="primaryDocument.type"
        options={allowedDocuments}
        label={t(ComponentResources.Type)}
        disabled={isBankID || allowedDocuments.length === 1}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.number"
        label={t(ComponentResources.Number)}
        disabled={isBankID}
        mask={/^.+$/}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.issueDate"
        type="date"
        label={t(ComponentResources.IssueDate)}
        disabled={isBankID}
        onBlur={handleIssueDateBlur}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.expiryDate"
        type="date"
        label={t(ComponentResources.ExpiryDate)}
        disabled={isBankID}
        onBlur={handleExpiryDateBlur}
      />

      <BlInputFormField
        control={control}
        errors={errors}
        name="primaryDocument.issuingAuthority"
        label={t(ComponentResources.IssuingAuthority)}
        placeholder={t(ComponentResources.IssuingAuthorityPlaceholder)}
        disabled={isBankID}
      />

      <DropdownInput
        control={control}
        errors={errors}
        name="primaryDocument.issueCountry"
        options={countries}
        label={t(ComponentResources.IssueCountry)}
        disabled={isBankID}
      />

      {!isBankID && (
        <>
          <DocumentScanInput
            isFrontScan
            label={t(ComponentResources.FrontScan)}
          />
          <DocumentScanInput
            isFrontScan={false}
            label={t(ComponentResources.BackScan)}
          />
        </>
      )}
    </InputHeaderWrapper>
  );
};
