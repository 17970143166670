
/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// ARCHITECTURE START
  export type FetchResponse<T> = {
    json: T;
    status: number;
    args: any;
    error: any;
  };
  
  type Configuration = {
    jwtKey: string | undefined;
    onResponse?: (response: FetchResponse<any>) => void;
  };
  
  let CONFIG: Configuration = {
    jwtKey: undefined,
    onResponse: () => {},
  };
  
  export function configureApiCalls(configuration: Configuration) {
    CONFIG = { ...CONFIG, ...configuration };
  }
  
  async function fetchJson<T>(...args: any): Promise<FetchResponse<T>> {
    const errorResponse = (response: Response, args: any) => {
      const errorResponse = { status: response.status, json: null as any, args, error: response };
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse;
    }

    const errorStatus = (status: number, args: any) => {
      const errorResponse = { status: status, json: null as any, args, error: new Error("Network error", {cause: status}) };
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse;
    }

    try {
      const res: Response = await (fetch as any)(...args);
      try {
        const json = await res.json();
       const isSuccess = res.status >= 200 && res.status < 300;
        const response = { json: isSuccess ? json : null, status: res.status, args, error: isSuccess ? null : json };
        CONFIG.onResponse && CONFIG.onResponse(response);
        return response;
      }
      catch {
        return errorResponse(res, args)
      }
    } catch {
      return errorStatus(503, args);
    }
  }
  
  const updateHeaders = (headers: Headers) => {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = CONFIG.jwtKey
      ? localStorage.getItem(CONFIG.jwtKey as any)
      : undefined;
    if (!headers.has("Authorization") && token) {
      headers.append("Authorization", token);
    }
  };

function apiPost<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  var raw = JSON.stringify(request);
  updateHeaders(headers);
  var requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

function apiGet<TResponse>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";
  const requestOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPut<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

function apiDelete<TResponse>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  var requestOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPatch<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}
// ARCHITECTURE END

export const API_ROUTES = { 
	getAccessControlDistributions: "/api/access-control/distributions",
	postAttachments: "/api/attachments",
	deleteAttachmentsAttachmentGuid: "/api/attachments",
	postAuthSignUpBiometricsChallenge: "/api/auth/sign-up/biometrics/challenge",
	postAuthSignUpBiometrics: "/api/auth/sign-up/biometrics",
	postAuthSignInBiometricsChallenge: "/api/auth/sign-in/biometrics/challenge",
	postAuthSignInBiometrics: "/api/auth/sign-in/biometrics",
	deleteAuthBiometricsId: "/api/auth/biometrics",
	postAuthSignUp: "/api/auth/sign-up",
	postAuthSignIn: "/api/auth/sign-in",
	deleteAuthTokenRevoke: "/api/auth/token/revoke",
	postAuthVerifyEmail: "/api/auth/verify-email",
	postAuthResendVerificationEmail: "/api/auth/resend-verification-email",
	postAuthPasswordReset: "/api/auth/password/reset",
	postAuthPasswordSet: "/api/auth/password/set",
	putAuthPassword: "/api/auth/password",
	postAuthTokenProlong: "/api/auth/token/prolong",
	getBankIdAuthVerify: "/api/bank-id/auth/verify",
	getBankIdAuthNativeCallback: "/api/bank-id/auth/native-callback",
	postBankIdAuthProcessesInit: "/api/bank-id/auth/processes/init",
	getBankIdAuthProcessesProcessID: "/api/bank-id/auth/processes",
	getCacheRefresh: "/api/cache/refresh",
	getV1CalculationsPension: "/api/v1/calculations/pension",
	postV1Campaigns: "/api/v1/campaigns",
	postV1CampaignsChannelIDNotification: "/api/v1/campaigns",
	getCampaigns: "/api/campaigns",
	putCampaignsChannelID: "/api/campaigns",
	getClient: "/api/client",
	putClientTermsAndServices: "/api/client/terms-and-services",
	putClientNotificationStatus: "/api/client/notification-status",
	putClientAnalyticsStatus: "/api/client/analytics-status",
	getClientDealers: "/api/client/dealers",
	postClientContactConfirmation: "/api/client/contact-confirmation",
	putClientContactConfirmation: "/api/client/contact-confirmation",
	getClientExistence: "/api/client/existence",
	postClientPersonalData: "/api/client/personal-data",
	postClientAml: "/api/client/aml",
	getClientDocuments: "/api/client/documents",
	getClientVerificationType: "/api/client/verification/type",
	getCodeListBanks: "/api/code-list/banks",
	getCodeListCountries: "/api/code-list/countries",
	getCodeListBusinessSector: "/api/code-list/business-sector",
	getCodeListCurrencies: "/api/code-list/currencies",
	getContractDashboard: "/api/contract/dashboard",
	getContractContractID: "/api/contract",
	getContractTransactionsContractID: "/api/contract/transactions",
	getContractCashFlowContractID: "/api/contract/cash-flow",
	getContractBankAccountsContractID: "/api/contract/bank-accounts",
	getContractDealerContractID: "/api/contract/dealer",
	getContractDetailContractID: "/api/contract/detail",
	getContractUnsettledTradesContractID: "/api/contract/unsettled-trades",
	postContractInvestUninvestedDeposits: "/api/contract/invest-uninvested-deposits",
	postContractRedemption: "/api/contract/redemption",
	postContractPreview: "/api/contract/preview",
	postContract: "/api/contract",
	deleteContractContractIDInstruction: "/api/contract",
	getContractPaymentInstructions: "/api/contract/payment-instructions",
	postContractTransferDip: "/api/contract/transfer/dip",
	postContractTransferDipContractPreview: "/api/contract/transfer/dip/contract/preview",
	postContractContractIDBankConnections: "/api/contract",
	postContractContractIDBankConnectionsDeactivate: "/api/contract",
	postContractContractIDExchangeAssets: "/api/contract",
	postContractContractIDEdit: "/api/contract",
	getContractDraft: "/api/contract-draft",
	postContractDraft: "/api/contract-draft",
	postContractDraftDocument: "/api/contract-draft/document",
	getContractDraftDocumentDocumentGuid: "/api/contract-draft/document",
	getDocumentsFileName: "/api/documents",
	getDocuments: "/api/documents",
	getExchangeRate: "/api/exchange-rate",
	getFcmTokensTokenStatus: "/api/fcm/tokens",
	postFcmTokens: "/api/fcm/tokens",
	getHealthStatus: "/api/health/status",
	postLogError: "/api/log/Error",
	postPaymentQrCode: "/api/payment/qr-code",
	getProductList: "/api/product/list",
	getProductTilesList: "/api/product/tiles/list",
	getProductSectionsIsin: "/api/product/sections",
	getProductFeeRateCalculation: "/api/product-fee-rate/calculation",
	postV1PushNotificationsSend: "/api/v1/push-notifications/send",
	getSettings: "/api/settings",
	postSignatureSms: "/api/signature/sms",
	putSignatureSms: "/api/signature/sms",
	postSignatureBiometrics: "/api/signature/biometrics",
	putSignatureBiometrics: "/api/signature/biometrics",
	postSignatureBiometricsVerification: "/api/signature/biometrics/verification",
	putSignatureBiometricsVerification: "/api/signature/biometrics/verification",
	postTracking: "/api/tracking",
	getUserContact: "/api/user/contact",
	getValuesLogError: "/api/values/log-error",
	getValuesLogError2: "/api/values/log-error2",
	getValuesLogError3: "/api/values/log-error3",
	getValuesLogError4: "/api/values/log-error4"
}

export type DistributionsAccessControlQueryResult = {
	status: DistributionsAccessControlQueryResultStatus;
	distributionID?: number | null;
	error?: ApiCallError | null;
};

export enum DistributionsAccessControlQueryResultStatus {
	AccessAllowed = "AccessAllowed",
	MoreThan1000ContractsFound = "MoreThan1000ContractsFound",
	AccessRestricted = "AccessRestricted",
	Fail = "Fail"
}

export type ApiCallError = {
	httpStatus: number;
	errorMessages: string[];
	userFriendlyMessage: string;
};

export type CreateOrUpdateAttachmentCommandResult = {
	status: CreateOrUpdateAttachmentCommandResultStatus;
	error?: ApiCallError | null;
};

export enum CreateOrUpdateAttachmentCommandResultStatus {
	Success = "Success",
	InvalidBase64String = "InvalidBase64String",
	MimeTypeNotAllowed = "MimeTypeNotAllowed",
	Fail = "Fail"
}

export type CreateOrUpdateAttachmentRequest = {
	attachmentGuid: string;
	base64Data: string;
};

export type DeleteAttachmentCommandResult = {
	status: DeleteAttachmentCommandResultStatus;
	error?: ApiCallError | null;
};

export enum DeleteAttachmentCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type SignUpBiometricsChallengeCommandResult = {
	challenge?: string | null;
	error?: ApiCallError | null;
};

export type SignUpBiometricsCommandResult = {
	status: SignUpBiometricsCommandResultStatus;
	error?: ApiCallError | null;
};

export enum SignUpBiometricsCommandResultStatus {
	Success = "Success",
	Failed = "Failed"
}

export type SignUpBiometricsRequestDto = {
	challenge: string;
	signedChallenge: string;
	publicKey: string;
	id: string;
};

export type SignInBiometricsChallengeCommandResult = {
	challenge?: string | null;
	error?: ApiCallError | null;
};

export type SignInBiometricsChallengeCommand = {
	login: string;
};

export type SignInCommandResult = {
	signInResult: SignInResult;
	status: SignInCommandResultStatus;
	error?: ApiCallError | null;
};

export type SignInResult = {
	twoFactorTokenID?: string | null;
	status: SignInStatus;
	token?: string | null;
	email?: string | null;
	login?: string | null;
	error: AuthError;
	errorMessage?: string | null;
	accessRightCodes: string[];
	profilePicture?: string | null;
	appData?: AppData | null;
	hasAgreedToTermsAndConditionsOfMobileApp: boolean;
	isUnderage: boolean;
	defaultPage: Page;
	roles: string[];
};

export enum SignInStatus {
	Success = "Success",
	TwoFactorVerificationTokenStep = "TwoFactorVerificationTokenStep",
	Failed = "Failed",
	UnsafeSuccess = "UnsafeSuccess",
	PasswordResetRequired = "PasswordResetRequired",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
}

export enum AuthError {
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDisabled = "AccountIsDisabled",
	AccountNotFound = "AccountNotFound",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation",
	MissingAccessRole = "MissingAccessRole",
	InvalidClientType = "InvalidClientType",
	DealerAccessRoleNotAllowed = "DealerAccessRoleNotAllowed",
	InvestikaApiCallError = "InvestikaApiCallError"
}

export type AppData = {
	biometrics: BiometricsAppDataDto[];
	isAnalyticsEnabled: boolean;
	isNotificationEnabled: boolean;
};

export type BiometricsAppDataDto = {
	deviceID: string;
	isEnabled: boolean;
};

export enum Page {
	Dashboard = "Dashboard",
	ContractCreate = "ContractCreate"
}

export enum SignInCommandResultStatus {
	Success = "Success",
	ApplicationVerification = "ApplicationVerification",
	AccountNotFound = "AccountNotFound",
	InvalidClientType = "InvalidClientType",
	MissingAccessRole = "MissingAccessRole",
	DealerAccessRoleNotAllowed = "DealerAccessRoleNotAllowed",
	InvestikaApiCallError = "InvestikaApiCallError",
	InvalidCredentials = "InvalidCredentials",
	PasswordResetRequired = "PasswordResetRequired",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation",
	Fail = "Fail"
}

export type SignInBiometricsCommand = {
	challenge: string;
	signedChallenge: string;
	publicKey: string;
	login: string;
	trackingSessionID: string;
};

export type DeleteBiometricsCommandResult = {
	status: DeleteBiometricsCommandResultStatus;
	error?: ApiCallError | null;
};

export enum DeleteBiometricsCommandResultStatus {
	Success = "Success",
	Failed = "Failed"
}

export type SignUpCommandResult = {
	status: SignUpCommandResultStatus;
	tokenID?: string | null;
	error?: ApiCallError | null;
};

export enum SignUpCommandResultStatus {
	Success = "Success",
	GeneralError = "GeneralError"
}

export type SignUpCommand = {
	login: string;
	password: string;
	isAnalyticsEnabled: boolean;
};

export type SignInCommand = {
	login: string;
	password: string;
	token?: string | null;
	tokenID?: string | null;
	trackingSessionID?: string | null;
};

export type RevokeTokenCommandResult = {
	status: RevokeTokenCommandResultStatus;
	error?: ApiCallError | null;
};

export enum RevokeTokenCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type EmailVerificationCommandResult = {
	signInResult?: SignInResult | null;
	status: EmailVerificationCommandResultStatus;
	error?: ApiCallError | null;
};

export enum EmailVerificationCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type EmailVerificationCommand = {
	idToken: string;
	code: string;
};

export type ResendVerificationEmailCommandResult = {
	status: ResendVerificationEmailCommandResultStatus;
	tokenID?: string | null;
	error?: ApiCallError | null;
};

export enum ResendVerificationEmailCommandResultStatus {
	Success = "Success",
	Failed = "Failed"
}

export type ResendVerificationEmailCommand = {
	email: string;
};

export type ResetPasswordCommandResult = {
	tokenID?: string | null;
	status: ResetPasswordCommandResultStatus;
	error?: ApiCallError | null;
};

export enum ResetPasswordCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ResetPasswordCommand = {
	login: string;
};

export type SetPasswordCommandResult = {
	status: SetPasswordCommandResultStatus;
	error?: ApiCallError | null;
};

export enum SetPasswordCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type SetPasswordCommand = {
	tokenID: string;
	password: string;
	code: string;
};

export type ChangePasswordCommandResult = {
	status: ChangePasswordCommandStatus;
	signInResult?: SignInResult | null;
	error?: ApiCallError | null;
};

export enum ChangePasswordCommandStatus {
	Success = "Success"
}

export type ChangePasswordRequestDto = {
	oldPassword: string;
	newPassword: string;
};

export type ProlongTokenCommandResult = {
	status: ProlongTokenCommandResultStatus;
	token?: string | null;
	error?: ApiCallError | null;
};

export enum ProlongTokenCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ProcessBankIDVerificationCommandResult = {
	status: ProcessBankIDVerificationCommandResultStatus;
	verificationProcess?: BankIDVerificationProcess | null;
	error?: ApiCallError | null;
};

export enum ProcessBankIDVerificationCommandResultStatus {
	Success = "Success",
	UserInfoError = "UserInfoError",
	Fail = "Fail"
}

export type BankIDVerificationProcess = {
	processStatus: BankIDProcessStatus;
	processAudience: BankIDProcessAudience;
	profile?: BankIDProfileResponse | null;
};

export enum BankIDProcessStatus {
	Success = "Success",
	AuthInProcess = "AuthInProcess",
	AuthError = "AuthError",
	UserInfoError = "UserInfoError",
	Error = "Error"
}

export enum BankIDProcessAudience {
	ContractCreate = "ContractCreate",
	EditPersonalData = "EditPersonalData"
}

export type BankIDProfileResponse = {
	sub: string;
	txn: string;
	verified_claims?: VerifiedClaimsDto | null;
	given_name: string;
	middle_name?: string | null;
	family_name: string;
	gender: string;
	birthdate: string;
	birthnumber: string;
	age?: number | null;
	majority?: boolean | null;
	date_of_death: any;
	birthplace: string;
	birthcountry: string;
	primary_nationality?: string | null;
	nationalities?: string[] | null;
	maritalstatus: string;
	email: string;
	phone_number: string;
	pep?: boolean | null;
	limited_legal_capacity?: boolean | null;
	addresses: Address[];
	idcards: Idcard[];
	paymentAccounts?: string[] | null;
	paymentAccountsDetails?: PaymentAccountsDetail[] | null;
	updated_at?: number | null;
};

export type VerifiedClaimsDto = {
	verification: Verification;
};

export type Verification = {
	trust_framework: string;
	time: string;
	verification_process: string;
};

export type Address = {
	type: string;
	street?: string | null;
	buildingapartment?: string | null;
	streetnumber?: string | null;
	evidencenumber?: string | null;
	city: string;
	cityarea?: string | null;
	zipcode: string;
	country: string;
	ruian_reference?: string | null;
};

export type Idcard = {
	type: string;
	description?: string | null;
	country: string;
	number: string;
	valid_to: string;
	issuer?: string | null;
	issue_date?: string | null;
};

export type PaymentAccountsDetail = {
	iban: string;
	currency: string;
};

export type InitializeBankIDVerificationProcessCommandResult = {
	status: InitializeBankIDVerificationProcessCommandResultStatus;
	error?: ApiCallError | null;
};

export enum InitializeBankIDVerificationProcessCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type InitializeBankIDVerificationProcessRequest = {
	processID: string;
	processAudience: BankIDProcessAudience;
	trackingSessionID: string;
};

export type GetBankIDVerificationProcessQueryResult = {
	status: GetBankIDProcessQueryResultStatus;
	verificationProcess?: BankIDVerificationProcess | null;
	error?: ApiCallError | null;
};

export enum GetBankIDProcessQueryResultStatus {
	Success = "Success",
	ProcessNotFound = "ProcessNotFound",
	Fail = "Fail"
}

export type CalculatePensionResponse = {
	yearsToPension: number;
	estimatedMonthlyStatePension: number;
	recommendedMonthlyPortfolioPension: number;
	minimumPortfolioValue: number;
	recommendedMonthlyInvestment: number;
	expectedPortfolioValue: number;
	estimatedMonthlyPortfolioPension: number;
	dipTaxSavings: number;
	pensionAge: number;
	pensionYears: number;
	monthlyInvestment: number;
	yields: YieldItem[];
	errors: {[key: string | number]: string[]};
};

export type YieldItem = {
	investmentStartMonth: number;
	portfolioValue: number;
	investmentValue: number;
};

export enum Gender {
	Male = "Male",
	Female = "Female",
	Other = "Other"
}

export enum InvestmentStrategy {
	Dynamic = "Dynamic",
	DynamicUnder30 = "DynamicUnder30",
	Balanced = "Balanced",
	Conservative = "Conservative"
}

export type CreateCampaignResponse = {
	status: CreateCampaignResponseStatus;
	error?: ApiCallError | null;
};

export enum CreateCampaignResponseStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ApiProblemDetails = {
	errors: {[key: string | number]: ErrorDetail[]};
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
	extensions: unknown;
};

export type ErrorDetail = {
	code: ErrorCode;
	message?: string | null;
};

export enum ErrorCode {
	Unspecified = "Unspecified",
	OutOfRange = "OutOfRange",
	NotFound = "NotFound",
	Invalid = "Invalid",
	Forbidden = "Forbidden",
	TooManyRequests = "TooManyRequests",
	Conflict = "Conflict",
	NullOrEmpty = "NullOrEmpty",
	Unauthorized = "Unauthorized",
	ExternalProviderNotAvailable = "ExternalProviderNotAvailable"
}

export type CreateCampaignRequest = {
	channelID: string;
	title: string;
	body?: string | null;
	deepLink?: string | null;
	startDate: string;
	endDate?: string | null;
	visibleForAll: boolean;
};

export type SendCampaignNotificationsResponse = {
	status: SendCampaignNotificationsResponseStatus;
	error?: ApiCallError | null;
};

export enum SendCampaignNotificationsResponseStatus {
	Success = "Success",
	CampaignNotFound = "CampaignNotFound",
	CampaignIsVisibleForAll = "CampaignIsVisibleForAll",
	Fail = "Fail"
}

export type SendCampaignNotificationsRequest = {
	recipients: Recipient[];
};

export type Recipient = {
	login: string;
};

export type UserCampaignListQueryResult = {
	status: CampaignListQueryResultStatus;
	campaigns?: CampaignDto[] | null;
	error?: ApiCallError | null;
};

export enum CampaignListQueryResultStatus {
	Success = "Success",
	NotFound = "NotFound",
	Fail = "Fail"
}

export type CampaignDto = {
	channelID: string;
	title: string;
	body?: string | null;
	deepLink?: string | null;
};

export type HideUserCampaignCommandResult = {
	status: HideUserCampaignCommandResultStatus;
	error?: ApiCallError | null;
};

export enum HideUserCampaignCommandResultStatus {
	Success = "Success",
	CampaignNotFound = "CampaignNotFound",
	Fail = "Fail"
}

export type HideUserCampaignRequest = {
	action: string;
};

export type ClientDto = {
	name: PersonNameDto;
	addresses: AddressDto[];
	email: string;
	mobile: string;
	citizenship: string;
	otherCitizenships: string[];
	personalIdentificationNumber: string;
	birthPlace: string;
	birthCountry: string;
	birthDate?: string | null;
	sex: SexType;
	taxResidencyCountry: string;
	taxResidencyDic: string;
	amlExpiryDate?: string | null;
	document: PersonalDocumentDto;
	secondDocument?: PersonalDocumentDto | null;
	agreements: ClientAgreement[];
};

export type PersonNameDto = {
	titleBefore: string;
	firstName: string;
	lastName: string;
	titleAfter: string;
};

export type AddressDto = {
	type: AddressType;
	streetName: string;
	streetConscriptionNumber: string;
	streetNumber: string;
	city: string;
	country: string;
	zip: string;
};

export enum AddressType {
	Domicile = "Domicile",
	Postal = "Postal",
	Origin = "Origin"
}

export enum SexType {
	M = "M",
	F = "F"
}

export type PersonalDocumentDto = {
	type: PersonalDocumentType;
	number: string;
	issueDate: string;
	validTill?: string | null;
	isValidTillIndefinite: boolean;
	issuedBy: string;
	issueCountry: string;
};

export enum PersonalDocumentType {
	IdentityCard = "IdentityCard",
	DrivingLicense = "DrivingLicense",
	Passport = "Passport",
	GunLicense = "GunLicense",
	BirthCertificate = "BirthCertificate",
	ExctractFromCommercialRegister = "ExctractFromCommercialRegister",
	TradeLicense = "TradeLicense",
	TemporaryResidencePermit = "TemporaryResidencePermit",
	Other = "Other"
}

export enum ClientAgreement {
	ClientAgreeInfo = "ClientAgreeInfo",
	ClientAgreeBioSign = "ClientAgreeBioSign",
	ClientAgreeTakeoverData = "ClientAgreeTakeoverData"
}

export type UpdateClientTermsAndServicesCommandResult = {
	status: UpdateClientTermsAndServicesCommandResultStatus;
	error?: ApiCallError | null;
};

export enum UpdateClientTermsAndServicesCommandResultStatus {
	Success = "Success",
	Fail = "Fail",
	MustBeAccepted = "MustBeAccepted"
}

export type TermsOfConditionAcceptanceDto = {
	hasAgreedToTermsAndConditions: boolean;
};

export type UpdateClientNotificationStatusCommandResult = {
	status: UpdateClientNotificationEnabledCommandResultStatus;
	error?: ApiCallError | null;
};

export enum UpdateClientNotificationEnabledCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ClientNotificationStatusDto = {
	isNotificationEnabled: boolean;
};

export type UpdateClientAnalyticsStatusCommandResult = {
	status: UpdateClientAnalyticsStatusCommandResultStatus;
	error?: ApiCallError | null;
};

export enum UpdateClientAnalyticsStatusCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ClientAnalyticsStatusDto = {
	isAnalyticsEnabled: boolean;
};

export type DealerDto = {
	dealerCode: string;
	dealerID?: number | null;
	name: PersonNameDto;
	distributionName: string;
};

export type ClientSendContactConfirmationCommandResult = {
	status: ClientSendContactConfirmationCommandResultStatus;
	contactConfirmation?: ContactConfirmationDto | null;
	error?: ApiCallError | null;
};

export enum ClientSendContactConfirmationCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ContactConfirmationDto = {
	verificationTokenID: string;
	code: string;
};

export type ClientSendContactConfirmationDto = {
	mobilePhoneNumber?: string | null;
};

export type ClientVerifyContactConfirmationCommandResult = {
	status: ClientVerifyContactConfirmationCommandResultStatus;
	error?: ApiCallError | null;
};

export enum ClientVerifyContactConfirmationCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ClientVerifyContactConfirmationCommand = {
	contactConfirmation: ContactConfirmationDto;
};

export type CheckClientExistsQueryResult = {
	status: CheckClientExistsQueryResultStatus;
	error?: ApiCallError | null;
};

export enum CheckClientExistsQueryResultStatus {
	ClientExists = "ClientExists",
	ClientDoesNotExist = "ClientDoesNotExist",
	Fail = "Fail"
}

export type UpdateClientPersonalDataCommandResult = {
	status: UpdateClientPersonalDataCommandResultStatus;
	signatureHash?: string | null;
	requestHash?: string | null;
	clientVerificationHash?: string | null;
	error?: ApiCallError | null;
};

export enum UpdateClientPersonalDataCommandResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	ContactInfoNotFound = "ContactInfoNotFound",
	BankIDProfileNotFound = "BankIDProfileNotFound",
	DocumentScanNotFound = "DocumentScanNotFound",
	DocumentScansNotFound = "DocumentScansNotFound",
	SignatureDocumentNotFound = "SignatureDocumentNotFound",
	Fail = "Fail"
}

export type UpdateClientPersonalDataRequest = {
	personalData: UpdateClientPersonalDataFormModel;
	contactInfo: UpdateClientPersonalDataContactInfoFormModel;
	permanentAddress: UpdateClientPersonalDataAddressFormModel;
	primaryDocument: UpdateClientPersonalDataDocumentFormModel;
	isBiometry: boolean;
	isBankID: boolean;
};

export type UpdateClientPersonalDataFormModel = {
	lastName: string;
};

export type UpdateClientPersonalDataContactInfoFormModel = {
	email: string;
	phone: string;
};

export type UpdateClientPersonalDataAddressFormModel = {
	streetName: string;
	streetNumber?: string | null;
	streetConscriptionNumber?: string | null;
	postalCode: string;
	city: string;
	country: string;
};

export type UpdateClientPersonalDataDocumentFormModel = {
	type: PersonalDocumentType;
	number: string;
	issuingAuthority: string;
	issueCountry: string;
	issueDate: string;
	expiryDate: string;
	frontScan?: UpdateClientPersonalDataDocumentScanFormModel | null;
	backScan?: UpdateClientPersonalDataDocumentScanFormModel | null;
};

export type UpdateClientPersonalDataDocumentScanFormModel = {
	documentGuid: string;
	fileName: string;
};

export type UpdateAmlCommandResult = {
	status: UpdateAmlCommandResultStatus;
	requestHash?: string | null;
	signatureHash?: string | null;
	error?: ApiCallError | null;
};

export enum UpdateAmlCommandResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	DocumentsNotFound = "DocumentsNotFound",
	Fail = "Fail"
}

export type UpdateAmlRequest = {
	aml: AmlDto;
	isBiometry: boolean;
};

export type AmlDto = {
	netIncome: NetIncomeType;
	sourceOfIncome: SourceOfIncomeType;
	sourceOfIncomeOther: string;
	sourceOfResources: SourceOfResourcesType[];
	sourceOfResourcesOther: string;
	businessSector: number;
	job: JobType;
	sourceValue: number;
	sourceCurrency: CurrencyCode;
	pep: boolean;
	pepOriginProperty: string;
	taxResidenceCountry: string;
	tin: string;
	isDeclaration1: boolean;
	isDeclaration2: boolean;
	beneficient: string;
};

export enum NetIncomeType {
	A = "A",
	B = "B",
	C = "C",
	D = "D"
}

export enum SourceOfIncomeType {
	Salary = "Salary",
	Business = "Business",
	Rent = "Rent",
	Dividend = "Dividend",
	Other = "Other"
}

export enum SourceOfResourcesType {
	Savings = "Savings",
	SaleOfProperty = "SaleOfProperty",
	Heritage = "Heritage",
	Other = "Other"
}

export enum JobType {
	NonManagerialPosition = "NonManagerialPosition",
	ManagerialPosition = "ManagerialPosition"
}

export enum CurrencyCode {
	CZK = "CZK",
	EUR = "EUR"
}

export type ClientDocumentsQueryResult = {
	status: ClientDocumentsQueryResultStatus;
	documents?: ClientDocument[] | null;
	error?: ApiCallError | null;
};

export enum ClientDocumentsQueryResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	Fail = "Fail"
}

export type ClientDocument = {
	type: ClientDocumentType;
	category: ClientDocumentCategory;
	contractID?: number | null;
	fileName: string;
};

export enum ClientDocumentType {
	ClientAmlChange = "ClientAmlChange",
	ClientPersonalDataChange = "ClientPersonalDataChange",
	Other = "Other"
}

export enum ClientDocumentCategory {
	Report = "Report",
	Request = "Request",
	ChangeRequest = "ChangeRequest",
	Other = "Other"
}

export type ClientVerificationTypeQueryResult = {
	status: ClientVerificationTypeQueryResultStatus;
	isBankIDVerified: boolean;
	error?: ApiCallError | null;
};

export enum ClientVerificationTypeQueryResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	Fail = "Fail"
}

export type BankListQueryResult = {
	bankList: BankItemDto[];
	status: BankListQueryResultStatus;
	error: ApiCallError;
};

export type BankItemDto = {
	numericCode: string;
	swiftCode: string;
	name: string;
	country: string;
};

export enum BankListQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type CountryListQueryResult = {
	status: CountryListQueryResultStatus;
	countries?: CodeListItemDto[] | null;
	error?: ApiCallError | null;
};

export enum CountryListQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type CodeListItemDto = {
	name: string;
	code: string;
};

export type BusinessSectorListQueryResult = {
	status: BusinessSectorListQueryResultStatus;
	businessSectors?: CodeListItemDto[] | null;
	error?: ApiCallError | null;
};

export enum BusinessSectorListQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ContractDashboardQueryResult = {
	contractDashboard?: ContractDashboardDto | null;
	status: ContractDashboardQueryResultStatus;
	error?: ApiCallError | null;
};

export type ContractDashboardDto = {
	totalAmount: number;
	isAccessRestricted: boolean;
	superiorAgentID?: number | null;
	contracts: ContractDto[];
};

export type ContractDto = {
	contractID?: number | null;
	name?: string | null;
	actualAmount?: number | null;
	currency: CurrencyCode;
	actualPercent?: number | null;
	purposeAmount?: number | null;
	signDate?: string | null;
	signPlace?: string | null;
	purposeDate?: string | null;
	isDipAllowed?: boolean | null;
	isDip?: boolean | null;
	dipOpeningDate?: string | null;
	dipCancellationDate?: string | null;
	type: ContractTypeCode;
	purposeCategory?: number | null;
	bank?: string | null;
	bankAccountNumber?: string | null;
	iban?: string | null;
	bankAccountCurrency: CurrencyCode;
	contractHash?: string | null;
	investmentProspect: number;
	signType: ContractSignType;
	dealerCode?: string | null;
	dealerId?: number | null;
	realizedProfit?: number | null;
	performanceFromAccountCreation?: number | null;
	isReadonly: boolean;
	modelling?: ContractModelling | null;
};

export enum ContractTypeCode {
	BEN = "BEN",
	CONSIGNMENT = "CONSIGNMENT",
	DYN = "DYN",
	FCG = "FCG",
	LIBERO_RS = "LIBERO_RS",
	OKP = "OKP",
	OKS_FKI = "OKS_FKI",
	OKS_Investor = "OKS_Investor",
	OKS_LC = "OKS_LC",
	OKS_LC_EX = "OKS_LC_EX",
	OKS_LC_EX_ = "OKS_LC_EX_",
	OKSP_LC = "OKSP_LC",
	OKSP_LC_EX = "OKSP_LC_EX",
	OKSP_LC_EX_ = "OKSP_LC_EX_",
	RS_INVCZK = "RS_INVCZK",
	RS_INVCZKSELF = "RS_INVCZKSELF",
	RS_INVEUR = "RS_INVEUR",
	RS_INVEURSELF = "RS_INVEURSELF",
	RS_INVPROFICZK = "RS_INVPROFICZK",
	RS_OKSmartFondy = "RS_OKSmartFondy",
	RS_OKSmartFondy_EX = "RS_OKSmartFondy_EX",
	RS_OKSmartFondy_EX_ = "RS_OKSmartFondy_EX_",
	RS_OKSmartProdukty = "RS_OKSmartProdukty",
	RS_OKSmartProdukty_EX = "RS_OKSmartProdukty_EX",
	RS_OKSmartProdukty_EX_ = "RS_OKSmartProdukty_EX_"
}

export enum ContractSignType {
	SMS = "SMS",
	BIO = "BIO"
}

export type ContractModelling = {
	isDip: boolean;
	sessionID?: string | null;
	channelID?: string | null;
	modellingItems: ContractModellingItem[];
};

export type ContractModellingItem = {
	productID?: number | null;
	isin: string;
	singleInvestment: number;
	periodicalInvestment: number;
	investmentLengthYears: number;
};

export enum ContractDashboardQueryResultStatus {
	Success = "Success",
	Fail = "Fail",
	ClientNotFound = "ClientNotFound",
	MoreThan1000ContractsFound = "MoreThan1000ContractsFound"
}

export type ContractOverviewQueryResult = {
	contractOverview?: ContractOverviewDto | null;
	status: ContractOverviewQueryResultStatus;
	error?: ApiCallError | null;
};

export type ContractOverviewDto = {
	name?: string | null;
	signDate?: string | null;
	asset?: number | null;
	actualAmount: number;
	purposeAmount?: number | null;
	actualPercent?: number | null;
	purposeCategory?: number | null;
	currency: CurrencyCode;
	totalDeposits?: number | null;
	totalInvestments?: number | null;
	totalWithdraws?: number | null;
	uninvestedDeposits?: number | null;
	awaitingInvestments?: number | null;
	paidCommissions?: number | null;
	realizedProfit?: number | null;
	unrealizedProfit?: number | null;
	performanceFromAccountCreation?: number | null;
	lastMovementDate?: string | null;
	bankAccountRegion?: string | null;
	graphData: ContractGraphItemDto[];
	portfolioItems: ContractOverviewPortfolioItemDto[];
	type: ContractTypeCode;
	isDipAllowed?: boolean | null;
	isDip?: boolean | null;
	dipOpeningDate?: string | null;
	typeName: string;
	isFirstPayment: boolean;
	isBankIDVerified: boolean;
	isReadonly: boolean;
	hasExchangeableAssets: boolean;
	exchangeableAssets?: ContractExchangeableAsset[] | null;
	modelling?: ContractModelling | null;
};

export type ContractGraphItemDto = {
	date?: string | null;
	value?: number | null;
	deposit?: number | null;
};

export type ContractOverviewPortfolioItemDto = {
	name: string;
	amount?: number | null;
	currency: CurrencyCode;
	awaitingInvestments?: number | null;
	isin: string;
	productID?: number | null;
	quantity?: number | null;
};

export type ContractExchangeableAsset = {
	sourceProductID: number;
	sourceIsin: string;
	sourceCurrency: CurrencyCode;
	targetProductID: number;
	targetIsin: string;
	targetCurrency: CurrencyCode;
	single: ContractExchangeableAssetInvestmentDescriptor;
	periodical: ContractExchangeableAssetInvestmentDescriptor;
};

export type ContractExchangeableAssetInvestmentDescriptor = {
	isAllowed: boolean;
	minAmount?: number | null;
	maxAmount?: number | null;
	defaultAmount?: number | null;
};

export enum ContractOverviewQueryResultStatus {
	Success = "Success",
	Fail = "Fail",
	ContractNotFound = "ContractNotFound",
	ContractAccessDenied = "ContractAccessDenied"
}

export type ContractTransactionsQueryResult = {
	status: ContractTransactionsQueryResultStatus;
	data?: ContractTransactionDto[] | null;
	metadata?: PaginationMetadata | null;
	error?: ApiCallError | null;
};

export enum ContractTransactionsQueryResultStatus {
	Success = "Success",
	Fail = "Fail",
	ClientNotFound = "ClientNotFound",
	ContractAccessDenied = "ContractAccessDenied"
}

export type ContractTransactionDto = {
	name: string;
	investmentType?: InvestmentType | null;
	numberOfItems?: number | null;
	pricePerItem?: number | null;
	amount?: number | null;
	date: string;
	currency: CurrencyCode;
	transactionID?: number | null;
};

export enum InvestmentType {
	Repurchase = "Repurchase",
	Transfer = "Transfer",
	Exchange = "Exchange",
	Issue = "Issue"
}

export type PaginationMetadata = {
	currentPage: number;
	totalPages: number;
	pageSize: number;
	totalCount: number;
	hasPrevious: boolean;
	hasNext: boolean;
};

export type ContractCashFlowQueryResult = {
	status: ContractCashFlowQueryResultStatus;
	data?: ContractCashFlowItemDto[] | null;
	metadata?: PaginationMetadata | null;
	error?: ApiCallError | null;
};

export enum ContractCashFlowQueryResultStatus {
	Success = "Success",
	Fail = "Fail",
	ClientNotFound = "ClientNotFound",
	ContractAccessDenied = "ContractAccessDenied"
}

export type ContractCashFlowItemDto = {
	date: string;
	directionType: number;
	description: string;
	amount?: number | null;
	isClientDeposit: boolean;
	isFee: boolean;
	isClientWithdrawal: boolean;
	cashFlowID?: string | null;
	currency: CurrencyCode;
};

export type BankAccountItemDto = {
	bankAccountID?: number | null;
	bankCode: string;
	accountNumber: string;
	iban: string;
	bankName: string;
	currency: CurrencyCode;
};

export type ContractDealerDto = {
	firstName: string;
	lastName: string;
	distribution: string;
	phone: string;
	email: string;
};

export type ContractDetailDto = {
	status: ContractStatusType;
	typeName: string;
	isDipAllowed?: boolean | null;
	isDip?: boolean | null;
	dipOpeningDate?: string | null;
	type: ContractTypeCode;
	contractNo: string;
	signDate?: string | null;
	documents?: ContractDocumentDto[] | null;
};

export enum ContractStatusType {
	Active = "Active",
	Blocked = "Blocked",
	Ended = "Ended",
	Canceled = "Canceled"
}

export type ContractDocumentDto = {
	fileName: string;
	type?: string | null;
};

export type ContractUnsettledTradesQueryResult = {
	contractUnsettledItems: ContractUnsettledTradeItemDto[];
	status: ContractUnsettledTradesQueryResultStatus;
	error: ApiCallError;
};

export type ContractUnsettledTradeItemDto = {
	type: ContractUnsettledTradeType;
	fundName?: string | null;
	targetFondName?: string | null;
	amount?: number | null;
	productID?: number | null;
	currency?: CurrencyCode | null;
	instructionValueType: InstructionValueType;
	unsettledTradeID?: number | null;
};

export enum ContractUnsettledTradeType {
	BUY = "BUY",
	SELL = "SELL",
	SWITCH = "SWITCH",
	TRANSFERIN = "TRANSFERIN",
	TRANSFEROUT = "TRANSFEROUT",
	UNKNOWN = "UNKNOWN"
}

export enum InstructionValueType {
	Pieces = "Pieces",
	Amount = "Amount",
	All = "All",
	Unknown = "Unknown"
}

export enum ContractUnsettledTradesQueryResultStatus {
	Success = "Success",
	ContractAccessDenied = "ContractAccessDenied",
	Fail = "Fail"
}

export type InvestUninvestedDepositsCommandResult = {
	signatureHashList: string[];
	status: InvestUninvestedDepositsCommandResultStatus;
	error: ApiCallError;
};

export enum InvestUninvestedDepositsCommandResultStatus {
	Success = "Success",
	Fail = "Fail",
	ClientNotFound = "ClientNotFound",
	ContractNotFound = "ContractNotFound",
	ContractNotActive = "ContractNotActive",
	ContractTypeNotAllowed = "ContractTypeNotAllowed",
	CurrencyMismatch = "CurrencyMismatch",
	InsufficientAccountBalance = "InsufficientAccountBalance",
	ProductNotFound = "ProductNotFound",
	ContractAccessDenied = "ContractAccessDenied"
}

export type InvestUninvestedDepositsDto = {
	contractID: number;
	isin: string;
	currency: CurrencyCode;
	amount: number;
	isBiometry: boolean;
};

export type ContractRedemptionCommandCommandResult = {
	signatureHashList: string[];
	status: ContractRedemptionCommandCommandResultStatus;
	error: ApiCallError;
};

export enum ContractRedemptionCommandCommandResultStatus {
	Success = "Success",
	Fail = "Fail",
	ContractNotFound = "ContractNotFound",
	DipCancelAgreeRequired = "DipCancelAgreeRequired",
	NotValidBankAccount = "NotValidBankAccount",
	ContractAccessDenied = "ContractAccessDenied"
}

export type ContractRedemptionCommand = {
	contractID: number;
	isin: string;
	amount: number;
	isBiometry: boolean;
	bankAccountID: number;
	type: RedemptionType;
	method: RedemptionMethod;
	dipCancelAgree: boolean;
};

export enum RedemptionType {
	Single = "Single",
	Periodical = "Periodical"
}

export enum RedemptionMethod {
	Pieces = "Pieces",
	Amount = "Amount"
}

export type ContractPreviewCommandResult = {
	contractPreview: ContractPreviewDto;
	status: ContractPreviewCommandResultStatus;
	error: ApiCallError;
};

export type ContractPreviewDto = {
	data: string;
};

export enum ContractPreviewCommandResultStatus {
	PreviewCreated = "PreviewCreated",
	Fail = "Fail"
}

export type NewContractDto = {
	client: ClientDto;
	aml: AmlDto;
	contract: ContractDto;
	attachments: AttachmentDto[];
	isBankID: boolean;
	isBiometry: boolean;
};

export type AttachmentDto = {
	source?: AttachmentSource | null;
	sourceId?: number | null;
	type: AttachmentType;
	documentDrawGuid: string;
	fileName: string;
	process?: boolean | null;
};

export enum AttachmentSource {
	Contract = "Contract",
	Request = "Request",
	User = "User"
}

export enum AttachmentType {
	AFFIDAVIT = "AFFIDAVIT",
	AGREEMENT = "AGREEMENT",
	AGR_APPENDIX = "AGR_APPENDIX",
	AMLINFO = "AMLINFO",
	ATTACHMENT = "ATTACHMENT",
	BANKACC_DEACTIVATION = "BANKACC_DEACTIVATION",
	BANKSTATEMENT = "BANKSTATEMENT",
	BIRTHCERT = "BIRTHCERT",
	CASHINVEST = "CASHINVEST",
	CERTMAIN1 = "CERTMAIN1",
	CERTMAIN2 = "CERTMAIN2",
	DATA_CHANGE_ACCOUNT = "DATA_CHANGE_ACCOUNT",
	DEACTIVATEBANKACCOUNT = "DEACTIVATEBANKACCOUNT",
	EET = "EET",
	EET1_0 = "EET1_0",
	EMT = "EMT",
	EMT1_0 = "EMT1_0",
	EMT3_0 = "EMT3_0",
	EMT3_1 = "EMT3_1",
	EPT = "EPT",
	EPT1_0 = "EPT1_0",
	EPT1_1 = "EPT1_1",
	EUROTEMPLATE_ALL = "EUROTEMPLATE_ALL",
	EXANTECOST = "EXANTECOST",
	FINAL_PROPOSAL = "FINAL_PROPOSAL",
	IDENTITYDOCUMENT = "IDENTITYDOCUMENT",
	IDENT_DATA_CHANGE = "IDENT_DATA_CHANGE",
	JSONBANKID = "JSONBANKID",
	NEWBANKACCOUNT = "NEWBANKACCOUNT",
	NEWPLAN = "NEWPLAN",
	NEW_INVESTMENT = "NEW_INVESTMENT",
	NEW_SELL = "NEW_SELL",
	OTHER = "OTHER",
	OTHERPEP = "OTHERPEP",
	OTHERPO = "OTHERPO",
	PAYMENTORDER = "PAYMENTORDER",
	PERIOD_SELL = "PERIOD_SELL",
	PLANCHANGE = "PLANCHANGE",
	PROPOSAL = "PROPOSAL",
	QI_STATEMENT = "QI_STATEMENT",
	QUALIFIEDINVESTORCONF = "QUALIFIEDINVESTORCONF",
	QUESTIONARY_CONFIRM = "QUESTIONARY_CONFIRM",
	REGCOMMCONFIRMATION = "REGCOMMCONFIRMATION",
	REGCOMMORDER = "REGCOMMORDER",
	RESIDENCE = "RESIDENCE",
	SECONDARYCERT = "SECONDARYCERT",
	SELL = "SELL",
	SH_INVEST_QUEST = "SH_INVEST_QUEST",
	TRAILER_FEE = "TRAILER_FEE",
	WARRANT = "WARRANT"
}

export type CreateContractCommandResult = {
	status: CreateContractCommandResultStatus;
	contract?: ContractDto | null;
	signatureHashList: string[];
	error?: ApiCallError | null;
};

export enum CreateContractCommandResultStatus {
	ContractCreated = "ContractCreated",
	ClientError = "ClientError",
	AmlError = "AmlError",
	ContractError = "ContractError",
	AttachmentError = "AttachmentError",
	DocumentNotFound = "DocumentNotFound",
	NotValidBankIdData = "NotValidBankIdData",
	BankIdDataNotFound = "BankIdDataNotFound",
	PersonalIdentificationNumberMismatch = "PersonalIdentificationNumberMismatch",
	DocumentIndefiniteExpiryDateNotAllowed = "DocumentIndefiniteExpiryDateNotAllowed",
	DipUnavailable = "DipUnavailable",
	DistributionNotAllowed = "DistributionNotAllowed"
}

export type StatusCommandResult = {
	status: StatusCommandResultStatus;
};

export enum StatusCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ContractPaymentInstructionsQueryResult = {
	isNoticeToFirstPaymentVisible: boolean;
	defaultBankAccount?: DefaultBankAccountDto | null;
	contractPaymentMessage?: ContractPaymentMessageDto | null;
	paymentInstructions: PaymentInstructionDto[];
	documents?: ContractDocumentDto[] | null;
	status: ContractPaymentInstructionsQueryResultStatus;
	error: ApiCallError;
};

export type DefaultBankAccountDto = {
	accountNumber?: string | null;
	bankCode?: string | null;
	iban?: string | null;
	bankCountry?: string | null;
	currency: CurrencyCode;
};

export type ContractPaymentMessageDto = {
	contractNo?: string | null;
	firstName?: string | null;
	lastName?: string | null;
};

export type PaymentInstructionDto = {
	isin?: string | null;
	productID?: number | null;
	bankAccount?: string | null;
	iban?: string | null;
	currency: CurrencyCode;
	vs?: string | null;
	ss?: string | null;
	employerSpecificSymbol?: string | null;
	isSingleInvestment: boolean;
};

export enum ContractPaymentInstructionsQueryResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	ContractAccessDenied = "ContractAccessDenied",
	CalculateContractAmountFailed = "CalculateContractAmountFailed",
	ClientWithoutBankAccount = "ClientWithoutBankAccount",
	ContractWithoutClient = "ContractWithoutClient",
	UnknownProduct = "UnknownProduct",
	DocumentsRetrievalFailed = "DocumentsRetrievalFailed",
	Fail = "Fail"
}

export type ContractTransferDipCommandResult = {
	status: ContractTransferDipCommandResultStatus;
	requestHash?: string | null;
	signatureHash?: string | null;
	error?: ApiCallError | null;
};

export enum ContractTransferDipCommandResultStatus {
	Success = "Success",
	ContractDipTransferInformationRequired = "ContractDipTransferInformationRequired",
	ClientNotFound = "ClientNotFound",
	ContractNotFound = "ContractNotFound",
	ContractAlreadyTransferred = "ContractAlreadyTransferred",
	ContractTypeCodeNotAllowed = "ContractTypeCodeNotAllowed",
	DipUnavailable = "DipUnavailable",
	ContractAccessDenied = "ContractAccessDenied",
	Fail = "Fail"
}

export type ContractTransferDipDto = {
	contractID: number;
	isBiometry: boolean;
};

export type ContractTransferDipContractPreviewCommandResult = {
	status: ContractTransferDipContractPreviewCommandResultStatus;
	base64Data?: string | null;
	error?: ApiCallError | null;
};

export enum ContractTransferDipContractPreviewCommandResultStatus {
	Success = "Success",
	RequestHashRequired = "RequestHashRequired",
	FileNotFound = "FileNotFound",
	ContractAccessDenied = "ContractAccessDenied",
	Fail = "Fail"
}

export type ContractTransferDipContractPreviewDto = {
	contractID: number;
	requestHash?: string | null;
};

export type ContractCreateBankConnectionCommandResult = {
	status: ContractCreateBankConnectionCommandResultStatus;
	requestHash?: string | null;
	signatureHash?: string | null;
	error?: ApiCallError | null;
};

export enum ContractCreateBankConnectionCommandResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	ContractAccessDenied = "ContractAccessDenied",
	ContractNotFound = "ContractNotFound",
	ContractPortfolioNotFound = "ContractPortfolioNotFound",
	DocumentsNotFound = "DocumentsNotFound",
	Fail = "Fail"
}

export type ContractCreateBankConnectionRequest = {
	iban?: string | null;
	bankCode?: string | null;
	bankNumber?: string | null;
	currency: CurrencyCode;
	isBiometry: boolean;
};

export type ContractDeactivateBankConnectionCommandResult = {
	status: ContractDeactivateBankConnectionCommandResultStatus;
	requestHash?: string | null;
	signatureHash?: string | null;
	error?: ApiCallError | null;
};

export enum ContractDeactivateBankConnectionCommandResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	ContractAccessDenied = "ContractAccessDenied",
	ContractNotFound = "ContractNotFound",
	ContractPortfolioNotFound = "ContractPortfolioNotFound",
	Fail = "Fail"
}

export type ContractDeactivateBankConnectionRequest = {
	bankAccountID: number;
	isBiometry: boolean;
};

export type ContractExchangeAssetsCommandResult = {
	status: ContractExchangeAssetsCommandResultStatus;
	signatureHash?: string | null;
	error?: ApiCallError | null;
};

export enum ContractExchangeAssetsCommandResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	ContractNotFound = "ContractNotFound",
	ExchangeAssetsNotAllowed = "ExchangeAssetsNotAllowed",
	ProductFeeRateNotFound = "ProductFeeRateNotFound",
	ProductNotFound = "ProductNotFound",
	DocumentsNotFound = "DocumentsNotFound",
	PortfolioItemNotFound = "PortfolioItemNotFound",
	PortfolioItemAssetsNotFound = "PortfolioItemAssetsNotFound",
	Fail = "Fail"
}

export type ContractExchangeAssetsRequest = {
	sourceIsin: string;
	targetIsin: string;
	type: ContractExchangeAssetsType;
	method: ContractExchangeAssetsMethod;
	amountOrPieces: number;
	isExchangeAll: boolean;
	isBiometry: boolean;
};

export enum ContractExchangeAssetsType {
	Single = "Single",
	Periodical = "Periodical"
}

export enum ContractExchangeAssetsMethod {
	Pieces = "Pieces",
	Amount = "Amount"
}

export type ContractEditCommandResult = {
	status: ContractEditCommandResultStatus;
	error?: ApiCallError | null;
};

export enum ContractEditCommandResultStatus {
	Success = "Success",
	ContractAccessDenied = "ContractAccessDenied",
	Fail = "Fail"
}

export type ContractEditRequest = {
	name?: string | null;
	purposeCategory?: number | null;
};

export type SaveContractDraftCommandResult = {
	status: SaveContractDraftCommandResultStatus;
	error: ApiCallError;
};

export enum SaveContractDraftCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ContractDraftDto = {
	jsonData: string;
	isContractCreated: boolean;
	isContractSigned: boolean;
};

export type CreateContractDraftDocumentCommandResult = {
	status: CreateContractDraftDocumentCommandResultStatus;
	error: ApiCallError;
};

export enum CreateContractDraftDocumentCommandResultStatus {
	Success = "Success",
	ContractDraftNotFound = "ContractDraftNotFound",
	DocumentMimeTypeMismatch = "DocumentMimeTypeMismatch",
	Fail = "Fail"
}

export type ContractDraftDocumentDto = {
	documentGuid: string;
	data: string;
};

export type DocumentDownloadQueryResult = {
	status: DocumentDownloadQueryResultStatus;
	document?: DocumentResult | null;
	error?: ApiCallError | null;
};

export enum DocumentDownloadQueryResultStatus {
	Success = "Success",
	ContractAccessDenied = "ContractAccessDenied",
	ContractDocumentAccessDenied = "ContractDocumentAccessDenied",
	Fail = "Fail"
}

export type DocumentResult = {
	data: string;
	fileName: string;
	fileType: string;
	mimeType?: string | null;
};

export type DocumentListSectionDto = {
	documentType: AppDocumentType;
	documents: DocumentListItemDto[];
};

export enum AppDocumentType {
	GeneralTermsAndConditions = "GeneralTermsAndConditions",
	FundStatutes = "FundStatutes",
	KeyInvestorInformation = "KeyInvestorInformation",
	TariffsAndFees = "TariffsAndFees",
	FormsAndApplications = "FormsAndApplications"
}

export type DocumentListItemDto = {
	appDocumentID: number;
	isin: string;
	documentName: string;
	url: string;
	documentType: AppDocumentType;
};

export type GetExchangeRateQueryResult = {
	status: GetExchangeRateQueryResultStatus;
	result?: ExchangeRateDto | null;
	error?: ApiCallError | null;
};

export enum GetExchangeRateQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ExchangeRateDto = {
	rate?: number | null;
};

export type FcmTokenStatusQueryResult = {
	status: FcmTokenStatusQueryResultStatus;
	isNotificationEnabled: boolean;
	isNotificationPermissionPending: boolean;
	error?: ApiCallError | null;
};

export enum FcmTokenStatusQueryResultStatus {
	Success = "Success",
	TokenNotFound = "TokenNotFound",
	Fail = "Fail"
}

export type RegisterFcmTokenCommandResult = {
	status: RegisterFcmTokenCommandResultStatus;
	error?: ApiCallError | null;
};

export enum RegisterFcmTokenCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type RegisterFcmTokenRequest = {
	token: string;
	isNotificationEnabled: boolean;
	isNotificationPermissionGranted: boolean;
};

export type GetHealthStatusQueryResult = {
	healthStatus: HealthStatus;
};

export enum HealthStatus {
	Operational = "Operational",
	Maintenance = "Maintenance"
}

export type ErrorWebLogCommand = {
	dateCreated: string;
	webLogRequests: WebLogRequest[];
};

export type WebLogRequest = {
	dateCreated: string;
	error: string;
	additionalInfo?: string | null;
	reduxState?: string | null;
};

export type QrCodeDto = {
	data?: string | null;
};

export type GenerateQrCodeCommand = {
	iban: string;
	variableCode: string;
	constantSymbol: string;
	price: number;
	messageForPayment: string;
	invoiceID: string;
	specificSymbol: string;
	contractID: number;
};

export type ProductListQueryResult = {
	status: ProductListQueryResultStatus;
	products?: ProductItemDto[] | null;
	error?: ApiCallError | null;
};

export enum ProductListQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ProductItemDto = {
	productID?: number | null;
	contractTypeCode: ContractTypeCode;
	classCode?: string | null;
	className?: string | null;
	order?: number | null;
	singleMinInvestment?: number | null;
	singleMaxInvestment?: number | null;
	singleDefaultInvestment?: number | null;
	periodicalMinInvestment?: number | null;
	periodicalMaxInvestment?: number | null;
	periodicalDefaultInvestment?: number | null;
	currency: CurrencyCode;
	color?: string | null;
	hasPeriodicalRedemption?: boolean | null;
	minPerformance?: number | null;
	maxPerformance?: number | null;
	productCode?: string | null;
	isin?: string | null;
	productName?: string | null;
	productSingleSS?: string | null;
	productPeriodicalSS?: string | null;
	isDipAllowed?: boolean | null;
	employerSpecifiedSymbol?: EmployerSpecificSymbol | null;
	isQualifiedInvestorFund?: any | null;
};

export type EmployerSpecificSymbol = {
	single?: string | null;
	periodical?: string | null;
};

export type ProductTileListQueryResult = {
	status: ProductTileListQueryResultStatus;
	productTiles?: ProductTileDto[] | null;
	error?: ApiCallError | null;
};

export enum ProductTileListQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ProductTileDto = {
	productID?: number | null;
	code?: string | null;
	name?: string | null;
	order?: number | null;
	isDipAllowed?: boolean | null;
	items: ProductItemDto[];
};

export type ProductSectionItemDto = {
	productSectionItemID: number;
	isin: string;
	sectionNo: number;
	rank: number;
	languageCode: string;
	value: string;
	documentUrl: string;
	imageName: string;
};

export type ProductFeeRateCalculationQueryResult = {
	status: ProductFeeRateCalculationQueryResultStatus;
	feeRate?: number | null;
	error?: ApiCallError | null;
};

export enum ProductFeeRateCalculationQueryResultStatus {
	Success = "Success",
	ContractAccessDenied = "ContractAccessDenied",
	ContractNotFound = "ContractNotFound",
	ProductNotFound = "ProductNotFound",
	Fail = "Fail"
}

export enum ProductFeeRateCalculationType {
	Default = "Default",
	ExchangeAssets = "ExchangeAssets"
}

export type SendPushNotificationsResponse = {
	status: SendPushNotificationsResponseStatus;
	error?: ApiCallError | null;
};

export enum SendPushNotificationsResponseStatus {
	Success = "Success",
	AlreadySent = "AlreadySent",
	Fail = "Fail"
}

export type SendPushNotificationsRequest = {
	title: string;
	body?: string | null;
	imageUrl?: string | null;
	data: {[key: string | number]: string};
	recipients: Recipient2[];
};

export type Recipient2 = {
	login: string;
};

export type GetMobAppSettingsQueryResult = {
	status: GetMobAppSettingsQueryResultStatus;
	settings?: MobAppSettings | null;
	error?: ApiCallError | null;
};

export enum GetMobAppSettingsQueryResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type MobAppSettings = {
	isBySquareQRCodeEnabled: boolean;
	isDIPEnabled: boolean;
	supportedAndroidVersion: string;
	supportedAppleVersion: string;
	isTransferInOneDipContractEnabled: boolean;
};

export type InitializeSmsSignatureCommandResult = {
	status: InitializeSmsSignatureCommandResultStatus;
	error?: ApiCallError | null;
};

export enum InitializeSmsSignatureCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type InitializeSmsSignatureCommand = {
	signatureHash: string;
};

export type SignContractCommandResult = {
	signInResult?: SignInResult | null;
	status: SignContractCommandResultStatus;
	error?: ApiCallError | null;
};

export enum SignContractCommandResultStatus {
	Success = "Success",
	ContractFileNotExists = "ContractFileNotExists",
	Fail = "Fail"
}

export type SmsSignDto = {
	signatureHash: string;
	code?: string | null;
	contractID?: number | null;
};

export type InitializeBiometricSignatureCommandResult = {
	status: InitializeBiometricSignatureCommandResultStatus;
	challenge?: string | null;
	error?: ApiCallError | null;
};

export enum InitializeBiometricSignatureCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type InitializeBiometricSignatureCommand = {
	signatureHash: string;
};

export type SignWithBiometricsCommandResult = {
	status: SignWithBiometricsCommandResultStatus;
	error?: ApiCallError | null;
	signInResult?: SignInResult | null;
};

export enum SignWithBiometricsCommandResultStatus {
	Success = "Success",
	ContractFileNotExists = "ContractFileNotExists",
	Fail = "Fail"
}

export type BiometricSignatureRequest = {
	challenge: string;
	signedChallenge: string;
	login: string;
	publicKey: string;
	contractID?: number | null;
	signatureHash: string;
};

export type SendVerificationCodeCommandResult = {
	status: SendVerificationCodeCommandResultStatus;
	verificationHash?: string | null;
	error?: ApiCallError | null;
};

export enum SendVerificationCodeCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type SendVerificationCodeCommand = {
	clientVerificationHash: string;
};

export type ValidateVerificationCodeCommand = {
	code: string;
	clientVerificationHash: string;
};

export type CreateLogResult = {
	status: CreateLogResultStatus;
};

export enum CreateLogResultStatus {
	Success = "Success",
	Failure = "Failure"
}

export type LogDto = {
	sessionID: string;
	userID?: string | null;
	dateCreatedUtc: string;
	channelID?: string | null;
	url: string;
	event: string;
	action?: string | null;
	category: string;
	tag?: string | null;
	value?: string | null;
};

export type UserContactInfoQueryResult = {
	status: UserContactInfoQueryResultStatus;
	contactInfo?: UserContactInfo | null;
	error?: ApiCallError | null;
};

export enum UserContactInfoQueryResultStatus {
	Success = "Success",
	ClientNotFound = "ClientNotFound",
	Fail = "Fail"
}

export type UserContactInfo = {
	user: ContactInfo;
	client?: ContactInfo | null;
	hasAnyContract?: boolean | null;
};

export type ContactInfo = {
	phone?: string | null;
	isPhoneVerified?: boolean | null;
	email?: string | null;
	isEmailVerified?: boolean | null;
};

const API_URL = import.meta.env.VITE_API_URL;

export const getAccessControlDistributions = (headers = new Headers()): 
	Promise<FetchResponse<DistributionsAccessControlQueryResult>> => {
	return apiGet(`${API_URL}/api/access-control/distributions`, headers, {});
}

export const postAttachments = (requestContract: CreateOrUpdateAttachmentRequest, headers = new Headers()): 
	Promise<FetchResponse<CreateOrUpdateAttachmentCommandResult>> => 
	apiPost(`${API_URL}/api/attachments`, requestContract, headers);

export const deleteAttachmentsAttachmentGuid = (attachmentGuid: string, headers = new Headers()): 
	Promise<FetchResponse<DeleteAttachmentCommandResult>> => {
	return apiDelete(`${API_URL}/api/attachments/${attachmentGuid}`, headers, {});
}

export const postAuthSignUpBiometricsChallenge = (headers = new Headers()): 
	Promise<FetchResponse<SignUpBiometricsChallengeCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-up/biometrics/challenge`, {}, headers);

export const postAuthSignUpBiometrics = (requestContract: SignUpBiometricsRequestDto, headers = new Headers()): 
	Promise<FetchResponse<SignUpBiometricsCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-up/biometrics`, requestContract, headers);

export const postAuthSignInBiometricsChallenge = (requestContract: SignInBiometricsChallengeCommand, headers = new Headers()): 
	Promise<FetchResponse<SignInBiometricsChallengeCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-in/biometrics/challenge`, requestContract, headers);

export const postAuthSignInBiometrics = (requestContract: SignInBiometricsCommand, headers = new Headers()): 
	Promise<FetchResponse<SignInCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-in/biometrics`, requestContract, headers);

export const deleteAuthBiometricsId = (id: string, headers = new Headers()): 
	Promise<FetchResponse<DeleteBiometricsCommandResult>> => {
	return apiDelete(`${API_URL}/api/auth/biometrics/${id}`, headers, {});
}

export const postAuthSignUp = (requestContract: SignUpCommand, headers = new Headers()): 
	Promise<FetchResponse<SignUpCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-up`, requestContract, headers);

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()): 
	Promise<FetchResponse<SignInCommandResult>> => 
	apiPost(`${API_URL}/api/auth/sign-in`, requestContract, headers);

export const deleteAuthTokenRevoke = (headers = new Headers()): 
	Promise<FetchResponse<RevokeTokenCommandResult>> => {
	return apiDelete(`${API_URL}/api/auth/token/revoke`, headers, {});
}

export const postAuthVerifyEmail = (requestContract: EmailVerificationCommand, headers = new Headers()): 
	Promise<FetchResponse<EmailVerificationCommandResult>> => 
	apiPost(`${API_URL}/api/auth/verify-email`, requestContract, headers);

export const postAuthResendVerificationEmail = (requestContract: ResendVerificationEmailCommand, headers = new Headers()): 
	Promise<FetchResponse<ResendVerificationEmailCommandResult>> => 
	apiPost(`${API_URL}/api/auth/resend-verification-email`, requestContract, headers);

export const postAuthPasswordReset = (requestContract: ResetPasswordCommand, headers = new Headers()): 
	Promise<FetchResponse<ResetPasswordCommandResult>> => 
	apiPost(`${API_URL}/api/auth/password/reset`, requestContract, headers);

export const postAuthPasswordSet = (requestContract: SetPasswordCommand, headers = new Headers()): 
	Promise<FetchResponse<SetPasswordCommandResult>> => 
	apiPost(`${API_URL}/api/auth/password/set`, requestContract, headers);

export const putAuthPassword = (requestContract: ChangePasswordRequestDto, headers = new Headers()): 
	Promise<FetchResponse<ChangePasswordCommandResult>> => 
	apiPut(`${API_URL}/api/auth/password`, requestContract, headers);

export const postAuthTokenProlong = (headers = new Headers()): 
	Promise<FetchResponse<ProlongTokenCommandResult>> => 
	apiPost(`${API_URL}/api/auth/token/prolong`, {}, headers);

export const getBankIdAuthVerify = (ProcessID?: string, ProcessAudience?: BankIDProcessAudience, AccessToken?: string, TrackingSessionID?: string, headers = new Headers()): 
	Promise<FetchResponse<ProcessBankIDVerificationCommandResult>> => {
	const queryParams = {
		"ProcessID": ProcessID		,
"ProcessAudience": ProcessAudience		,
"AccessToken": AccessToken		,
"TrackingSessionID": TrackingSessionID
	}
	return apiGet(`${API_URL}/api/bank-id/auth/verify`, headers, queryParams);
}

export const getBankIdAuthNativeCallback = (ProcessID?: string, ProcessAudience?: BankIDProcessAudience, AccessToken?: string | undefined | null, Error?: string | undefined | null, ErrorDescription?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	const queryParams = {
		"ProcessID": ProcessID		,
"ProcessAudience": ProcessAudience		,
"AccessToken": AccessToken		,
"Error": Error		,
"ErrorDescription": ErrorDescription
	}
	return apiGet(`${API_URL}/api/bank-id/auth/native-callback`, headers, queryParams);
}

export const postBankIdAuthProcessesInit = (requestContract: InitializeBankIDVerificationProcessRequest, headers = new Headers()): 
	Promise<FetchResponse<InitializeBankIDVerificationProcessCommandResult>> => 
	apiPost(`${API_URL}/api/bank-id/auth/processes/init`, requestContract, headers);

export const getBankIdAuthProcessesProcessID = (processID: string, ProcessAudience?: BankIDProcessAudience, TrackingSessionID?: string, headers = new Headers()): 
	Promise<FetchResponse<GetBankIDVerificationProcessQueryResult>> => {
	const queryParams = {
		"ProcessAudience": ProcessAudience		,
"TrackingSessionID": TrackingSessionID
	}
	return apiGet(`${API_URL}/api/bank-id/auth/processes/${processID}`, headers, queryParams);
}

export const getCacheRefresh = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/cache/refresh`, headers, {});
}

export const getV1CalculationsPension = (Age: number, PensionAge?: number | undefined | null, Gender?: Gender, StatePensionStartYear?: number, EstimatedIncome?: number, RecommendedMonthlyPortfolioPension?: number | undefined | null, NumberOfChildren?: number, IsStatePensionsIncluded?: boolean, IsInflationIncluded?: boolean, PensionYears?: number | undefined | null, MonthlyInvestment?: number | undefined | null, EmployerMonthlyContribution?: number, InvestmentStrategy?: InvestmentStrategy, headers = new Headers()): 
	Promise<FetchResponse<CalculatePensionResponse>> => {
	const queryParams = {
		"Age": Age		,
"PensionAge": PensionAge		,
"Gender": Gender		,
"StatePensionStartYear": StatePensionStartYear		,
"EstimatedIncome": EstimatedIncome		,
"RecommendedMonthlyPortfolioPension": RecommendedMonthlyPortfolioPension		,
"NumberOfChildren": NumberOfChildren		,
"IsStatePensionsIncluded": IsStatePensionsIncluded		,
"IsInflationIncluded": IsInflationIncluded		,
"PensionYears": PensionYears		,
"MonthlyInvestment": MonthlyInvestment		,
"EmployerMonthlyContribution": EmployerMonthlyContribution		,
"InvestmentStrategy": InvestmentStrategy
	}
	return apiGet(`${API_URL}/api/v1/calculations/pension`, headers, queryParams);
}

export const postV1Campaigns = (requestContract: CreateCampaignRequest, headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/v1/campaigns`, requestContract, headers);

export const postV1CampaignsChannelIDNotification = (requestContract: SendCampaignNotificationsRequest, channelID: string, headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/v1/campaigns/${channelID}/notification`, requestContract, headers);

export const getCampaigns = (headers = new Headers()): 
	Promise<FetchResponse<UserCampaignListQueryResult>> => {
	return apiGet(`${API_URL}/api/campaigns`, headers, {});
}

export const putCampaignsChannelID = (requestContract: HideUserCampaignRequest, channelID: string, headers = new Headers()): 
	Promise<FetchResponse<HideUserCampaignCommandResult>> => 
	apiPut(`${API_URL}/api/campaigns/${channelID}`, requestContract, headers);

export const getClient = (headers = new Headers()): 
	Promise<FetchResponse<ClientDto>> => {
	return apiGet(`${API_URL}/api/client`, headers, {});
}

export const putClientTermsAndServices = (requestContract: TermsOfConditionAcceptanceDto, headers = new Headers()): 
	Promise<FetchResponse<UpdateClientTermsAndServicesCommandResult>> => 
	apiPut(`${API_URL}/api/client/terms-and-services`, requestContract, headers);

export const putClientNotificationStatus = (requestContract: ClientNotificationStatusDto, headers = new Headers()): 
	Promise<FetchResponse<UpdateClientNotificationStatusCommandResult>> => 
	apiPut(`${API_URL}/api/client/notification-status`, requestContract, headers);

export const putClientAnalyticsStatus = (requestContract: ClientAnalyticsStatusDto, headers = new Headers()): 
	Promise<FetchResponse<UpdateClientAnalyticsStatusCommandResult>> => 
	apiPut(`${API_URL}/api/client/analytics-status`, requestContract, headers);

export const getClientDealers = (headers = new Headers()): 
	Promise<FetchResponse<DealerDto[]>> => {
	return apiGet(`${API_URL}/api/client/dealers`, headers, {});
}

export const postClientContactConfirmation = (requestContract: ClientSendContactConfirmationDto, headers = new Headers()): 
	Promise<FetchResponse<ClientSendContactConfirmationCommandResult>> => 
	apiPost(`${API_URL}/api/client/contact-confirmation`, requestContract, headers);

export const putClientContactConfirmation = (requestContract: ClientVerifyContactConfirmationCommand, headers = new Headers()): 
	Promise<FetchResponse<ClientVerifyContactConfirmationCommandResult>> => 
	apiPut(`${API_URL}/api/client/contact-confirmation`, requestContract, headers);

export const getClientExistence = (personalIdentificationNumber?: string, citizenship?: string, headers = new Headers()): 
	Promise<FetchResponse<CheckClientExistsQueryResult>> => {
	const queryParams = {
		"personalIdentificationNumber": personalIdentificationNumber		,
"citizenship": citizenship
	}
	return apiGet(`${API_URL}/api/client/existence`, headers, queryParams);
}

export const postClientPersonalData = (requestContract: UpdateClientPersonalDataRequest, headers = new Headers()): 
	Promise<FetchResponse<UpdateClientPersonalDataCommandResult>> => 
	apiPost(`${API_URL}/api/client/personal-data`, requestContract, headers);

export const postClientAml = (requestContract: UpdateAmlRequest, headers = new Headers()): 
	Promise<FetchResponse<UpdateAmlCommandResult>> => 
	apiPost(`${API_URL}/api/client/aml`, requestContract, headers);

export const getClientDocuments = (headers = new Headers()): 
	Promise<FetchResponse<ClientDocumentsQueryResult>> => {
	return apiGet(`${API_URL}/api/client/documents`, headers, {});
}

export const getClientVerificationType = (headers = new Headers()): 
	Promise<FetchResponse<ClientVerificationTypeQueryResult>> => {
	return apiGet(`${API_URL}/api/client/verification/type`, headers, {});
}

export const getCodeListBanks = (country?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<BankListQueryResult>> => {
	const queryParams = {
		"country": country
	}
	return apiGet(`${API_URL}/api/code-list/banks`, headers, queryParams);
}

export const getCodeListCountries = (headers = new Headers()): 
	Promise<FetchResponse<CountryListQueryResult>> => {
	return apiGet(`${API_URL}/api/code-list/countries`, headers, {});
}

export const getCodeListBusinessSector = (headers = new Headers()): 
	Promise<FetchResponse<BusinessSectorListQueryResult>> => {
	return apiGet(`${API_URL}/api/code-list/business-sector`, headers, {});
}

export const getCodeListCurrencies = (headers = new Headers()): 
	Promise<FetchResponse<CodeListItemDto[]>> => {
	return apiGet(`${API_URL}/api/code-list/currencies`, headers, {});
}

export const getContractDashboard = (headers = new Headers()): 
	Promise<FetchResponse<ContractDashboardQueryResult>> => {
	return apiGet(`${API_URL}/api/contract/dashboard`, headers, {});
}

export const getContractContractID = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractOverviewQueryResult>> => {
	return apiGet(`${API_URL}/api/contract/${contractID}`, headers, {});
}

export const getContractTransactionsContractID = (contractID: number, DateFrom?: string | undefined | null, DateTo?: string | undefined | null, IncludePurchases?: boolean, IncludeRedemptions?: boolean, IncludeAssetsExchanges?: boolean, IncludeAssetsTransfers?: boolean, PageNumber?: number, PageSize?: number, headers = new Headers()): 
	Promise<FetchResponse<ContractTransactionsQueryResult>> => {
	const queryParams = {
		"DateFrom": DateFrom		,
"DateTo": DateTo		,
"IncludePurchases": IncludePurchases		,
"IncludeRedemptions": IncludeRedemptions		,
"IncludeAssetsExchanges": IncludeAssetsExchanges		,
"IncludeAssetsTransfers": IncludeAssetsTransfers		,
"PageNumber": PageNumber		,
"PageSize": PageSize
	}
	return apiGet(`${API_URL}/api/contract/transactions/${contractID}`, headers, queryParams);
}

export const getContractCashFlowContractID = (contractID: number, DateFrom?: string | undefined | null, DateTo?: string | undefined | null, IncludeFees?: boolean, IncludeDeposits?: boolean, IncludeWithdrawals?: boolean, PageNumber?: number, PageSize?: number, headers = new Headers()): 
	Promise<FetchResponse<ContractCashFlowQueryResult>> => {
	const queryParams = {
		"DateFrom": DateFrom		,
"DateTo": DateTo		,
"IncludeFees": IncludeFees		,
"IncludeDeposits": IncludeDeposits		,
"IncludeWithdrawals": IncludeWithdrawals		,
"PageNumber": PageNumber		,
"PageSize": PageSize
	}
	return apiGet(`${API_URL}/api/contract/cash-flow/${contractID}`, headers, queryParams);
}

export const getContractBankAccountsContractID = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<BankAccountItemDto[]>> => {
	return apiGet(`${API_URL}/api/contract/bank-accounts/${contractID}`, headers, {});
}

export const getContractDealerContractID = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractDealerDto>> => {
	return apiGet(`${API_URL}/api/contract/dealer/${contractID}`, headers, {});
}

export const getContractDetailContractID = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractDetailDto>> => {
	return apiGet(`${API_URL}/api/contract/detail/${contractID}`, headers, {});
}

export const getContractUnsettledTradesContractID = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractUnsettledTradesQueryResult>> => {
	return apiGet(`${API_URL}/api/contract/unsettled-trades/${contractID}`, headers, {});
}

export const postContractInvestUninvestedDeposits = (requestContract: InvestUninvestedDepositsDto, headers = new Headers()): 
	Promise<FetchResponse<InvestUninvestedDepositsCommandResult>> => 
	apiPost(`${API_URL}/api/contract/invest-uninvested-deposits`, requestContract, headers);

export const postContractRedemption = (requestContract: ContractRedemptionCommand, headers = new Headers()): 
	Promise<FetchResponse<ContractRedemptionCommandCommandResult>> => 
	apiPost(`${API_URL}/api/contract/redemption`, requestContract, headers);

export const postContractPreview = (requestContract: NewContractDto, headers = new Headers()): 
	Promise<FetchResponse<ContractPreviewCommandResult>> => 
	apiPost(`${API_URL}/api/contract/preview`, requestContract, headers);

export const postContract = (requestContract: NewContractDto, headers = new Headers()): 
	Promise<FetchResponse<CreateContractCommandResult>> => 
	apiPost(`${API_URL}/api/contract`, requestContract, headers);

export const deleteContractContractIDInstruction = (contractID: number, headers = new Headers()): 
	Promise<FetchResponse<StatusCommandResult>> => {
	return apiDelete(`${API_URL}/api/contract/${contractID}/instruction`, headers, {});
}

export const getContractPaymentInstructions = (contractID?: number, isin?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<ContractPaymentInstructionsQueryResult>> => {
	const queryParams = {
		"contractID": contractID		,
"isin": isin
	}
	return apiGet(`${API_URL}/api/contract/payment-instructions`, headers, queryParams);
}

export const postContractTransferDip = (requestContract: ContractTransferDipDto, headers = new Headers()): 
	Promise<FetchResponse<ContractTransferDipCommandResult>> => 
	apiPost(`${API_URL}/api/contract/transfer/dip`, requestContract, headers);

export const postContractTransferDipContractPreview = (requestContract: ContractTransferDipContractPreviewDto, headers = new Headers()): 
	Promise<FetchResponse<ContractTransferDipContractPreviewCommandResult>> => 
	apiPost(`${API_URL}/api/contract/transfer/dip/contract/preview`, requestContract, headers);

export const postContractContractIDBankConnections = (requestContract: ContractCreateBankConnectionRequest, contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractCreateBankConnectionCommandResult>> => 
	apiPost(`${API_URL}/api/contract/${contractID}/bank-connections`, requestContract, headers);

export const postContractContractIDBankConnectionsDeactivate = (requestContract: ContractDeactivateBankConnectionRequest, contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractDeactivateBankConnectionCommandResult>> => 
	apiPost(`${API_URL}/api/contract/${contractID}/bank-connections/deactivate`, requestContract, headers);

export const postContractContractIDExchangeAssets = (requestContract: ContractExchangeAssetsRequest, contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractExchangeAssetsCommandResult>> => 
	apiPost(`${API_URL}/api/contract/${contractID}/exchange-assets`, requestContract, headers);

export const postContractContractIDEdit = (requestContract: ContractEditRequest, contractID: number, headers = new Headers()): 
	Promise<FetchResponse<ContractEditCommandResult>> => 
	apiPost(`${API_URL}/api/contract/${contractID}/edit`, requestContract, headers);

export const getContractDraft = (headers = new Headers()): 
	Promise<FetchResponse<ContractDraftDto>> => {
	return apiGet(`${API_URL}/api/contract-draft`, headers, {});
}

export const postContractDraft = (requestContract: ContractDraftDto, headers = new Headers()): 
	Promise<FetchResponse<SaveContractDraftCommandResult>> => 
	apiPost(`${API_URL}/api/contract-draft`, requestContract, headers);

export const postContractDraftDocument = (requestContract: ContractDraftDocumentDto, headers = new Headers()): 
	Promise<FetchResponse<CreateContractDraftDocumentCommandResult>> => 
	apiPost(`${API_URL}/api/contract-draft/document`, requestContract, headers);

export const getContractDraftDocumentDocumentGuid = (documentGuid: string, headers = new Headers()): 
	Promise<FetchResponse<CreateContractDraftDocumentCommandResult>> => {
	return apiGet(`${API_URL}/api/contract-draft/document/${documentGuid}`, headers, {});
}

export const getDocumentsFileName = (fileName: string, contractID?: number | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<DocumentDownloadQueryResult>> => {
	const queryParams = {
		"contractID": contractID
	}
	return apiGet(`${API_URL}/api/documents/${fileName}`, headers, queryParams);
}

export const getDocuments = (headers = new Headers()): 
	Promise<FetchResponse<DocumentListSectionDto[]>> => {
	return apiGet(`${API_URL}/api/documents`, headers, {});
}

export const getExchangeRate = (currencyFrom?: string | undefined | null, currencyTo?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<GetExchangeRateQueryResult>> => {
	const queryParams = {
		"currencyFrom": currencyFrom		,
"currencyTo": currencyTo
	}
	return apiGet(`${API_URL}/api/exchange-rate`, headers, queryParams);
}

export const getFcmTokensTokenStatus = (token: string, headers = new Headers()): 
	Promise<FetchResponse<FcmTokenStatusQueryResult>> => {
	return apiGet(`${API_URL}/api/fcm/tokens/${token}/status`, headers, {});
}

export const postFcmTokens = (requestContract: RegisterFcmTokenRequest, headers = new Headers()): 
	Promise<FetchResponse<RegisterFcmTokenCommandResult>> => 
	apiPost(`${API_URL}/api/fcm/tokens`, requestContract, headers);

export const getHealthStatus = (headers = new Headers()): 
	Promise<FetchResponse<GetHealthStatusQueryResult>> => {
	return apiGet(`${API_URL}/api/health/status`, headers, {});
}

export const postLogError = (requestContract: ErrorWebLogCommand, headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/log/Error`, requestContract, headers);

export const postPaymentQrCode = (requestContract: GenerateQrCodeCommand, headers = new Headers()): 
	Promise<FetchResponse<QrCodeDto>> => 
	apiPost(`${API_URL}/api/payment/qr-code`, requestContract, headers);

export const getProductList = (contractTypeCode?: ContractTypeCode | undefined | null, isDip?: boolean | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<ProductListQueryResult>> => {
	const queryParams = {
		"contractTypeCode": contractTypeCode		,
"isDip": isDip
	}
	return apiGet(`${API_URL}/api/product/list`, headers, queryParams);
}

export const getProductTilesList = (headers = new Headers()): 
	Promise<FetchResponse<ProductTileListQueryResult>> => {
	return apiGet(`${API_URL}/api/product/tiles/list`, headers, {});
}

export const getProductSectionsIsin = (isin: string, headers = new Headers()): 
	Promise<FetchResponse<ProductSectionItemDto[]>> => {
	return apiGet(`${API_URL}/api/product/sections/${isin}`, headers, {});
}

export const getProductFeeRateCalculation = (SourceContractID?: number | undefined | null, TargetContractID?: number | undefined | null, SourceContractTypeCode?: ContractTypeCode | undefined | null, TargetContractTypeCode?: ContractTypeCode | undefined | null, SourceIsin?: string, TargetIsin?: string | undefined | null, AmountOrPieces?: number, IsPieces?: boolean, CalculationType?: ProductFeeRateCalculationType, headers = new Headers()): 
	Promise<FetchResponse<ProductFeeRateCalculationQueryResult>> => {
	const queryParams = {
		"SourceContractID": SourceContractID		,
"TargetContractID": TargetContractID		,
"SourceContractTypeCode": SourceContractTypeCode		,
"TargetContractTypeCode": TargetContractTypeCode		,
"SourceIsin": SourceIsin		,
"TargetIsin": TargetIsin		,
"AmountOrPieces": AmountOrPieces		,
"IsPieces": IsPieces		,
"CalculationType": CalculationType
	}
	return apiGet(`${API_URL}/api/product-fee-rate/calculation`, headers, queryParams);
}

export const postV1PushNotificationsSend = (requestContract: SendPushNotificationsRequest, headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/v1/push-notifications/send`, requestContract, headers);

export const getSettings = (headers = new Headers()): 
	Promise<FetchResponse<GetMobAppSettingsQueryResult>> => {
	return apiGet(`${API_URL}/api/settings`, headers, {});
}

export const postSignatureSms = (requestContract: InitializeSmsSignatureCommand, headers = new Headers()): 
	Promise<FetchResponse<InitializeSmsSignatureCommandResult>> => 
	apiPost(`${API_URL}/api/signature/sms`, requestContract, headers);

export const putSignatureSms = (requestContract: SmsSignDto, headers = new Headers()): 
	Promise<FetchResponse<SignContractCommandResult>> => 
	apiPut(`${API_URL}/api/signature/sms`, requestContract, headers);

export const postSignatureBiometrics = (requestContract: InitializeBiometricSignatureCommand, headers = new Headers()): 
	Promise<FetchResponse<InitializeBiometricSignatureCommandResult>> => 
	apiPost(`${API_URL}/api/signature/biometrics`, requestContract, headers);

export const putSignatureBiometrics = (requestContract: BiometricSignatureRequest, headers = new Headers()): 
	Promise<FetchResponse<SignWithBiometricsCommandResult>> => 
	apiPut(`${API_URL}/api/signature/biometrics`, requestContract, headers);

export const postSignatureBiometricsVerification = (requestContract: SendVerificationCodeCommand, headers = new Headers()): 
	Promise<FetchResponse<SendVerificationCodeCommandResult>> => 
	apiPost(`${API_URL}/api/signature/biometrics/verification`, requestContract, headers);

export const putSignatureBiometricsVerification = (requestContract: ValidateVerificationCodeCommand, headers = new Headers()): 
	Promise<FetchResponse<ValidateVerificationCodeCommand>> => 
	apiPut(`${API_URL}/api/signature/biometrics/verification`, requestContract, headers);

export const postTracking = (requestContract: LogDto, headers = new Headers()): 
	Promise<FetchResponse<CreateLogResult>> => 
	apiPost(`${API_URL}/api/tracking`, requestContract, headers);

export const getUserContact = (headers = new Headers()): 
	Promise<FetchResponse<UserContactInfoQueryResult>> => {
	return apiGet(`${API_URL}/api/user/contact`, headers, {});
}

export const getValuesLogError = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/values/log-error`, headers, {});
}

export const getValuesLogError2 = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/values/log-error2`, headers, {});
}

export const getValuesLogError3 = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/values/log-error3`, headers, {});
}

export const getValuesLogError4 = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/values/log-error4`, headers, {});
}

export const API = { 
	getAccessControlDistributions,
	postAttachments,
	deleteAttachmentsAttachmentGuid,
	postAuthSignUpBiometricsChallenge,
	postAuthSignUpBiometrics,
	postAuthSignInBiometricsChallenge,
	postAuthSignInBiometrics,
	deleteAuthBiometricsId,
	postAuthSignUp,
	postAuthSignIn,
	deleteAuthTokenRevoke,
	postAuthVerifyEmail,
	postAuthResendVerificationEmail,
	postAuthPasswordReset,
	postAuthPasswordSet,
	putAuthPassword,
	postAuthTokenProlong,
	getBankIdAuthVerify,
	getBankIdAuthNativeCallback,
	postBankIdAuthProcessesInit,
	getBankIdAuthProcessesProcessID,
	getCacheRefresh,
	getV1CalculationsPension,
	postV1Campaigns,
	postV1CampaignsChannelIDNotification,
	getCampaigns,
	putCampaignsChannelID,
	getClient,
	putClientTermsAndServices,
	putClientNotificationStatus,
	putClientAnalyticsStatus,
	getClientDealers,
	postClientContactConfirmation,
	putClientContactConfirmation,
	getClientExistence,
	postClientPersonalData,
	postClientAml,
	getClientDocuments,
	getClientVerificationType,
	getCodeListBanks,
	getCodeListCountries,
	getCodeListBusinessSector,
	getCodeListCurrencies,
	getContractDashboard,
	getContractContractID,
	getContractTransactionsContractID,
	getContractCashFlowContractID,
	getContractBankAccountsContractID,
	getContractDealerContractID,
	getContractDetailContractID,
	getContractUnsettledTradesContractID,
	postContractInvestUninvestedDeposits,
	postContractRedemption,
	postContractPreview,
	postContract,
	deleteContractContractIDInstruction,
	getContractPaymentInstructions,
	postContractTransferDip,
	postContractTransferDipContractPreview,
	postContractContractIDBankConnections,
	postContractContractIDBankConnectionsDeactivate,
	postContractContractIDExchangeAssets,
	postContractContractIDEdit,
	getContractDraft,
	postContractDraft,
	postContractDraftDocument,
	getContractDraftDocumentDocumentGuid,
	getDocumentsFileName,
	getDocuments,
	getExchangeRate,
	getFcmTokensTokenStatus,
	postFcmTokens,
	getHealthStatus,
	postLogError,
	postPaymentQrCode,
	getProductList,
	getProductTilesList,
	getProductSectionsIsin,
	getProductFeeRateCalculation,
	postV1PushNotificationsSend,
	getSettings,
	postSignatureSms,
	putSignatureSms,
	postSignatureBiometrics,
	putSignatureBiometrics,
	postSignatureBiometricsVerification,
	putSignatureBiometricsVerification,
	postTracking,
	getUserContact,
	getValuesLogError,
	getValuesLogError2,
	getValuesLogError3,
	getValuesLogError4
}

