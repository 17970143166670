import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { FormProvider, useForm } from "react-hook-form";
import { useAppSelector } from "Hooks/useAppSelector";
import { useToast } from "Hooks/useToast";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { PhoneVerificationFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServerErrorMessage } from "Hooks/useHandleServerError";
import { sendClientVerificationCodeAsync } from "State/Contracts/Biometrics/SendClientVerificationCodeState";
import { verifyClientVerificationCodeAsync } from "State/Contracts/Biometrics/VerifyClientVerificationCodeState";
import { SignatureFormLoader } from "Components/ContractBankConnections/Shared/SignatureFormLoader";
import { SignatureFormTitle } from "Components/ContractBankConnections/Shared/SignatureFormTitle";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PhoneVerification } from "Components/ContractCreate/Shared/PhoneVerification";
import { string, object, ObjectSchema } from "yup";
import { SignatureFormSmsTranslations } from "Components/ContractBankConnections/Shared/SignatureFormSms";

const useYupFormSchema = (): ObjectSchema<PhoneVerificationFormModel> =>
  object<PhoneVerificationFormModel>().shape({
    code: string()
      .matches(/^.{5}$/)
      .required(),
  });

export interface ClientVerificationFormBiometricsTranslations
  extends SignatureFormSmsTranslations {
  clientVerificationHashNotFoundError: string;
}

type Props = {
  isLoading?: boolean;
  isCodeSendToClientEmail: boolean;
  phoneOrEmail: string;
  signatureHash: string | null | undefined;
  clientVerificationHash: string | null | undefined;
  translations: ClientVerificationFormBiometricsTranslations;
  error?: Error | null;
  errorMessages?: string[] | null;
  onSuccess: () => void;
};

export const ClientVerificationFormBiometrics: FunctionComponent<
  Props
> = props => {
  const {
    isLoading,
    isCodeSendToClientEmail,
    phoneOrEmail,
    translations,
    error,
    errorMessages,
    onSuccess,
  } = props;

  const { t } = useResource();
  const { current: resendSmsTimeoutMessage } = useRef(
    t(
      Resources.ApiValidation.Validation.Specific.SmsErrorSecondsBetweenSending,
    ),
  );

  const { showToast } = useToast();
  const {
    isLoading: isSendingCode,
    clientVerificationHash,
    error: sendCodeError,
  } = useAppSelector(s => s.biometrics.sendClientVerificationCode);

  const { isLoading: isVerifyingCode, error: verifyCodeError } = useAppSelector(
    s => s.biometrics.verifyClientVerificationCode,
  );

  const dispatch = useAppDispatch();
  const schema = useYupFormSchema();
  const form = useForm<PhoneVerificationFormModel>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setError, clearErrors } = form;

  useEffect(() => {
    if (verifyCodeError) {
      return setError("code", {
        message: translations.verificationError,
      });
    }

    clearErrors("code");
  }, [verifyCodeError, setError, clearErrors, translations.verificationError]);

  const verificationErrorMessages = useServerErrorMessage(
    sendCodeError || verifyCodeError,
    Resources.Validation.ServerError,
  );

  const [isWaitingToVerifyCode, setIsWaitingToVerifyCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const resendCode = useCallback(() => {
    if (clientVerificationHash) {
      dispatch(
        sendClientVerificationCodeAsync.request({
          isCodeSendToClientEmail,
          clientVerificationHash,
        }),
      );
      setIsCodeSent(true);
    }
  }, [isCodeSendToClientEmail, clientVerificationHash, dispatch]);

  useEffect(() => {
    if (isSendingCode || !isCodeSent) {
      return;
    }

    if (
      translations.resendCodeTimeout &&
      verificationErrorMessages.includes(resendSmsTimeoutMessage)
    ) {
      verificationErrorMessages[
        verificationErrorMessages.findIndex(x => x === resendSmsTimeoutMessage)
      ] = translations.resendCodeTimeout;
    }

    if (sendCodeError) {
      showToast({
        content: verificationErrorMessages.join(" "),
        variant: "error",
      });
      setError("code", {
        message: verificationErrorMessages.join(" "),
      });
    }

    if (!sendCodeError) {
      clearErrors();
      showToast({
        content: translations.codeSent,
        variant: "success",
      });
    }

    setIsCodeSent(false);
  }, [
    isCodeSent,
    isSendingCode,
    isCodeSendToClientEmail,
    setIsCodeSent,
    setError,
    sendCodeError,
    showToast,
    verificationErrorMessages,
    clearErrors,
    errorMessages,
    translations,
    resendSmsTimeoutMessage,
  ]);

  const clientVerificationHashNotFoundError = !clientVerificationHash
    ? new Error(translations.clientVerificationHashNotFoundError)
    : null;

  const isError = !!error || !!clientVerificationHashNotFoundError;
  const serverErrorMessages = useServerErrorMessage(
    error,
    Resources.Validation.ServerError,
  );

  const onSubmit = ({ code }: PhoneVerificationFormModel) => {
    if (clientVerificationHash && code) {
      dispatch(
        verifyClientVerificationCodeAsync.request({
          clientVerificationHash,
          code,
          onSuccess: () => {
            setIsWaitingToVerifyCode(true);
            onSuccess();
          },
        }),
      );
    }
  };

  if (isLoading || isSendingCode) {
    return (
      <SignatureFormLoader
        title={translations.title}
        working={translations.isLoading}
      />
    );
  }

  return (
    <>
      <div>
        <SignatureFormTitle title={translations.title} />
        <LoadingWrapper
          error={error ?? clientVerificationHashNotFoundError}
          errorMessages={errorMessages ?? serverErrorMessages ?? undefined}
        >
          <FormProvider {...form}>
            <PhoneVerification
              phone={phoneOrEmail}
              mask="*****"
              translations={translations}
              onResendCode={resendCode}
              onSubmit={onSubmit}
            />
          </FormProvider>
        </LoadingWrapper>
      </div>

      <PrimaryButton
        isLoading={isVerifyingCode || isWaitingToVerifyCode}
        color="primary"
        trackingTag={translations.submit}
        disabled={isError || isVerifyingCode || isWaitingToVerifyCode}
        onClick={handleSubmit(onSubmit)}
      >
        {translations.submit}
      </PrimaryButton>
    </>
  );
};
