import {
  ContractExchangeableAsset,
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsType,
  ContractOverviewPortfolioItemDto,
  CurrencyCode,
} from "Api/Api";
import { ExchangeAssetsFormModel } from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { Resources, useResource } from "Translations/Resources";
import { getLocalizedCurrencyCode } from "Utils/CurrencyUtils";
import { convertNanToNull, formatNumber } from "Utils/NumberUtils";
import { nameof } from "Utils/ObjectUtils";
import { boolean, mixed, number, object, ObjectSchema, string } from "yup";

export const MIN_EXCHANGE_ASSETS_AMOUNT_CZK = 100;
export const MIN_EXCHANGE_ASSETS_AMOUNT_EUR = 20;
export const MAX_EXCHANGE_ASSETS_AMOUNT_CZK = 100_000_000;
export const MAX_EXCHANGE_ASSETS_AMOUNT_EUR = 5_000_000;

const ValidationResources = Resources.Contract.ExchangeAssets.Modelling;

export const useYupFormSchema = (
  portfolioItem: ContractOverviewPortfolioItemDto,
  exchangeableAssets?: ContractExchangeableAsset[],
): ObjectSchema<ExchangeAssetsFormModel> => {
  const { t } = useResource();

  const schema: ObjectSchema<ExchangeAssetsFormModel> = object().shape({
    targetIsin: string().required(),
    type: mixed<ContractExchangeAssetsType>()
      .oneOf(Object.values(ContractExchangeAssetsType))
      .required(),
    method: mixed<ContractExchangeAssetsMethod>()
      .oneOf(Object.values(ContractExchangeAssetsMethod))
      .required(),
    amountOrPieces: number()
      .required()
      .when(nameof<ExchangeAssetsFormModel>("method"), {
        is: ContractExchangeAssetsMethod.Pieces,
        then: schema =>
          schema
            .transform(x => convertNanToNull(Number.parseFloat(x)))
            .min(1, t(ValidationResources.Parameters.Pieces.Validation.Min))
            .max(
              portfolioItem.quantity ?? 0,
              t(ValidationResources.Parameters.Pieces.Validation.Max, {
                value: formatNumber(portfolioItem.quantity ?? 0),
              }),
            )
            .required(),
        otherwise: schema =>
          schema
            .transform(x => convertNanToNull(Number.parseInt(x)))
            .required()
            .test({
              name: "min-amount",
              test: (amount, context) => {
                const defaultAmount =
                  portfolioItem.currency === CurrencyCode.CZK
                    ? MIN_EXCHANGE_ASSETS_AMOUNT_CZK
                    : MIN_EXCHANGE_ASSETS_AMOUNT_EUR;

                const exchangeableAsset = exchangeableAssets?.find(
                  x => x.targetIsin === context.parent.targetIsin,
                );

                const minAmount =
                  exchangeableAsset?.single.minAmount ?? defaultAmount;

                if (amount >= minAmount) {
                  return true;
                }

                return context.createError({
                  message: t(
                    ValidationResources.Parameters.Amount.Validation.Min,
                    {
                      value: formatNumber(minAmount),
                      currency: getLocalizedCurrencyCode(
                        portfolioItem.currency,
                      ),
                    },
                  ),
                });
              },
            })
            .test(
              "max-amount",
              context => {
                const defaultAmount =
                  portfolioItem.currency === CurrencyCode.CZK
                    ? MAX_EXCHANGE_ASSETS_AMOUNT_CZK
                    : MAX_EXCHANGE_ASSETS_AMOUNT_EUR;

                const exchangeableAsset = exchangeableAssets?.find(
                  x => x.targetIsin === context.parent.targetIsin,
                );

                const maxAmount = Math.min(
                  portfolioItem.amount ?? 0,
                  exchangeableAsset?.single.maxAmount ?? defaultAmount,
                );

                return t(ValidationResources.Parameters.Amount.Validation.Max, {
                  value: formatNumber(maxAmount),
                  currency: getLocalizedCurrencyCode(portfolioItem.currency),
                });
              },
              (amount, context) => {
                const defaultAmount =
                  portfolioItem.currency === CurrencyCode.CZK
                    ? MAX_EXCHANGE_ASSETS_AMOUNT_CZK
                    : MAX_EXCHANGE_ASSETS_AMOUNT_EUR;

                const exchangeableAsset = exchangeableAssets?.find(
                  x => x.targetIsin === context.parent.targetIsin,
                );

                const maxAmount = Math.min(
                  portfolioItem.amount ?? 0,
                  exchangeableAsset?.single.maxAmount ?? defaultAmount,
                );

                if (maxAmount >= amount) {
                  return true;
                }

                return context.createError({
                  message: t(
                    ValidationResources.Parameters.Amount.Validation.Max,
                    {
                      value: formatNumber(maxAmount),
                      currency: getLocalizedCurrencyCode(
                        portfolioItem.currency,
                      ),
                    },
                  ),
                });
              },
            )
            .required(),
      }),
    isExchangeAll: boolean().required(),
  });

  return schema;
};
