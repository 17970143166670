import { yupResolver } from "@hookform/resolvers/yup";
import { AddressType } from "Api/Api";
import { ContactInformationForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/ContactInfoForm";
import { AddressesForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/AddressesForm";
import { PersonalDataForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/PersonalDataForm";
import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useYupFormSchema } from "Hooks/More/PersonalData/EditPersonalData/Modelling/useYupFormSchema";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useCallback, useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  editPersonalDataAsync,
  EditPersonalDataFormModel,
  EditPersonalDataSteps,
  setEditPersonalDataActualStep,
  setEditPersonalDataFormData,
} from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";
import { DocumentsForm } from "Components/More/PersonalData/EditPersonalData/Modelling/components/DocumentsForm";
import { isMatchingEmail } from "Utils/EmailUtils";
import { isMatchingPhone } from "Utils/PhoneUtils";

const PageResource = Resources.More.PersonalData.EditPersonalData;
const ComponentResources = PageResource.Modelling;

export const ModellingStep: FunctionComponent = () => {
  const { t } = useResource();
  const { data: client } = useAppSelector(s => s.client.client);
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.editPersonalData,
  );

  const isBankID = useRef(!!defaultValues?.isBankID);
  const dispatch = useAppDispatch();
  const email = useAppSelector(
    s => s.profile.contactInfo.contactInfo?.client?.email,
  );

  const phone = useAppSelector(
    s => s.profile.contactInfo.contactInfo?.client?.phone,
  );

  const nationalities = useMemo(() => {
    if (client?.citizenship && client?.otherCitizenships) {
      return Array.from(
        new Set([client.citizenship, ...client.otherCitizenships]),
      );
    }

    return client?.citizenship ? [client.citizenship] : [];
  }, [client?.citizenship, client?.otherCitizenships]);

  const permanentAddress = useMemo(
    () => client?.addresses.find(x => x.type === AddressType.Domicile),
    [client?.addresses],
  );

  const title = usePageTitle();
  const schema = useYupFormSchema(nationalities, phone, email);
  const form = useForm<EditPersonalDataFormModel>({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      ...defaultValues,
      personalData: {
        lastName:
          defaultValues.personalData.lastName || client?.name.lastName || "",
      },
      contactInfo: {
        ...defaultValues.contactInfo,
        phone: defaultValues.contactInfo.phone || phone || "",
        email: defaultValues.contactInfo.email || email || "",
      },
      permanentAddress: {
        ...defaultValues.permanentAddress,
        streetName:
          defaultValues.permanentAddress.streetName ||
          permanentAddress?.streetName ||
          "",
        streetNumber:
          defaultValues.permanentAddress.streetNumber ||
          permanentAddress?.streetNumber ||
          "",
        streetConscriptionNumber:
          defaultValues.permanentAddress.streetConscriptionNumber ||
          permanentAddress?.streetConscriptionNumber ||
          "",
        postalCode:
          defaultValues.permanentAddress.postalCode ||
          permanentAddress?.zip ||
          "",
        city:
          defaultValues.permanentAddress.city || permanentAddress?.city || "",
        country:
          defaultValues.permanentAddress.country ||
          permanentAddress?.country ||
          "",
      },
    },
  });

  const {
    watch,
    handleSubmit,
    formState: { isValid, isValidating },
  } = form;

  const newPhone = watch("contactInfo.phone");
  const newEmail = watch("contactInfo.email");

  const onSubmit = useCallback(
    (formData: EditPersonalDataFormModel) => {
      const isContactInfoChange =
        !isMatchingPhone(phone, newPhone) || !isMatchingEmail(email, newEmail);

      dispatch(
        setEditPersonalDataFormData({
          ...formData,
          isBiometry: canUseBiometricsForSigning,
          isContactInfoChange,
          isCodeSendToClientEmail: !isMatchingPhone(phone, newPhone),
        }),
      );

      if (isContactInfoChange) {
        dispatch(
          setEditPersonalDataActualStep(
            EditPersonalDataSteps.ClientVerification,
          ),
        );
      } else {
        dispatch(
          setEditPersonalDataActualStep(EditPersonalDataSteps.Signature),
        );
      }

      dispatch(
        editPersonalDataAsync.request({
          ...formData,
          isBiometry: canUseBiometricsForSigning,
          isContactInfoChange,
          isCodeSendToClientEmail: !isMatchingPhone(phone, newPhone),
        }),
      );
    },
    [canUseBiometricsForSigning, dispatch, email, newEmail, newPhone, phone],
  );

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {title}
        </PageTitle>

        <FormProvider {...form}>
          <PersonalDataForm isBankID={isBankID.current} />
          <ContactInformationForm />
          <AddressesForm isBankID={isBankID.current} />
          <DocumentsForm
            isBankID={isBankID.current}
            nationalities={nationalities}
          />
        </FormProvider>
      </div>

      <BlSlideButton
        minSlideVelocity={0.95}
        minSlideWidth={0.95}
        onSlideDone={handleSubmit(onSubmit)}
        trackingTag={t(ComponentResources.Submit)}
        disabled={!isValid}
        isLoading={isValidating}
      >
        {t(ComponentResources.Submit)}
      </BlSlideButton>
    </>
  );
};
