import {
  PersonalDocumentType,
  UpdateClientPersonalDataFormModel as PersonalDataFormModel,
  UpdateClientPersonalDataContactInfoFormModel as ContactInfoFormModel,
  UpdateClientPersonalDataDocumentScanFormModel as DocumentScanFromModel,
  UpdateClientPersonalDataDocumentFormModel as DocumentFormModel,
  UpdateClientPersonalDataAddressFormModel as AddressFormModel,
} from "Api/Api";
import { USA_NATIONALITY_FORBID_TYPE } from "Constants/Contracts/Create";
import { CZECHIA_COUNTRY_CODE, USA_COUNTRY_CODE } from "Constants/Countries";
import { VALIDATION_POSTAL_CODE_COUNTRIES } from "Constants/Inputs";
import { useAppSelector } from "Hooks/useAppSelector";
import { BankIdClaims, BankIdScopes } from "Models/BankID";
import { EditPersonalDataFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { ResourceDictionary } from "Translations/ResourceDictionary";
import { Resources, useResource } from "Translations/Resources";
import { ObjPathProxy } from "ts-object-path";
import { isMatchingEmail } from "Utils/EmailUtils";
import { convertNanToNull } from "Utils/NumberUtils";
import { isNoU } from "Utils/ObjectUtils";
import { isMatchingPhone } from "Utils/PhoneUtils";
import { boolean, mixed, object, ObjectSchema, string } from "yup";

export const useYupFormSchema = (
  nationalities: string[],
  originalPhone: string | null | undefined,
  originalEmail: string | null | undefined,
): ObjectSchema<EditPersonalDataFormModel> => {
  const { t } = useResource();
  const { lastRequest: defaultValues } = useAppSelector(
    s => s.client.editPersonalData,
  );

  return object<EditPersonalDataFormModel>().shape({
    isBankID: boolean().required(),
    personalData: personalDataValidator(),
    contactInfo: contactInformationValidator(
      originalPhone ?? "",
      originalEmail ?? "",
      t,
    ),
    permanentAddress: addressValidator(t),
    primaryDocument: documentValidator(
      defaultValues.isBankID,
      getAllowedDocumentTypes(nationalities),
    ),
  });
};

const addressValidator = (
  t: (
    resourcePath: ObjPathProxy<ResourceDictionary, string>,
    options?: any,
  ) => string,
): ObjectSchema<AddressFormModel> =>
  object<AddressFormModel>().shape({
    streetName: string()
      .required()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Street}`),
    city: string()
      .min(2)
      .required()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.City}`),
    streetNumber: string()
      .optional()
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.StreetNumber}`),
    streetConscriptionNumber: string()
      .transform(x => x ?? "")
      .required()
      .label(
        `${BankIdScopes.ProfileAddresses}:${BankIdClaims.BuildingApartment}`,
      ),
    postalCode: string()
      .required()
      .when("country", {
        is: (country: string) =>
          VALIDATION_POSTAL_CODE_COUNTRIES.includes(country),
        then: schema =>
          schema
            .min(5, t(Resources.Validation.InvalidFormat))
            .max(5, t(Resources.Validation.InvalidFormat))
            .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`),
        otherwise: schema =>
          schema
            .transform(val =>
              isNoU(convertNanToNull(parseInt(val))) ? null : val,
            )
            .nonNullable()
            .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.ZipCode}`),
      }),
    country: string()
      .required()
      .test(
        USA_NATIONALITY_FORBID_TYPE,
        t(Resources.Contract.NewContract.Shared.USACountryPermit),
        value => value !== USA_COUNTRY_CODE,
      )
      .label(`${BankIdScopes.ProfileAddresses}:${BankIdClaims.Country}`),
  });

const documentValidator = (
  isBankID: boolean,
  allowedDocumentTypes: PersonalDocumentType[],
): ObjectSchema<DocumentFormModel> =>
  object<DocumentFormModel>().shape({
    type: mixed<PersonalDocumentType>()
      .oneOf(Object.values(PersonalDocumentType))
      .oneOf(allowedDocumentTypes)
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Type}`),
    issueCountry: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Country}`),
    expiryDate: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.ValidTo}`),
    issueDate: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.IssueDate}`),
    issuingAuthority: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Issuer}`),
    number: string()
      .required()
      .label(`${BankIdScopes.ProfileIdCards}:${BankIdClaims.Number}`),
    frontScan: isBankID
      ? documentScanValidator().default(undefined).nullable().optional()
      : documentScanValidator().required(),
    backScan: isBankID
      ? documentScanValidator().default(undefined).nullable().optional()
      : documentScanValidator().required(),
  });

const documentScanValidator = (): ObjectSchema<DocumentScanFromModel> =>
  object<DocumentScanFromModel>().shape({
    documentGuid: string().required(),
    fileName: string().required(),
  });

export function getAllowedDocumentTypes(
  nationalities: string[],
): PersonalDocumentType[] {
  if (nationalities.some(x => x === CZECHIA_COUNTRY_CODE)) {
    return [PersonalDocumentType.IdentityCard];
  }

  return Object.values(PersonalDocumentType);
}

const personalDataValidator = (): ObjectSchema<PersonalDataFormModel> =>
  object<PersonalDataFormModel>().shape({
    lastName: string().required(),
  });

const contactInformationValidator = (
  phone: string,
  email: string,
  t: (
    resourcePath: ObjPathProxy<ResourceDictionary, string>,
    options?: any,
  ) => string,
): ObjectSchema<ContactInfoFormModel> =>
  object<ContactInfoFormModel>().shape({
    email: string().required(),
    phone: string()
      .required()
      .test(
        "email-and-phone-changed",
        t(
          Resources.More.PersonalData.EditPersonalData.Modelling.ContactInfo
            .EmailAndPhoneChanged,
        ),
        (newPhone, context) =>
          !(
            !isMatchingPhone(phone, newPhone) &&
            !isMatchingEmail(email, context.parent.email)
          ),
      ),
  });
