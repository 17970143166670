import { Typography, TypographyProps } from "@mui/material";
import { useDesktop } from "Hooks/useDesktop";
import { type PropsWithChildren, type FunctionComponent } from "react";

type Props = {
  showOnMobile?: boolean;
  showOnDesktop?: boolean;
} & Pick<TypographyProps, "marginBottom" | "noWrap">;

export type PageTitleProps = PropsWithChildren<Props>;

export const PageTitle: FunctionComponent<PageTitleProps> = ({
  children,
  marginBottom = 2,
  noWrap,
  showOnMobile = true,
  showOnDesktop = true,
}) => {
  const { isDesktop } = useDesktop();

  if ((isDesktop && showOnDesktop) || (!isDesktop && showOnMobile)) {
    return (
      <Typography
        variant="h2"
        component="h2"
        marginBottom={marginBottom}
        noWrap={noWrap}
      >
        {children}
      </Typography>
    );
  }

  return null;
};
