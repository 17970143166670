import {
  DeleteAttachmentCommandResult,
  deleteAttachmentsAttachmentGuid,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";

export const deleteAttachmentAsync = createAsyncAction(
  "@attachments/DELETE_ATTACHMENT_REQUEST",
  "@attachments/DELETE_ATTACHMENT_SUCCESS",
  "@attachments/DELETE_ATTACHMENT_FAILURE",
)<
  { attachmentGuid: string },
  [DeleteAttachmentCommandResult, { attachmentGuid: string }],
  Error
>();

function* deleteAttachment(
  action: ReturnType<typeof deleteAttachmentAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      deleteAttachmentsAttachmentGuid,
      action.payload.attachmentGuid,
    );

    if (error) {
      yield put(deleteAttachmentAsync.failure(error));
      return;
    }

    yield put(
      deleteAttachmentAsync.success(response, {
        attachmentGuid: action.payload.attachmentGuid,
      }),
    );
  } catch (err) {
    yield put(deleteAttachmentAsync.failure(err as Error));
  }
}

export function* watchDeleteAttachmentSaga() {
  yield takeLatest(getType(deleteAttachmentAsync.request), deleteAttachment);
}
