import { FunctionComponent, useRef, useState } from "react";
import { ProductLogo } from "Components/Shared/ProductLogo";
import { CurrencyRenderer } from "Components/Shared/CurrencyRenderer";
import { IconCircleWrapper } from "Components/Shared/IconCircleWrapper";
import {
  ExchangeIcon,
  OtherIcon,
  PurchaseIcon,
  RedeemIcon,
} from "Components/Shared/Icons";
import { StyledDarkCard, StyledFlex } from "Components/Shared/StyledComponents";
import {
  Box,
  CardContent,
  Grid,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { useClickAway, useToggle } from "react-use";

import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { useNavigate } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { UninvestedDepositTooltip } from "./UninvestedDepositTooltip";
import { RedemptionNotAllowedDialog } from "Components/ContractRedemption/RedemptionNotAllowedDialog";
import { useIsRedemptionAllowed } from "Hooks/Contract/Detail/useIsRedemptionAllowed";
import { ContractTypeCode, CurrencyCode } from "Api/Api";
import { ComponentVisibilityWrapper } from "Components/Shared/ComponentVisibilityWrapper";
import { useFormattedProductClass } from "Hooks/Products/useFormattedProductClass";
import { useIsSelfNegotiatedContract } from "Hooks/Contract/Detail/useIsSelfNegotiatedContract";
import { Colors } from "Components/Layout/Themes/Colors";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { useConfirmationDialog } from "Hooks/Dialogs/useConfirmationDialog";
import { ConfirmValues } from "Components/Shared/Dialogs/ConfirmDialog";

type Props = {
  isin: string;
  name: string;
  estate: number | null | undefined;
  currency?: CurrencyCode;
  uninvestedDeposits?: number | null;
  contractID: number;
  isUnderage: boolean;
  contractType: ContractTypeCode;
  isDip: boolean;
  isReadonly: boolean;
  hasExchangeableAssets: boolean;
};

const StyledSlide = styled(Slide)`
  z-index: 1;
  position: absolute;
  right: -1px;
  top: -1px;
  background-color: ${({ theme }) => theme.colors.gray};
`;

const ButtonBox = styled.div<{
  $color?: string;
  $isLast?: boolean;
  $disabled?: boolean;
}>`
  padding: 0 8px;
  min-width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ $color, theme }) => $color || theme.colors.gray};
  border: 1px solid ${({ theme }) => theme.colors.border}80;
  cursor: pointer;
  ${({ $isLast }) => $isLast && "border-radius: 0 16px 0px 0;"}
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
  `}
`;

const StyledCardContent = styled(CardContent)`
  display: grid;
  overflow: auto;
`;

const ComponentResources = Resources.Contract.Detail.Overview;

export const Investment: FunctionComponent<Props> = ({
  estate,
  currency = CurrencyCode.CZK,
  isin,
  uninvestedDeposits,
  contractID,
  isUnderage,
  contractType,
  isDip,
  isReadonly,
  hasExchangeableAssets,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const slideRef = useRef<HTMLDivElement>(null);
  const { t } = useResource();
  const [isOpen, toggle] = useToggle(false);
  const { palette } = useTheme();
  const navigate = useNavigate();

  useClickAway(slideRef, () => toggle(false));

  const isSelfInvestmentType = useIsSelfNegotiatedContract(contractType);
  const isRedemptionAllowed = useIsRedemptionAllowed(isin);
  const [
    isRedemptionNotAllowedDialogOpened,
    setIsRedemptionNotAllowedDialogOpened,
  ] = useState(false);

  const productClass = useFormattedProductClass(isin);

  const redemptionDipDialog = useConfirmationDialog({
    confirmation: t(
      Resources.Contract.Detail.Redemption.DipDialog.Confirmation,
    ),
    cancelText: t(Resources.Common.Close),
    confirmText: t(Resources.Common.Continue),
  });

  return (
    <DipTagWrapper showPlain={!isDip}>
      <StyledDarkCard>
        {!isReadonly && (
          <StyledSlide
            ref={slideRef}
            in={isOpen}
            direction="left"
            container={containerRef.current}
          >
            <Box display="flex" justifyContent="end">
              <ButtonBox
                $color={Colors.PrimaryMain}
                $disabled={isUnderage}
                onClick={() =>
                  navigate(getPath(AppRouting.Purchase, contractID, isin))
                }
              >
                <PurchaseIcon width={25} height={25} color={Colors.Black} />
                <Typography
                  color={Colors.Black}
                  textTransform="lowercase"
                  fontSize={12}
                >
                  {t(ComponentResources.Buy)}
                </Typography>
              </ButtonBox>
              {/* // TODO: disabled, because it's not implemented yet
          <ButtonBox $color={palette.secondary.main} $disabled={isUnderage}>
            <StyledExchangeIcon
              width={25}
              height={25}
              color={palette.background.default}
            />
            <Typography
              color={palette.background.default}
              textTransform="lowercase"
              fontSize={12}
            >
              {t(ComponentResources.Change)}
            </Typography>
          </ButtonBox>*/}
              <RedemptionNotAllowedDialog
                isDip={isDip}
                isOpened={isRedemptionNotAllowedDialogOpened}
                onClose={() => setIsRedemptionNotAllowedDialogOpened(false)}
              />
              <ButtonBox
                $isLast={!hasExchangeableAssets}
                $disabled={!isSelfInvestmentType}
                onClick={async () => {
                  if (isRedemptionAllowed) {
                    if (isDip) {
                      const result = await redemptionDipDialog();
                      if (result === ConfirmValues.Cancel) {
                        return;
                      }
                    }

                    navigate(getPath(AppRouting.Redemption, contractID, isin));
                  } else {
                    setIsRedemptionNotAllowedDialogOpened(true);
                  }
                }}
              >
                <RedeemIcon
                  width={25}
                  height={25}
                  color={palette.secondary.main}
                />
                <Typography
                  color="secondary"
                  textTransform="lowercase"
                  fontSize={12}
                >
                  {t(ComponentResources.RedemptionButton)}
                </Typography>
              </ButtonBox>
              {hasExchangeableAssets && (
                <ButtonBox
                  $isLast
                  onClick={() =>
                    navigate(
                      getPath(
                        AppRouting.ContractExchangeAssets,
                        contractID,
                        isin,
                      ),
                    )
                  }
                >
                  <ExchangeIcon
                    width={25}
                    height={25}
                    color={palette.secondary.main}
                  />
                  <Typography
                    color="secondary"
                    textTransform="lowercase"
                    fontSize={12}
                  >
                    {t(ComponentResources.ExchangeAssetsButton)}
                  </Typography>
                </ButtonBox>
              )}
            </Box>
          </StyledSlide>
        )}

        <StyledCardContent>
          <StyledFlex $gap={2}>
            <ProductLogo
              isin={isin}
              name={productClass}
              imageWidth={90}
              imageHeight={35}
            />
            <Box ref={containerRef} width="100%">
              <StyledFlex
                $gap={1}
                $alignItems="center"
                $justifyContent="space-between"
              >
                <div>
                  <Typography textTransform="uppercase">
                    {t(ComponentResources.Estate)}
                  </Typography>
                  <CurrencyRenderer
                    value={estate}
                    decimalPlaces={2}
                    fontSize={15}
                    currency={currency}
                  />
                </div>
                {!isReadonly && (
                  <ComponentVisibilityWrapper isHidden={isOpen}>
                    <IconCircleWrapper
                      size={20}
                      color="primary"
                      onClick={toggle}
                    >
                      <OtherIcon width={10} />
                    </IconCircleWrapper>
                  </ComponentVisibilityWrapper>
                )}
              </StyledFlex>
              {!!uninvestedDeposits && (
                <>
                  <Typography textTransform="uppercase" marginTop={3}>
                    {t(ComponentResources.AwaitingInvestmentLabel)}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <CurrencyRenderer
                        value={uninvestedDeposits}
                        decimalPlaces={2}
                        fontSize={15}
                        currency={currency}
                      />
                    </Grid>
                    <Grid item>
                      <UninvestedDepositTooltip
                        isin={isin}
                      ></UninvestedDepositTooltip>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </StyledFlex>
        </StyledCardContent>
      </StyledDarkCard>
    </DipTagWrapper>
  );
};
