import { Stack } from "@mui/material";
import { MenuChip } from "Components/ContractDetail/MenuChip";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { Aml } from "Components/More/PersonalData/Aml";
import { Documents } from "Components/More/PersonalData/Documents";
import { PersonalData } from "Components/More/PersonalData/PersonalData";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useActualTab } from "Hooks/More/PersonalData/useActualTab";
import {
  PersonalDataTabs,
  useMenuTabs,
} from "Hooks/More/PersonalData/useMenuTabs";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useDesktop } from "Hooks/useDesktop";
import { getClientAsync } from "State/Client/Client/GetClient";
import { resetClientDocumentsState } from "State/Client/Documents/ClientDocumentsState";
import { Resources, useResource } from "Translations/Resources";
import { getPath, AppRouting } from "Utils/UrlUtils";
import {
  useRef,
  MouseEvent,
  type FunctionComponent,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router";
import { useDraggable } from "react-use-draggable-scroll";
import styled from "styled-components";

const StyledStack = styled(Stack)`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const PersonalDataPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [actualTab, setActualTab] = useActualTab();

  const menuTabs = useMenuTabs();
  const menuStackRef = useRef<HTMLDivElement>(null);

  const { events } = useDraggable(
    // This force typing is from docs and ts is not working without it
    menuStackRef as React.MutableRefObject<HTMLDivElement>,
  );

  const handleMenuTabClick = useCallback(
    (event: MouseEvent<HTMLDivElement>, tab: PersonalDataTabs) => {
      event.stopPropagation();

      const parent = menuStackRef.current;

      if (parent) {
        // we need to wait for the browser to scroll the element into view
        setTimeout(() => {
          const child = event.currentTarget ?? event.target;

          const parentRect = parent.getBoundingClientRect();
          const childRect = child.getBoundingClientRect();

          // ignore for the latest element - it's causing bad UX on safari
          if (parentRect.right === childRect.right) {
            return;
          }

          const scrollToX =
            childRect.left +
            childRect.width / 2 -
            parentRect.left -
            parentRect.width / 2 +
            parent.scrollLeft;

          parent.scrollTo({
            left: Math.max(
              0,
              Math.min(scrollToX, parent.scrollWidth - parent.clientWidth),
            ),
            behavior: "smooth",
          });
        }, 0);
      }

      setActualTab(tab);
    },
    [setActualTab],
  );

  useEffect(() => {
    dispatch(getClientAsync.request());
    dispatch(resetClientDocumentsState());
  }, [dispatch]);

  const menuTabsComponent = useMemo(() => {
    return (
      <StyledStack
        role="menubar"
        direction="row"
        gap={2}
        marginBottom={6}
        overflow="auto"
        position="relative"
        ref={menuStackRef}
        {...events}
      >
        {menuTabs.map(({ value, label }) => (
          <MenuChip
            data-value={value}
            key={value}
            label={label}
            onClick={e => handleMenuTabClick(e, value)}
            active={value === actualTab}
          />
        ))}
      </StyledStack>
    );
  }, [actualTab, events, menuTabs, handleMenuTabClick]);

  return (
    <AuthenticatedLayout
      title={t(Resources.More.Menu.PersonalData.Title)}
      isTitleShown={!isDesktop}
      onBackClick={() => navigate(getPath(AppRouting.More))}
    >
      <StyledFlex
        $flexDirection="column"
        $justifyContent="flex-start"
        $fullHeight
      >
        {menuTabsComponent}
        {actualTab === PersonalDataTabs.PersonalData && <PersonalData />}
        {actualTab === PersonalDataTabs.Aml && <Aml />}
        {actualTab === PersonalDataTabs.Documents && <Documents />}
      </StyledFlex>
    </AuthenticatedLayout>
  );
};
