import { Box, DialogContent, Stack, Typography } from "@mui/material";
import { StyledDialog } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { TrackingWrapper } from "Components/Tracking/TrackingWrapper";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent, useState } from "react";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { ProductCard } from "Components/ContractExchangeAssets/components/ProductCard";
import { track } from "Utils/TrackingUtils";
import { ContractExchangeableAsset } from "Api/Api";

const StyledDialogWithOffset = styled(StyledDialog)`
  margin-top: 90px;
  height: calc(100vh - 90px);
`;

type Props = {
  isDip: boolean;
  isVisible: boolean;
  defaultIsin?: string | null;
  exchangeableAssets: ContractExchangeableAsset[];
  onSelect: (isin: string) => void;
  onClose: () => void;
};

const ComponentResources =
  Resources.Contract.ExchangeAssets.Modelling.FundSelection.Dialog;

export const ProductSelectorDialog: FunctionComponent<Props> = ({
  defaultIsin,
  exchangeableAssets,
  isDip,
  isVisible,
  onSelect,
  onClose,
}) => {
  const { t } = useResource();
  const [selectedIsin, setSelectedIsin] = useState(defaultIsin);

  const handleSelect = (isin: string) => {
    track({
      category: "RadioButtons",
      event: "Change",
      action: "Click",
      tag: "fundISIN",
      value: isin,
    });

    setSelectedIsin(isin);
  };

  const handleSubmit = () => {
    if (selectedIsin) {
      onSelect(selectedIsin);
    }
  };

  return (
    <StyledDialogWithOffset
      open={isVisible}
      onClose={() => {
        onClose();
        setSelectedIsin(defaultIsin);
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <TrackingWrapper
          payload={{
            category: "ModalView",
            event: "Open",
            action: isVisible ? "Open" : "Close",
          }}
        >
          <Box marginBottom={10}>
            <Typography marginBottom={5}>
              {t(ComponentResources.Description)}
            </Typography>
            <Stack gap={2}>
              {exchangeableAssets.map(x => (
                <DipTagWrapper showPlain={!isDip} key={x.targetIsin}>
                  <ProductCard
                    isChecked={selectedIsin === x.targetIsin}
                    isin={x.targetIsin}
                    key={x.targetIsin}
                    onCheck={handleSelect}
                  />
                </DipTagWrapper>
              ))}
            </Stack>
          </Box>
          <PrimaryButton
            color="primary"
            fullWidth
            disabled={
              !exchangeableAssets.some(x => x.targetIsin === selectedIsin)
            }
            onClick={handleSubmit}
          >
            {t(ComponentResources.Submit)}
          </PrimaryButton>
        </TrackingWrapper>
      </DialogContent>
    </StyledDialogWithOffset>
  );
};
