import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ProfileSettings } from "Components/Profile/Settings/ProfileSettings";
import { useDesktop } from "Hooks/useDesktop";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useCallback, type FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router";

export const SettingsPage: FunctionComponent = _ => {
  const { t } = useResource();
  const { isDesktop } = useDesktop();
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = useCallback(() => {
    const previousPath = location.state?.from as string | null | undefined;

    // Prevent looping when moving between Settings <-> 2FA
    if (previousPath?.startsWith(getPath(AppRouting.TwoFactorVerification))) {
      navigate(getPath(AppRouting.Dashboard));
      return;
    }

    navigate(-1);
  }, [location.state?.from, navigate]);

  return (
    <AuthenticatedLayout
      title={t(Resources.Profile.Settings.Title)}
      isTitleShown={!isDesktop}
      onBackClick={handleBackClick}
      disableContentPadding
    >
      <ProfileSettings />
    </AuthenticatedLayout>
  );
};
