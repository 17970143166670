import { Box } from "@mui/material";
import {
  PoliticallyExposed,
  PoliticallyExposedFieldsProps,
} from "Components/ContractCreate/AMLQuestions/components/PoliticallyExposed";
import {
  SourceOfFunds,
  SourceOfFundsFieldsProps,
} from "Components/ContractCreate/AMLQuestions/components/SourceOfFunds";
import {
  SourceOfIncome,
  SourceOfIncomeFieldsProps,
} from "Components/ContractCreate/AMLQuestions/components/SourceOfIncome";
import {
  TaxResidency,
  TaxResidencyFieldsProps,
} from "Components/ContractCreate/AMLQuestions/components/TaxResidency";
import { BlInfoPopper } from "Components/Shared/BlInfoPopper";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { DropdownInput } from "Components/Shared/Inputs/Form/DropdownInput";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { CodeListItem } from "Models/CodeListItem";
import { LookupItem } from "Models/LookupItem";
import { FunctionComponent, ReactNode, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";

type AMLFormProps = {
  isAnnualIncomeReadonly?: boolean;
  isActivityFieldReadonly?: boolean;
  isJobPositionReadonly?: boolean;
  isFundsVolumeReadonly?: boolean;
  isFundsVolumeCurrencyReadonly?: boolean;
  annualIncomeOptions: CodeListItem<string | number, ReactNode>[];
  businessSectors: LookupItem<string | number>[];
  jobPositions: CodeListItem<string | number, ReactNode>[];
} & SourceOfIncomeFieldsProps &
  SourceOfFundsFieldsProps &
  PoliticallyExposedFieldsProps &
  TaxResidencyFieldsProps;

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

export const AmlForm: FunctionComponent<AMLFormProps> = props => {
  const {
    isAnnualIncomeReadonly = false,
    isActivityFieldReadonly = false,
    isJobPositionReadonly = false,
    isFundsVolumeReadonly = false,
    isFundsVolumeCurrencyReadonly = false,
    annualIncomeOptions,
    businessSectors,
    jobPositions,
  } = props;

  const { t } = useResource();
  const {
    control,
    formState: { errors },
  } = useFormContext<AMLQuestionsFormModel>();

  const [displayIncomeSource, setDisplayIncomeSource] = useState(false);

  const handleSourceOfIncomeChange = (shouldDisplayIncomeSource: boolean) => {
    setDisplayIncomeSource(shouldDisplayIncomeSource);
  };

  return (
    <>
      <RadioButtons
        label={t(ComponentResources.AnnualIncome)}
        control={control}
        errors={errors}
        name="annualIncome"
        codeList={annualIncomeOptions}
        disabled={isAnnualIncomeReadonly}
      />

      <DropdownInput
        label={t(ComponentResources.FieldOfActivity)}
        control={control}
        errors={errors}
        name="businessSector"
        options={businessSectors}
        placeholder={t(Resources.Common.Choose)}
        disabled={isActivityFieldReadonly}
      />

      <SourceOfIncome {...props} />

      <RadioButtons
        label={
          <>
            {t(ComponentResources.JobPosition)}{" "}
            <BlInfoPopper>
              {t(ComponentResources.JobPositionPopper)}
            </BlInfoPopper>
          </>
        }
        control={control}
        errors={errors}
        name="jobPosition"
        codeList={jobPositions}
        disabled={isJobPositionReadonly}
      />

      <SourceOfFunds onSelect={handleSourceOfIncomeChange} {...props} />

      {displayIncomeSource && (
        <Box display="flex" marginBottom={2}>
          <BlInputFormField
            control={control}
            fullWidth
            name="fundsVolume"
            errors={errors}
            placeholder={t(ComponentResources.Roughly)}
            label={t(ComponentResources.FundsVolume)}
            mask={Number}
            max={100_000_000}
            min={0}
            inputProps={{
              max: 100_000_000,
              min: 0,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            disabled={isFundsVolumeReadonly}
          />
          <Box width={160}>
            <DropdownInput
              label={t(ComponentResources.FundsVolumeCurrency)}
              control={control}
              name="fundsVolumeCurrency"
              options={[
                { Name: "CZK", Value: "CZK" },
                { Name: "EUR", Value: "EUR" },
              ]}
              errors={errors}
              disabled={isFundsVolumeCurrencyReadonly}
            />
          </Box>
        </Box>
      )}

      <PoliticallyExposed {...props} />
      <TaxResidency {...props} />
    </>
  );
};
