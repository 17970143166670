import { useAppSelector } from "Hooks/useAppSelector";
import { useMemo } from "react";
import { ExchangeAssetsStep } from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { Resources, useResource } from "Translations/Resources";

const HookResources = Resources.Contract.ExchangeAssets;

export const usePageTitle = () => {
  const { t } = useResource();
  const { actualStep } = useAppSelector(s => s.contracts.exchangeAssets);

  return useMemo(() => {
    switch (actualStep) {
      case ExchangeAssetsStep.Modelling:
      case ExchangeAssetsStep.Information:
      case ExchangeAssetsStep.Signature:
        return t(HookResources.Title);
      case ExchangeAssetsStep.Success:
        return t(HookResources.Success.Title);
    }
  }, [actualStep, t]);
};
