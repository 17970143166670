import {
  postSignatureBiometricsVerification,
  SendVerificationCodeCommand,
  SendVerificationCodeCommandResult,
} from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { safeApiCall } from "State/Utils";
import { createAsyncAction, getType } from "typesafe-actions";

export const sendClientVerificationCodeAsync = createAsyncAction(
  "@biometrics/SEND_CLIENT_VERIFICATION_CODE_REQUEST",
  "@biometrics/SEND_CLIENT_VERIFICATION_CODE_SUCCESS",
  "@biometrics/SEND_CLIENT_VERIFICATION_CODE_FAILURE",
)<
  SendVerificationCodeCommand & { isCodeSendToClientEmail: boolean },
  SendVerificationCodeCommandResult,
  Error
>();

function* sendClientVerificationCode(
  action: ReturnType<typeof sendClientVerificationCodeAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postSignatureBiometricsVerification,
      action.payload,
    );

    if (error) {
      yield put(sendClientVerificationCodeAsync.failure(error));
      return;
    }

    yield put(sendClientVerificationCodeAsync.success(response));
  } catch (err) {
    yield put(sendClientVerificationCodeAsync.failure(err as Error));
  }
}

export function* watchSendVerificationCodeSaga() {
  yield takeLatest(
    getType(sendClientVerificationCodeAsync.request),
    sendClientVerificationCode,
  );
}
