import { produce } from "immer";
import {
  userContactVerified,
  UserContactVerifiedActionType,
} from "State/Shared/UserContactVerified";
import { ActionType, createAction, createReducer } from "typesafe-actions";

export type TwoFactorVerificationFormModel = {
  phone?: string | null;
};

export enum TwoFactorVerificationSteps {
  Modelling,
  Verification,
}

export type TwoFactorVerificationState = {
  isLoading: boolean;
  isPhoneVerified: boolean;
  actualStep: TwoFactorVerificationSteps;
  formData?: TwoFactorVerificationFormModel;
  error?: Error;
};

function getInitialState(): TwoFactorVerificationState {
  return {
    isLoading: false,
    isPhoneVerified: false,
    actualStep: TwoFactorVerificationSteps.Modelling,
  };
}

export const resetTwoFactorVerificationState = createAction(
  "@profile/RESET_TWO_FACTOR_VERIFICATION_STATE",
)<void>();

export const setTwoFactorVerificationActualStep = createAction(
  "@profile/SET_TWO_FACTOR_VERIFICATION_ACTUAL_STEP",
)<TwoFactorVerificationSteps>();

export type TwoFactorVerificationActions =
  | ActionType<typeof resetTwoFactorVerificationState>
  | ActionType<typeof setTwoFactorVerificationActualStep>
  | UserContactVerifiedActionType;

export const twoFactorVerificationReducer = createReducer<
  TwoFactorVerificationState,
  TwoFactorVerificationActions
>(getInitialState())
  .handleAction(setTwoFactorVerificationActualStep, (state, action) =>
    produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    }),
  )
  .handleAction(resetTwoFactorVerificationState, state =>
    produce(state, () => {
      return getInitialState();
    }),
  )
  .handleAction(userContactVerified, state =>
    produce(state, draft => {
      draft.isPhoneVerified = true;
      return draft;
    }),
  );
