import { Box, Typography } from "@mui/material";
import { HiddenSubmitButton } from "Components/Shared/Buttons/HiddenSubmitButton";
import { TextButton } from "Components/Shared/Buttons/TextButton";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { useAppSelector } from "Hooks/useAppSelector";
import useHandleServerError from "Hooks/useHandleServerError";
import { type PhoneVerificationFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

export interface PhoneVerificationTranslations {
  codeType?: string;
  codeNotReceived?: string;
  codeDetermination: string;
  resendCode?: string;
}

interface Props {
  isLoading?: boolean;
  phone: string;
  autoFocus?: boolean;
  mask?: string;
  disableMask?: boolean;
  disableFormatting?: boolean;
  translations: PhoneVerificationTranslations;
  onResendCode?(phone: string): void;
  onSubmit(data: PhoneVerificationFormModel): void;
  onCodeChange?(value: string): void;
}

const StyledTypography = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
`;

const StyledForm = styled.form`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const ComponentResources =
  Resources.Contract.NewContract.Shared.PhoneVerification;

const formatPhone = (phone: string) => {
  // Add spaces between every 3 characters but ignore if starts with + sign
  return phone.replace(/(\d{3})(?=\d)/g, "$1 ");
};

export const PhoneVerification: FunctionComponent<Props> = ({
  isLoading,
  phone,
  mask = "****",
  autoFocus = true,
  disableMask = false,
  disableFormatting = false,
  translations: { codeType, resendCode, codeNotReceived, codeDetermination },
  onSubmit,
  onResendCode,
  onCodeChange,
}) => {
  const { t } = useResource();
  const { error: serverError } = useAppSelector(
    x => x.contracts.create.signatureSms,
  );

  const form = useFormContext<PhoneVerificationFormModel>();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  useHandleServerError({
    form,
    serverError,
    resource: Resources.Validation.ServerError,
    key: "code",
  });

  const handleResendCode = () => {
    onResendCode?.(phone);
  };

  return (
    <>
      <StyledTypography component="span" paddingRight={1}>
        {codeType ?? t(ComponentResources.Label1)}
      </StyledTypography>
      <StyledTypography component="b" fontWeight={700} paddingRight={1}>
        {disableFormatting ? phone : formatPhone(phone)}
      </StyledTypography>
      <Typography component="span">
        {t(ComponentResources.Label2, { codeDetermination })}
      </Typography>

      <LoadingWrapper
        isLoading={isLoading}
        fullWidth
        wrapChildren
        skeleton={<LoadingSkeleton />}
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <BlInputFormField
            control={control}
            name="code"
            label={t(ComponentResources.Code)}
            isMaskLazy={disableMask ? undefined : false}
            mask={disableMask ? undefined : mask}
            maskPlaceholder={disableMask ? undefined : "_"}
            autoFocus={autoFocus}
            errors={errors}
            onChange={onCodeChange}
          />

          <Box marginTop={2}>
            <Typography paddingRight={1}>
              {codeNotReceived ?? t(ComponentResources.CodeDescription)}
            </Typography>
            <TextButton color="secondary" onClick={handleResendCode}>
              {resendCode ?? t(ComponentResources.ResendCode)}
            </TextButton>
          </Box>

          <HiddenSubmitButton />
        </StyledForm>
      </LoadingWrapper>
    </>
  );
};

const LoadingSkeleton = () => (
  <Box gap={3} display="flex" flexDirection="column" marginTop={5}>
    <PrimarySkeleton variant="rectangular" height={60} />
    <PrimarySkeleton variant="rectangular" height={30} />
  </Box>
);
