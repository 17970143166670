import { Stack, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import {
  HandleFormSubmit,
  PersonalIdentificationNumberValidator,
} from "Components/Shared/Inputs/SpecificInputs/PersonalIdentificationNumberValidator";
import { PageTitle } from "Components/Shared/PageTitle";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { usePageTitle } from "Hooks/More/PersonalData/EditPersonalData/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { FunctionComponent, useCallback, useRef } from "react";
import { setEditPersonalDataIsBankIDVerified } from "State/Client/EditPersonalData/EditPersonalDataState";
import { Resources, useResource } from "Translations/Resources";
import { ValidationError } from "yup";

const ValidationResources = Resources.Contract.NewContract.PersonalInformation;
const ComponentResources = Resources.Contract.NewContract.PersonalInformation;

export const BankIDCallbackStep: FunctionComponent = _ => {
  const { t } = useResource();
  const { error: bankIDError } = useAppSelector(s => s.bankID);
  const {
    bankID: { personalIdentificationNumber },
    error,
  } = useAppSelector(s => s.client.editPersonalData);

  const title = usePageTitle();
  const validatorRef = useRef<HandleFormSubmit>(null);

  const dispatch = useAppDispatch();
  const onSuccess = useCallback(
    () => dispatch(setEditPersonalDataIsBankIDVerified(true)),
    [dispatch],
  );

  const onError = useCallback(
    () => dispatch(setEditPersonalDataIsBankIDVerified(false)),
    [dispatch],
  );

  const isError = !!error || !!bankIDError;

  return (
    <>
      <div>
        <PageTitle showOnDesktop showOnMobile={false}>
          {title}
        </PageTitle>

        {isError && (
          <Typography color="error" marginBottom={3}>
            {error && error instanceof ValidationError
              ? t(ValidationResources.BankIDMissingValuesError)
              : t(ValidationResources.BankIDError)}
          </Typography>
        )}

        {!isError && (
          <>
            <Typography marginBottom={3}>
              {t(
                ComponentResources.PersonalIdentificationNumberVerification
                  .Label,
              )}
            </Typography>

            <PersonalIdentificationNumberValidator
              ref={validatorRef}
              label={t(ComponentResources.PersonalIdentificationNumber)}
              personalIdentificationNumber={personalIdentificationNumber}
              translations={{
                mismatch: t(
                  ComponentResources.PersonalIdentificationNumberMismatch,
                ),
              }}
              onSuccess={onSuccess}
            />
          </>
        )}
      </div>

      <PrimaryButton
        color="primary"
        onClick={() => (error ? onError() : validatorRef.current?.submit())}
        trackingTag={t(Resources.Common.Continue)}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};

export const BankIDCallbackLoadingSkeleton = (
  <>
    <Stack gap={3}>
      <PrimarySkeleton fullWidth fullHeight={false} height={75} />
      <PrimarySkeleton fullWidth fullHeight={false} height={75} />
    </Stack>
    <PrimarySkeleton fullWidth fullHeight={false} height={75} />
  </>
);
