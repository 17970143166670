import { getUserContact } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { safeApiCall } from "State/Utils";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";

export const transferDipInitializeSignatureAsync = createAsyncAction(
  "@contract/PUT_TRANSFER_DIP_INIT_SIGNATURE_REQUEST",
  "@contract/PUT_TRANSFER_DIP_INIT_SIGNATURE_SUCCESS",
  "@contract/PUT_TRANSFER_DIP_INIT_SIGNATURE_FAILURE",
)<
  {
    signatureHash: string | null;
    isBiometry: boolean;
  },
  {
    signatureType: "SMS" | "BIOMETRICS";
    phone: string | null;
  },
  Error
>();

function* transferDipInitializeSignature(
  action: ReturnType<typeof transferDipInitializeSignatureAsync.request>,
): Generator {
  try {
    const { signatureHash, isBiometry } = action.payload;

    if (!signatureHash) {
      yield put(
        transferDipInitializeSignatureAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );

      return;
    }

    if (isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.TransferDip),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: signatureHash,
        }),
      );

      yield put(
        transferDipInitializeSignatureAsync.success({
          signatureType: "BIOMETRICS",
          phone: null,
        }),
      );

      return;
    }

    const { response: contactInfoResponse, error: contractError } =
      yield* safeApiCall(getUserContact);

    if (contractError) {
      yield put(transferDipInitializeSignatureAsync.failure(contractError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash,
      }),
    );

    yield put(
      transferDipInitializeSignatureAsync.success({
        signatureType: "SMS",
        phone:
          contactInfoResponse.contactInfo?.client?.phone ||
          contactInfoResponse.contactInfo?.user.phone ||
          null,
      }),
    );
  } catch (err) {
    yield put(setBiometricsSignatureType(null));
    yield put(transferDipInitializeSignatureAsync.failure(err as Error));
  }
}

export function* transferDipInitializeSignatureSaga() {
  yield takeLatest(
    getType(transferDipInitializeSignatureAsync.request),
    transferDipInitializeSignature,
  );
}
