import { Typography } from "@mui/material";
import {
  ContractExchangeableAsset,
  ContractOverviewPortfolioItemDto,
} from "Api/Api";
import { ProductSelector } from "Components/ContractExchangeAssets/components/ProductSelector";
import { ProductSelectorDialog } from "Components/ContractExchangeAssets/components/ProductSelectorDialog";
import { ProductSelectorWidth } from "Components/ContractExchangeAssets/components/ProductSelectorPlaceholder";
import { ArrowDoubleRightIcon } from "Components/Shared/Icons/ArrowDoubleRight";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

export const StyledWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.values["sm"]}px) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.values["sm"]}px) {
    width: 400px;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  isDip: boolean;
  defaultIsin?: string | null;
  exchangeableAssets: ContractExchangeableAsset[];
  portfolioItem?: ContractOverviewPortfolioItemDto | null;
  onSelect: (isin: string) => void;
};

const ComponentResources =
  Resources.Contract.ExchangeAssets.Modelling.FundSelection;

export const ProductSelectors: FunctionComponent<Props> = ({
  isDip,
  defaultIsin,
  exchangeableAssets,
  portfolioItem,
  onSelect,
}) => {
  const { t } = useResource();
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleOpen = () => setIsDialogVisible(true);
  const handleClose = () => setIsDialogVisible(false);
  const handleSelect = (isin: string) => {
    handleClose();
    onSelect(isin);
  };

  return (
    <>
      <StyledWrapper>
        <StyledFlex $justifyContent="space-between">
          <Typography
            textAlign="center"
            width={ProductSelectorWidth}
            marginBottom={2}
          >
            {t(ComponentResources.Source)}
          </Typography>
          <Typography
            textAlign="center"
            width={ProductSelectorWidth}
            marginBottom={2}
          >
            {t(ComponentResources.Target)}
          </Typography>
        </StyledFlex>

        <StyledFlex $justifyContent="space-between" $alignItems="center">
          <ProductSelector {...portfolioItem} />
          <StyledIconWrapper>
            <ArrowDoubleRightIcon />
          </StyledIconWrapper>
          <ProductSelector isin={defaultIsin} onClick={handleOpen} />
        </StyledFlex>
      </StyledWrapper>
      <ProductSelectorDialog
        isDip={isDip}
        isVisible={isDialogVisible}
        defaultIsin={defaultIsin}
        exchangeableAssets={exchangeableAssets}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </>
  );
};
