import { BlSlideButton } from "Components/Shared/Buttons/BlSlideButton/BlSlideButton";
import { PageTitle } from "Components/Shared/PageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import { FunctionComponent } from "react";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { boolean, object } from "yup";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  exchangeAssetsAsync,
  setExchangeAssetsIsConsentGranted,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { useAppSelector } from "Hooks/useAppSelector";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { BlCheckboxFormField } from "Components/Shared/Inputs/Form/BlCheckboxFormField";

const StyledList = styled.ul`
  display: grid;
  padding-left: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  line-height: 26px;
`;

type FormValues = {
  isConsentGranted: boolean;
};

const useYupFormSchema = (): ObjectSchema<FormValues> =>
  object<FormValues>().shape({
    isConsentGranted: boolean().isTrue().required(),
  });

const ComponentResources = Resources.Contract.ExchangeAssets.Information;

export const InformationStep: FunctionComponent = () => {
  const { t } = useResource();
  const { isLoading, isConsentGranted, lastRequest } = useAppSelector(
    s => s.contracts.exchangeAssets,
  );

  const title = usePageTitle();
  const dispatch = useAppDispatch();

  const schema = useYupFormSchema();
  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      isConsentGranted,
    },
  });

  const onSubmit = (values: FormValues) => {
    dispatch(setExchangeAssetsIsConsentGranted(values.isConsentGranted));
    if (lastRequest) {
      dispatch(exchangeAssetsAsync.request(lastRequest));
    }
  };

  return (
    <>
      <div>
        <PageTitle showOnMobile={false}>{title}</PageTitle>
        <Typography>{t(ComponentResources.Description)}</Typography>
        <StyledList>
          <li>{t(ComponentResources.Information.RedemptionDelay)}</li>
          <li>{t(ComponentResources.Information.FundSettlement)}</li>
          <li>
            {t(
              ComponentResources.Information
                .TargetFundPurchaseAndAssetsIdentification,
            )}
          </li>
          <li>{t(ComponentResources.Information.TargetFundPurchaseDelay)}</li>
          <li>
            {t(ComponentResources.Information.RedemptionAndPurchaseFeeRate)}
          </li>
          <li>
            {t(
              ComponentResources.Information.DelayBetweenRedemptionAndPurchase,
            )}
          </li>
        </StyledList>

        <BlCheckboxFormField
          control={control}
          name="isConsentGranted"
          label={t(ComponentResources.Consent)}
          errors={errors}
        />
      </div>
      <div>
        <BlSlideButton
          isLoading={isLoading}
          minSlideVelocity={0.95}
          minSlideWidth={0.95}
          trackingTag={t(ComponentResources.Submit)}
          disabled={!isValid}
          onSlideDone={handleSubmit(onSubmit)}
        >
          {t(ComponentResources.Submit)}
        </BlSlideButton>
      </div>
    </>
  );
};
