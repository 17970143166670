import { Typography } from "@mui/material";
import { PageTitle } from "Components/ContractCreate/Shared/PageTitle";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import {
  HandleFormSubmit,
  PersonalIdentificationNumberValidator,
} from "Components/Shared/Inputs/SpecificInputs/PersonalIdentificationNumberValidator";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useModelingColor } from "Hooks/useModelingColor";
import { setIdentityNumberVerified } from "State/Contracts/Create/CreateState";
import { NewContractStep } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { useCallback, useRef, type FunctionComponent } from "react";

const ComponentResources = Resources.Contract.NewContract.PersonalInformation;

type Props = {
  validateExistence(): void;
};

export const PersonalIdentificationNumberVerification: FunctionComponent<
  Props
> = ({ validateExistence }) => {
  const { t } = useResource();

  const validatorRef = useRef<HandleFormSubmit>(null);

  const dispatch = useAppDispatch();
  const onSuccess = useCallback(() => {
    dispatch(setIdentityNumberVerified(true));
    validateExistence();
  }, [validateExistence, dispatch]);

  const { color, contrastColor } = useModelingColor();
  const personalData = useAppSelector(
    s => s.contracts.create.formData[NewContractStep.PersonalData],
  );

  return (
    <>
      <div>
        <PageTitle>
          {t(ComponentResources.PersonalIdentificationNumberVerification.Title)}
        </PageTitle>

        <Typography marginBottom={3}>
          {t(ComponentResources.PersonalIdentificationNumberVerification.Label)}
        </Typography>

        <PersonalIdentificationNumberValidator
          ref={validatorRef}
          label={t(ComponentResources.PersonalIdentificationNumber)}
          personalIdentificationNumber={
            personalData?.personalIdentificationNumber
          }
          translations={{
            mismatch: t(
              ComponentResources.PersonalIdentificationNumberMismatch,
            ),
          }}
          onSuccess={onSuccess}
        />
      </div>
      <PrimaryButton
        color="primary"
        onClick={() => validatorRef.current?.submit()}
        trackingTag={t(Resources.Common.Continue)}
        hexColor={contrastColor}
        hexBackgroundColor={color}
      >
        {t(Resources.Common.Continue)}
      </PrimaryButton>
    </>
  );
};
