import { yupResolver } from "@hookform/resolvers/yup";
import { SignatureFormLoader } from "Components/ContractBankConnections/Shared/SignatureFormLoader";
import { SignatureFormTitle } from "Components/ContractBankConnections/Shared/SignatureFormTitle";
import {
  PhoneVerification,
  PhoneVerificationTranslations,
} from "Components/ContractCreate/Shared/PhoneVerification";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useServerErrorMessage } from "Hooks/useHandleServerError";
import { useToast } from "Hooks/useToast";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PhoneVerificationFormModel } from "State/Contracts/Create/Models";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { signWithSmsSmsAsync } from "State/Contracts/Create/SignatureSms/SignWithSmsState";
import { Resources } from "Translations/Resources";
import { object, ObjectSchema, string } from "yup";
import { useResource } from "Translations/Resources";

const useYupFormSchema = (): ObjectSchema<PhoneVerificationFormModel> =>
  object<PhoneVerificationFormModel>().shape({
    code: string()
      .matches(/^.{5}$/)
      .required(),
  });

export interface SignatureFormSmsTranslations
  extends PhoneVerificationTranslations {
  isLoading: string;
  title: string;
  codeSent: string;
  resendCodeTimeout?: string;
  verificationError: string;
  signatureHashNotFoundError: string;
  submit: string;
}

interface Props {
  isLoading: boolean;
  contractID: number | null | undefined;
  phone: string;
  signatureHash: string | null | undefined;
  productColor?: string | null;
  productContrastColor?: string | null;
  translations: SignatureFormSmsTranslations;
  components?: {
    title?: ReactNode;
  };
  error?: Error | null;
  errorMessages?: string[] | null;
  onSuccess: () => void;
}

export const SignatureFormSms: FunctionComponent<Props> = props => {
  const {
    isLoading,
    contractID,
    signatureHash,
    phone,
    productColor,
    productContrastColor,
    translations,
    components,
    error,
    errorMessages,
    onSuccess,
  } = props;

  const { t } = useResource();
  const { showToast } = useToast();
  const { isLoading: isSendingSms, error: sendSmsError } = useAppSelector(
    s => s.contracts.create.signatureSms,
  );

  const { isLoading: isVerifyingSms, error: verifySmsError } = useAppSelector(
    s => s.contracts.create.verificationSignatureSms,
  );

  const dispatch = useAppDispatch();
  const schema = useYupFormSchema();
  const form = useForm<PhoneVerificationFormModel>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, setError, clearErrors } = form;

  useEffect(() => {
    if (verifySmsError) {
      setError("code", {
        message: translations.verificationError,
      });
      return;
    }

    clearErrors("code");
  }, [verifySmsError, setError, clearErrors, translations.verificationError]);

  const resendSmsTimeoutMessage = useRef(
    t(
      Resources.ApiValidation.Validation.Specific.SmsErrorSecondsBetweenSending,
    ),
  );

  const signatureSmsErrorMessages = useServerErrorMessage(
    sendSmsError,
    Resources.Validation.ServerError,
  );

  const [isSigningWaiting, setIsSigningWaiting] = useState(false);
  const [signatureSmsSent, setSignatureSmsSent] = useState(false);

  const resendCode = useCallback(() => {
    if (signatureHash) {
      dispatch(initializeSmsSignatureAsync.request({ signatureHash }));
      setSignatureSmsSent(true);
    }
  }, [dispatch, signatureHash]);

  useEffect(() => {
    if (isSendingSms || !signatureSmsSent) {
      return;
    }

    if (
      translations.resendCodeTimeout &&
      signatureSmsErrorMessages.includes(resendSmsTimeoutMessage.current)
    ) {
      signatureSmsErrorMessages[
        signatureSmsErrorMessages.findIndex(
          x => x === resendSmsTimeoutMessage.current,
        )
      ] = translations.resendCodeTimeout;
    }

    if (sendSmsError) {
      showToast({
        content: signatureSmsErrorMessages.join(" "),
        variant: "error",
      });
      setError("code", {
        message: signatureSmsErrorMessages.join(" "),
      });
    }

    if (!sendSmsError) {
      clearErrors();
      showToast({
        content: translations.codeSent,
        variant: "success",
      });
    }

    setSignatureSmsSent(false);
  }, [
    isSendingSms,
    setSignatureSmsSent,
    setError,
    sendSmsError,
    signatureSmsErrorMessages,
    showToast,
    signatureSmsSent,
    errorMessages,
    clearErrors,
    translations,
    t,
  ]);

  const signatureHashNotFoundError = !signatureHash
    ? translations.signatureHashNotFoundError
    : null;

  const isError = !!error || !!signatureHashNotFoundError;
  const serverErrorMessages = useServerErrorMessage(
    error,
    Resources.Validation.ServerError,
  );

  const onSubmit = (formData: PhoneVerificationFormModel) => {
    if (signatureHash && formData.code) {
      dispatch(
        signWithSmsSmsAsync.request({
          signatureHash,
          code: formData.code,
          contractID,
          onSuccess: () => {
            setIsSigningWaiting(true);
            onSuccess();
          },
        }),
      );
    }
  };

  if (isLoading || isSendingSms) {
    return (
      <SignatureFormLoader
        title={translations.title}
        titleComponent={components?.title}
        working={translations.isLoading}
      />
    );
  }

  return (
    <>
      <div>
        <SignatureFormTitle
          title={translations.title}
          titleComponent={components?.title}
        />

        <LoadingWrapper
          error={error ?? signatureHashNotFoundError}
          errorMessages={errorMessages ?? serverErrorMessages ?? undefined}
        >
          <FormProvider {...form}>
            <PhoneVerification
              phone={phone}
              mask="*****"
              translations={translations}
              onResendCode={resendCode}
              onSubmit={onSubmit}
            />
          </FormProvider>
        </LoadingWrapper>
      </div>

      <PrimaryButton
        color="primary"
        onClick={handleSubmit(onSubmit)}
        disabled={isError || isVerifyingSms || isSigningWaiting}
        isLoading={isVerifyingSms || isSigningWaiting}
        trackingTag={translations.submit}
        hexColor={productContrastColor}
        hexBackgroundColor={productColor}
      >
        {translations.submit}
      </PrimaryButton>
    </>
  );
};
