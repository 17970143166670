import {
  ContractExchangeAssetsCommandResult,
  ContractExchangeAssetsMethod,
  ContractExchangeAssetsRequest,
  ContractExchangeAssetsType,
  getUserContact,
  postContractContractIDExchangeAssets,
} from "Api/Api";
import { produce } from "immer";
import { ApplicationError } from "Models/Errors/ApplicationError";
import {
  BiometricsSignatureType,
  setBiometricsSignatureType,
} from "State/Biometrics/BiometricsActions";
import { initializeBiometricSignatureAsync } from "State/Contracts/Biometrics/InitializeBiometricSignature";
import { initializeSmsSignatureAsync } from "State/Contracts/Create/SignatureSms/InitializeSmsSignatureState";
import { safeApiCall } from "State/Utils";
import { all, put, takeLeading } from "typed-redux-saga";
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from "typesafe-actions";

export type ExchangeAssetsFormModel = {
  isExchangeAll: boolean;
  type: ContractExchangeAssetsType;
  method: ContractExchangeAssetsMethod;
  targetIsin?: string;
  amountOrPieces?: number;
};

export enum ExchangeAssetsStep {
  Modelling,
  Information,
  Signature,
  Success,
}

export type ExchangeAssetsState = {
  isLoading: boolean;
  isConsentGranted: boolean;
  actualStep: ExchangeAssetsStep;
  formData: ExchangeAssetsFormModel;
  lastRequest?: ContractExchangeAssetsRequest & { contractID: number };
  phone?: string | null;
  signatureHash?: string | null;
  error?: Error | null;
};

function getDefaultState(): ExchangeAssetsState {
  return {
    isLoading: false,
    isConsentGranted: false,
    actualStep: ExchangeAssetsStep.Modelling,
    formData: {
      isExchangeAll: false,
      type: ContractExchangeAssetsType.Single,
      method: ContractExchangeAssetsMethod.Amount,
    },
  };
}

export const setExchangeAssetsStep = createAction(
  "@contract/SET_EXCHANGE_ASSETS_STEP",
)<ExchangeAssetsStep>();

export const resetExchangeAssetsState = createAction(
  "@contract/RESET_EXCHANGE_ASSETS_STATE",
)<void>();

export const setExchangeAssetsIsConsentGranted = createAction(
  "@contract/SET_EXCHANGE_ASSETS_IS_CONSENT_GRANTED",
)<boolean>();

export const setExchangeAssetsLastRequest = createAction(
  "@contract/SET_EXCHANGE_ASSETS_LAST_REQUEST",
)<ContractExchangeAssetsRequest & { contractID: number }>();

export type ExchangeAssetsStateAction =
  | ActionType<typeof setExchangeAssetsStep>
  | ActionType<typeof resetExchangeAssetsState>
  | ActionType<typeof setExchangeAssetsIsConsentGranted>
  | ActionType<typeof setExchangeAssetsLastRequest>
  | ActionType<typeof exchangeAssetsAsync>;

export const exchangeAssetsAsync = createAsyncAction(
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_REQUEST",
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_SUCCESS",
  "@contract/POST_CONTRACT_EXCHANGE_ASSETS_FAILURE",
)<
  ContractExchangeAssetsRequest & {
    contractID: number;
  },
  ContractExchangeAssetsCommandResult & { phone?: string | null },
  Error
>();

function* exchangeAssets(
  action: ReturnType<typeof exchangeAssetsAsync.request>,
): Generator {
  try {
    const { response, error } = yield* safeApiCall(
      postContractContractIDExchangeAssets,
      action.payload,
      action.payload.contractID,
    );

    if (!!error) {
      yield put(exchangeAssetsAsync.failure(error));
      return;
    }

    if (!response.signatureHash) {
      yield put(
        exchangeAssetsAsync.failure(
          new ApplicationError("Signature hash is required."),
        ),
      );
      return;
    }

    if (action.payload.isBiometry) {
      yield put(
        setBiometricsSignatureType(BiometricsSignatureType.ExchangeAssets),
      );

      yield put(
        initializeBiometricSignatureAsync.request({
          signatureHash: response.signatureHash,
        }),
      );

      return;
    }

    const { response: contactInfoResponse, error: contactInfoError } =
      yield* safeApiCall(getUserContact);

    if (!!contactInfoError) {
      yield put(exchangeAssetsAsync.failure(contactInfoError));
      return;
    }

    yield put(
      initializeSmsSignatureAsync.request({
        signatureHash: response.signatureHash,
      }),
    );

    yield put(
      exchangeAssetsAsync.success({
        ...response,
        phone:
          contactInfoResponse.contactInfo?.client?.phone ??
          contactInfoResponse.contactInfo?.user.phone,
      }),
    );
  } catch (err) {
    yield put(exchangeAssetsAsync.failure(err as Error));
  }
}

export function* exchangeAssetsSaga() {
  yield takeLeading(getType(exchangeAssetsAsync.request), exchangeAssets);
}

export function* watchExchangeAssetsSaga() {
  yield all([exchangeAssetsSaga()]);
}

export const exchangeAssetsReducer = createReducer<
  ExchangeAssetsState,
  ExchangeAssetsStateAction
>(getDefaultState())
  .handleAction(resetExchangeAssetsState, state => {
    return produce(state, () => getDefaultState());
  })
  .handleAction(setExchangeAssetsStep, (state, action) => {
    return produce(state, draft => {
      draft.actualStep = action.payload;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.request, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = true;
      draft.actualStep = ExchangeAssetsStep.Signature;
      draft.formData = draft.lastRequest = action.payload;
      draft.error = null;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.success, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.signatureHash = action.payload.signatureHash;
      draft.phone = action.payload.phone;
      return draft;
    });
  })
  .handleAction(exchangeAssetsAsync.failure, (state, action) => {
    return produce(state, draft => {
      draft.isLoading = false;
      draft.error = action.payload;
      return draft;
    });
  })
  .handleAction(setExchangeAssetsIsConsentGranted, (state, action) => {
    return produce(state, draft => {
      draft.isConsentGranted = action.payload;
      return draft;
    });
  })
  .handleAction(setExchangeAssetsLastRequest, (state, action) => {
    return produce(state, draft => {
      draft.formData = draft.lastRequest = action.payload;
      return draft;
    });
  });
