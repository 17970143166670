import { InformationStep } from "Components/ContractExchangeAssets/InformationStep";
import { ModellingStep } from "Components/ContractExchangeAssets/ModellingStep";
import { SignatureStep } from "Components/ContractExchangeAssets/SignatureStep";
import { SuccessStep } from "Components/ContractExchangeAssets/SuccessStep";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { DipTagWrapper } from "Components/Shared/Dip/DipTagWrapper";
import { LoadingWrapper } from "Components/Shared/LoadingWrapper";
import { PrimarySkeleton } from "Components/Shared/PrimarySkeleton";
import { usePageTitle } from "Hooks/ContractExchangeAssets/usePageTitle";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useDesktop } from "Hooks/useDesktop";
import { FunctionComponent, useEffect, useMemo } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import {
  ExchangeAssetsStep,
  resetExchangeAssetsState,
  setExchangeAssetsStep,
} from "State/Contracts/ExchangeAssets/ExchangeAssetsState";
import { getOverviewAsync } from "State/InvestmentsDetail/Overview/GetOverviewState";
import { AppRouting, getPath } from "Utils/UrlUtils";

export const ExchangeAssetsPage: FunctionComponent = () => {
  const { isDesktop } = useDesktop();
  const { actualStep } = useAppSelector(s => s.contracts.exchangeAssets);
  const {
    isLoading: isLoadingOverview,
    data: overview,
    error,
  } = useAppSelector(s => s.investmentsDetail.overview);

  const params = useParams();
  const title = usePageTitle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const contractID = Number.parseInt(params.id as string);
  const sourceIsin = params.isin as string;

  useEffect(() => {
    dispatch(resetExchangeAssetsState());
    dispatch(getOverviewAsync.request(contractID));
  }, [contractID, sourceIsin, dispatch]);

  const portfolioItem = useMemo(
    () => overview?.portfolioItems?.find(x => x.isin === sourceIsin),
    [overview?.portfolioItems, sourceIsin],
  );

  const exchangeableAssets = useMemo(
    () =>
      overview?.exchangeableAssets?.filter(x => x.sourceIsin === sourceIsin),
    [overview?.exchangeableAssets, sourceIsin],
  );

  const isLoading =
    isLoadingOverview ||
    !overview ||
    !portfolioItem ||
    !exchangeableAssets?.length;

  if (!isLoadingOverview && !!overview && !overview.hasExchangeableAssets) {
    return <Navigate to={getPath(AppRouting.ContractDetail, contractID)} />;
  }

  return (
    <AuthenticatedLayout
      isTitleShown={!isDesktop}
      title={title}
      headerChildren={
        !!overview?.isDip && !isDesktop ? <DipTagWrapper inverse /> : undefined
      }
      onBackClick={() => {
        switch (actualStep) {
          case ExchangeAssetsStep.Modelling:
            return navigate(getPath(AppRouting.ContractDetail, contractID));
          case ExchangeAssetsStep.Information:
            return dispatch(
              setExchangeAssetsStep(ExchangeAssetsStep.Modelling),
            );
          case ExchangeAssetsStep.Signature:
            return dispatch(
              setExchangeAssetsStep(ExchangeAssetsStep.Information),
            );
          case ExchangeAssetsStep.Success:
            return navigate(getPath(AppRouting.ContractDetail, contractID));
        }
      }}
    >
      <LoadingWrapper
        isLoading={isLoading}
        skeleton={LoadingSkeleton}
        error={error}
      >
        {actualStep === ExchangeAssetsStep.Modelling &&
          !!overview &&
          !!portfolioItem &&
          !!exchangeableAssets && (
            <ModellingStep
              isDip={!!overview.isDip}
              sourceIsin={sourceIsin}
              contractID={contractID}
              contractTypeCode={overview.type}
              portfolioItem={portfolioItem}
              exchangeableAssets={exchangeableAssets}
            />
          )}

        {actualStep === ExchangeAssetsStep.Information && <InformationStep />}
        {actualStep === ExchangeAssetsStep.Signature && (
          <SignatureStep contractID={contractID} />
        )}

        {actualStep === ExchangeAssetsStep.Success && (
          <SuccessStep contractID={contractID} />
        )}
      </LoadingWrapper>
    </AuthenticatedLayout>
  );
};

const LoadingSkeleton = (
  <>
    <div>
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={200}
        marginBottom={10}
      />
      <PrimarySkeleton
        fullWidth
        fullHeight={false}
        height={250}
        marginBottom={10}
      />
    </div>

    <PrimarySkeleton fullWidth fullHeight={false} height={60} />
  </>
);
