import {
  ClientVerificationFormBiometrics,
  ClientVerificationFormBiometricsTranslations,
} from "Components/ContractBankConnections/Shared/ClientVerificationFormBiometrics";
import { SignatureFormSms } from "Components/ContractBankConnections/Shared/SignatureFormSms";
import { useBiometricsCanBeUsed } from "Hooks/Auth/useBiometricsCanBeUsed";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { FunctionComponent, useEffect } from "react";
import {
  resetSendClientVerificationCode,
  resetVerifyClientVerificationCode,
} from "State/Biometrics/BiometricsActions";
import { resetSignWithSmsState } from "State/Contracts/Create/SignatureSms/SignWithSmsState";

interface CodeTypeTranslations
  extends Pick<
    ClientVerificationFormBiometricsTranslations,
    | "codeType"
    | "codeSent"
    | "codeNotReceived"
    | "resendCode"
    | "resendCodeTimeout"
  > {}

interface Translations
  extends Omit<
    ClientVerificationFormBiometricsTranslations,
    | "codeType"
    | "codeSent"
    | "codeNotReceived"
    | "resendCode"
    | "resendCodeTimeout"
  > {
  sms: CodeTypeTranslations;
  email: CodeTypeTranslations;
}

interface Props {
  isLoading?: boolean;
  isCodeSendToClientEmail: boolean;
  phone: string | null | undefined;
  email: string | null | undefined;
  signatureHash: string | null | undefined;
  clientVerificationHash: string | null | undefined;
  translations: Translations;
  error?: Error | null;
  errorMessages?: string[] | null;
  onSuccess: () => void;
}

export const ClientVerificationForm: FunctionComponent<Props> = ({
  isLoading = false,
  isCodeSendToClientEmail,
  phone,
  email,
  signatureHash,
  clientVerificationHash,
  translations,
  error,
  errorMessages,
  onSuccess,
}) => {
  const { canUseBiometricsForSigning } = useBiometricsCanBeUsed();

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSignWithSmsState());
      dispatch(resetSendClientVerificationCode());
      dispatch(resetVerifyClientVerificationCode());
    };
  }, [dispatch]);

  const phoneOrEmail = (isCodeSendToClientEmail ? email : phone) ?? "";
  const specificTranslations = isCodeSendToClientEmail
    ? translations.email
    : translations.sms;

  if (canUseBiometricsForSigning) {
    return (
      <ClientVerificationFormBiometrics
        isLoading={isLoading}
        isCodeSendToClientEmail={isCodeSendToClientEmail}
        phoneOrEmail={phoneOrEmail}
        signatureHash={signatureHash}
        clientVerificationHash={clientVerificationHash}
        translations={{
          ...translations,
          ...specificTranslations,
        }}
        error={error}
        errorMessages={errorMessages}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <SignatureFormSms
      isLoading={isLoading}
      phone={phoneOrEmail}
      contractID={null}
      signatureHash={signatureHash}
      translations={{
        ...translations,
        ...specificTranslations,
      }}
      error={error}
      errorMessages={errorMessages}
      onSuccess={onSuccess}
    />
  );
};
