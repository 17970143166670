import { BiometricsSignInType } from "State/Biometrics/BiometricsReducer";
import { createAction } from "typesafe-actions";

export const setBiometrics = createAction(
  "@biometrics/SET_BIOMETRICS",
)<boolean>();

export const setBiometricsSignInType = createAction(
  "@biometrics/SET_BIOMETRICS_SIGN_IN_TYPE",
)<BiometricsSignInType>();

export const setBiometricsCanBeUsed = createAction(
  "@biometrics/SET_BIOMETRICS_CAN_BE_USED",
)<boolean>();

export const setSettingBiometricsInProgress = createAction(
  "@biometrics/SET_SETTING_BIOMETRICS",
)<boolean>();

export const setLogin = createAction("@biometrics/SET_LOGIN")<string | null>();

export const setBiometricsSignInCanBeUsed = createAction(
  "@biometrics/SET_BIOMETRICS_SIGN_IN_CAN_BE_USED",
)<boolean>();

export enum BiometricsSignatureType {
  Contract = "Contract",
  UninvestedDeposits = "UninvestedDeposits",
  Redemption = "Redemption",
  TransferDip = "TransferDip",
  CreateBankConnection = "CreateBankConnection",
  DeactivateBankConnection = "CreateBankConnection",
  ExchangeAssets = "ExchangeAssets",
  EditAml = "EditAml",
  EditPersonalData = "EditPersonalData",
}

export const setBiometricsSignatureType = createAction(
  "@biometrics/SET_BIOMETRICS_SIGNATURE_TYPE",
)<BiometricsSignatureType | null>();

export const resetBiometricSignatureError = createAction(
  "@biometrics/RESET_ERROR",
)<void>();

export const resetBiometricSignature = createAction(
  "@biometrics/RESET_BIOMETRICS_SIGNATURE",
)<void>();

export const resetSendClientVerificationCode = createAction(
  "@biometrics/RESET_SEND_CLIENT_VERIFICATION_CODE",
)<void>();

export const resetVerifyClientVerificationCode = createAction(
  "@biometrics/RESET_VERIFY_CLIENT_VERIFICATION_CODE",
)<void>();
