import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { PrimaryDocumentFormModel } from "State/Client/EditPersonalData/EditPersonalDataState";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { uploadAttachmentAsync } from "State/Attachments/Upload/UploadAttachmentState";
import { deleteAttachmentAsync } from "State/Attachments/Delete/DeleteAttachmentState";
import { useTranslation } from "react-i18next";
import { CreateOrUpdateAttachmentCommandResultStatus as UploadResultStatus } from "Api/Api";
import { Resources } from "Translations/Resources";

export const useDocumentScan = (isFrontScan: boolean) => {
  const { t } = useTranslation();
  const { watch, setValue, setError } =
    useFormContext<PrimaryDocumentFormModel>();

  const dispatch = useAppDispatch();
  const documentScan = isFrontScan
    ? watch("primaryDocument.frontScan")
    : watch("primaryDocument.backScan");

  const [guid, setGuid] = useState<string | null>(
    documentScan?.documentGuid ?? null,
  );

  const state = useAppSelector(s => s.attachments)?.[guid ?? ""];

  const file = useMemo(
    () =>
      documentScan?.fileName ? new File([""], documentScan.fileName) : null,
    [documentScan?.fileName],
  );

  const uploadFile = (file: File) => {
    const newGuid = uuid();

    setGuid(newGuid);
    dispatch(
      uploadAttachmentAsync.request(
        {
          file,
          onFail: status => {
            const message =
              status === UploadResultStatus.InvalidBase64String ||
              status === UploadResultStatus.MimeTypeNotAllowed
                ? t(Resources.Common.UnsupportedFileType)
                : t(Resources.Validation.ServerError.General);

            setError(
              isFrontScan
                ? "primaryDocument.frontScan.documentGuid"
                : "primaryDocument.backScan.documentGuid",
              { message },
            );
          },
          onSuccess: (documentGuid, fileName) =>
            setValue(
              isFrontScan
                ? "primaryDocument.frontScan"
                : "primaryDocument.backScan",
              {
                documentGuid,
                fileName,
              },
              { shouldValidate: true },
            ),
        },
        { attachmentGuid: newGuid },
      ),
    );
  };

  const deleteFile = () => {
    if (guid) {
      dispatch(deleteAttachmentAsync.request({ attachmentGuid: guid }));
      setGuid(null);
      setValue(
        isFrontScan ? "primaryDocument.frontScan" : "primaryDocument.backScan",
        null,
        { shouldValidate: true },
      );
    }
  };

  return {
    isLoading: !!state?.isLoading,
    file,
    uploadFile,
    deleteFile,
  };
};
