import { PhoneVerificationFormModel } from "State/Contracts/Create/Models";
import { object, ObjectSchema, string } from "yup";

export const useYupFormSchema =
  (): ObjectSchema<PhoneVerificationFormModel> => {
    return object<PhoneVerificationFormModel>().shape({
      code: string()
        .required()
        .matches(/^.{4}$/),
    });
  };
