import { SourceOfIncomeType } from "Api/Api";
import { BlInputFormField } from "Components/Shared/Inputs/Form/BlInputFormField";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { CodeListItem } from "Models/CodeListItem";
import { AMLQuestionsFormModel } from "State/Contracts/Create/Models";
import { Resources, useResource } from "Translations/Resources";
import { ReactNode, type FunctionComponent } from "react";
import { useFormContext } from "react-hook-form";

export type SourceOfIncomeFieldsProps = {
  isSourceOfIncomeReadonly?: boolean;
  isOtherSourceOfIncomeReadonly?: boolean;
  sourcesOfIncome: CodeListItem<string | number, ReactNode>[];
};

const ComponentResources = Resources.Contract.NewContract.AMLQuestions;

export const SourceOfIncome: FunctionComponent<SourceOfIncomeFieldsProps> = ({
  isSourceOfIncomeReadonly = false,
  isOtherSourceOfIncomeReadonly = false,
  sourcesOfIncome,
}) => {
  const { t } = useResource();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AMLQuestionsFormModel>();

  const income = watch("sourceOfIncome");

  return (
    <div>
      <RadioButtons
        label={t(ComponentResources.IncomeSource)}
        control={control}
        errors={errors}
        name="sourceOfIncome"
        codeList={sourcesOfIncome}
        disabled={isSourceOfIncomeReadonly}
      />
      {income === SourceOfIncomeType.Other && (
        <BlInputFormField
          control={control}
          errors={errors}
          name="sourceOfIncomeOther"
          variant="standard"
          placeholder={t(ComponentResources.SourceOfIncomePlaceholder)}
          disabled={isOtherSourceOfIncomeReadonly}
        />
      )}
    </div>
  );
};
